import React from 'react';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import {
    ArrowDownWhite
} from "../../icons";
import BannerBG from '../../images/banner-img.jpg';
import BannerLogoIcon from '../../images/banner-logo-icon.png';
export const HeroBanner = (props) => {

    const ScrollToSearch = () => {
        document.getElementById('search_wrapper').scrollIntoView();

    }
    return (
        <div className="hero_banner_wrapper" style={{ backgroundImage: "url(" + props.BannerImage + ")" }}>
            <div className='banner_overlay'></div>
            <div className='hero_banner_logo_icon'>
                <img src={BannerLogoIcon} alt="Leo Cussen" />
            </div>
            <Container>
                <Row>
                    <Col lg={5} md={8} xs={12}>
                        <div className='hero_banner_content text-white'>
                            {props.BannerTitle !== null ? <h1>{props.BannerTitle}</h1> : null}
                            {props.BannerSubTitle !== null ? <h2>{props.BannerSubTitle}</h2> : null}
                            {props.BannerButtonUrl !== null ? <a href={props.BannerButtonUrl + props.GAurl} className='btn btn-secondary'>{props.BannerButtonText}</a> : null}
                            <div className='banner-icon d-lg-none text-center' onClick={() => ScrollToSearch()}><ArrowDownWhite /></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HeroBanner;
