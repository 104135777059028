import React from "react";

const SVG = ({
  style = {},
  fill = "#004e6d",
  stroke="none",
  width = "19.993px",
  height = "19.182px",
  className = "",
  viewBox = "0 0 19.993 19.182"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path fill={fill} stroke={stroke}
  d="M9.651,4.713A5.73,5.73,0,0,1,13.67,6.446v0a.831.831,0,0,1,.816-.89h.121a.9.9,0,0,1,.894.924l0,7.893a.511.511,0,0,0,.857.452c1.267-1.3,2.784-6.7-.788-9.822A8.553,8.553,0,0,0,5.4,4.211a7.677,7.677,0,0,0-2.571,9.217c1.711,3.949,6.607,5.125,9.517,3.952,1.474-.6,2.154,1.4.624,2.047-2.313.986-8.749.886-11.754-4.322A9.529,9.529,0,0,1,4.682,2.2,10.514,10.514,0,0,1,17.513,3.844c3.425,3.577,3.226,10.275-.116,12.88a2.333,2.333,0,0,1-3.747-1.692l-.016-.565a5.619,5.619,0,0,1-3.984,1.656,5.83,5.83,0,0,1-5.676-5.675A5.864,5.864,0,0,1,9.651,4.713Zm3.8,5.517A3.6,3.6,0,0,0,9.715,6.69H9.641a3.553,3.553,0,0,0-3.555,3.84,3.478,3.478,0,0,0,3.545,3.733,3.721,3.721,0,0,0,3.825-3.587Z" transform="translate(-0.001 -0.731)" />
  </svg>
);

export default SVG;