import React from "react";

const SVG = ({
  style = {},
  fill ="none",
  fillwhite ="#fff",
  stroke="#004e6d",
  width = "20",
  height = "20",
  className = "",
  viewBox = "0 0 20 20"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path d="M9,1.692S12,0,18,0V10.731A20.976,20.976,0,0,0,9,11ZM0,10.731V0C6,0,9,1.692,9,1.692V11A20.976,20.976,0,0,0,0,10.731Z" transform="translate(1 5)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
  <path d="M7,2s2.333-2,7-2V10.5A12.7,12.7,0,0,0,7,13ZM0,10.5V0C4.667,0,7,2,7,2V13A12.7,12.7,0,0,0,0,10.5Z" transform="translate(3 3)" fill={fillwhite} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
  <path d="M.5,0V11" transform="translate(9.5 5)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
  </svg>
);

export default SVG;