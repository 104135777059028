import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke = "#004e6d",
  width = "20.899",
  height = "20",
  className = "logout_icon",
  viewBox = "0 0 20.899 20"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(0.5)">
    <path d="M12.308,3.692V0H0V16H12.308V12.308" transform="translate(0 2)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M0,.615H13.538" transform="translate(6.154 9.385)" fill={fill} stroke={stroke} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M0,7.385,3.692,3.692,0,0" transform="translate(16 6.308)" fill={fill} stroke={stroke} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
  </g>
  </svg>
);

export default SVG;
