import React, { useState } from 'react'
import {
    // Card,
    Button,
    Modal,
    Container,
    Row,
    Col,
    Alert,
    // ModalBody,
    Form
} from 'react-bootstrap';


import { useLocation } from 'react-router';
import queryString from 'query-string';
import axios from 'axios';



export const ActivateAccount = (props) => {

    const location = useLocation();

    const queryParam = queryString.parse(location.search);

    if (queryParam["token"] === undefined) {
        window.location.href = "/";
    }

    const ResetUrl = queryParam["token"] !== undefined && queryParam["token"] !== null ? queryParam["token"] : undefined;

    const userEmail = queryParam["email"] !== undefined && queryParam["email"] !== null ? queryParam["email"] : undefined;


    /***
     * Error message
     */
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);




    /***
     * FOrm submit
     */
    const submit = (e) => {
        e.preventDefault();
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setSomethingUpadating(true);


        axios.get(props.EngineURL + 'create-account/activate_account_new.php?email=' + userEmail + '&token=' + ResetUrl)
            .then(function (response) {

                setTimeout(function () {
                    if (response.data.code === 200) {
                        axios.get(props.EngineURL + 'create-account/send_refreral_code.php?email=' + userEmail + '&discount_type=percentage&discount_value=10')
                            .then(function (response2) {
                                if (response2.status === 200) {
                                    if (response.data.message === "user account activated") {
                                        setSuccessMessage("Your account has been activated successfully! Please login now.");
                                    }
                                    else {
                                        setSuccessMessage(response.data.message);
                                    }
                                }
                                setSomethingUpadating(false);

                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                    }
                    else {
                        setErrorMessage(JSON.stringify(response.data.message));
                    }
                }, 1000);

            })
            .catch(function (error) {
                console.log(error);
            });



    }


    const openLoginPopup = () => {
        props.ShowLoginPopup();
    }




    return (
        <Container>
            <br />
            <Row className="justify-content-center">
                <Col md={4} xs={12}>

                    <Form className="" onSubmit={submit}>
                        <Modal.Title className="text-center">Activate your account</Modal.Title>


                        {ErrorMessage !== undefined ?
                            <Alert variant="danger">
                                {ErrorMessage}
                            </Alert>
                            : ''}
                        {SuccessMessage !== undefined ? <>
                            <Alert variant="success">
                                {SuccessMessage}
                            </Alert>
                            <Button className="w-100 rounded-2 btn-lg border-2" onClick={(e) => openLoginPopup()}>Login</Button></>
                            : ''}
                        {SomethingUpadating === true ?
                            <Alert variant="primary">
                                Validating...
                            </Alert>
                            : ''}
                        {SuccessMessage === undefined ? <Button variant="primary" type="submit" className="w-100 rounded-2 btn-lg border-2">Activate</Button> : ''}
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
