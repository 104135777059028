import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    CallPhoneIcon,
    LoaderIcon
} from "../../icons";
import {
    Container,
    Row,
    Col,
    Card,
    ListGroup
} from 'react-bootstrap';
import axios from 'axios';



export const Thankyou = (props) => {
    localStorage.removeItem("checkout_id");
    localStorage.removeItem("cart_session");
    localStorage.removeItem("cart_session_sku");



    const checkoutoken = localStorage.getItem("checkout_token");
    const was_book = localStorage.getItem("was_book");

    const StructureImages = localStorage.getItem("thank_you_image") !== null ? JSON.parse(localStorage.getItem("thank_you_image")) : [];


    const [UserOrderDetail, setUserOrderDetail] = useState(undefined);
    const [shopify_response_loaded, set_shopify_response_loaded] = useState(false);
    const [shopify_response_half_loaded, set_shopify_response_half_loaded] = useState(false);





    useEffect(() => {
        var clearinterval = setInterval(function () {
            if (checkoutoken !== null && UserOrderDetail === undefined && !shopify_response_loaded) {
                axios.post(props.EngineURL + 'chk/get-db-order-data.php', { checkout_token: checkoutoken })
                    .then(function (response) {
                        set_shopify_response_half_loaded(true);
                        if (response.data.status === "success") {
                            if (JSON.parse(response.data.data.shopify_response) !== null) {
                                setUserOrderDetail(JSON.parse(response.data.data.shopify_response));
                                set_shopify_response_loaded(true);
                                clearInterval(clearinterval);
                            }
                            else {
                                setUserOrderDetail(undefined);
                            }
                        }


                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            }
        }, 8000);
    }, [])


    function getTags(title) {
        var imageurl = 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Seminar.jpg';
        StructureImages.map(function (item, value) {
            if (item.title === title) {
                imageurl = getProdcutImage(item.tag);
            }
        })
        return imageurl[0];
    }

    function getSlug(title) {
        var imageurl = '/';
        StructureImages.map(function (item, value) {
            if (item.title === title) {
                imageurl = item.slug;
            }
        })
        return imageurl;
    }



    function getProdcutImage(tag_array) {
        var imagepath = ["https://engine.leocussen.edu.au/sync/img/logo.png"];
        props.Program_structure_images.filter(function (item, value) {
            var keyname = Object.keys(item);
            if (tag_array.includes(keyname.toString())) {
                imagepath = Object.values(item);
            }
        })
        return imagepath;
    }

    const numberWithCommas = (x) => {
        return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    if (checkoutoken == null) {

        return <div className="thank_you_page">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={11} xs={12}>
                        <Card className="thank_you_card shadow border-light mb-3">
                            <Card.Body>
                                <Row className="justify-content-between">
                                    <Col lg={6} md={7} xs={12}>
                                        <Card.Title as="h1" className="h2">Thank you for your order</Card.Title>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    if (shopify_response_half_loaded && UserOrderDetail == undefined) {
        return <div className="thank_you_page">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={11} xs={12}>
                        <Card className="thank_you_card shadow border-light mb-3">
                            <Card.Body>
                                <Row className="justify-content-between">
                                    <Col lg={6} md={7} xs={12}>
                                        <Card.Title as="h1" className="h2">Thank you for your order</Card.Title>
                                        <Card.Text as="div">
                                            <div className="thakyou_cta d-flex flex-wrap align-items-center">
                                                <p className="visit_link my-4 my-md-5"><Link to="/admin-profile" className="btn-link">Visit your account</Link> to view your purchases</p>
                                            </div>
                                            {!props.useLoggedIn
                                                ? <div><a href="#" className="btn btn-primary rounded-pill btn-lg" onClick={() => props.ShowCreateAccountPopup()}>Create Account</a></div>
                                                : ''
                                            }
                                        </Card.Text>
                                    </Col>
                                    <Col lg={4} md={5} xs={12}>
                                        <div className="need_help_block mt-4 mt-md-0">
                                            <h6 className="display-6">Need Help</h6>
                                            <div className=" d-flex flex-wrap align-items-center justify-content-between">
                                                <h3 className="w-100"><CallPhoneIcon /> <a href="tel:1300 039 031">1300 039 031</a></h3>
                                                <a href="mailto:registration@leocussen.edu.au" className="btn-link mt-3">Get in touch</a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                        <br />
                        <h2>Please wait, your order is being processed</h2>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    if (UserOrderDetail != undefined) {
        return (
            <div className="thank_you_page">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={11} xs={12}>
                            <Card className="thank_you_card shadow border-light mb-3">
                                <Card.Body>
                                    <Row className="justify-content-between">
                                        <Col lg={6} md={7} xs={12}>
                                            <Card.Title as="h1" className="h2">Thank you for your order</Card.Title>
                                            <Card.Text as="div">
                                                <p>Thank you for your purchase - your order confirmation/tax invoice is on its way.</p>
                                                <p>Are you new to our online store? Create an account to view your orders, see your upcoming programs, access your digital library and keep track of your CPD points, all in one place. Click below to sign up or log in.</p>
                                                <p>Thanks for supporting Leo Cussen Centre for Law.</p>

                                                <div className="thakyou_cta d-flex flex-wrap align-items-center">
                                                    <p className="visit_link my-4 my-md-5"><Link to="/admin-profile" className="btn-link">Visit your account</Link> to view your purchases</p>
                                                </div>
                                                {!props.useLoggedIn
                                                    ? <div><a href="#" className="btn btn-primary rounded-pill btn-lg" onClick={() => props.ShowCreateAccountPopup()}>Create Account</a></div>
                                                    : ''
                                                }
                                            </Card.Text>
                                        </Col>
                                        <Col lg={4} md={5} xs={12}>
                                            <div className="need_help_block mt-4 mt-md-0">
                                                <h6 className="display-6">Need Help</h6>
                                                <div className=" d-flex flex-wrap align-items-center justify-content-between">
                                                    <h3 className="w-100"><CallPhoneIcon /> <a href="tel:1300 039 031">1300 039 031</a></h3>
                                                    <a href="mailto:enquiries@leocussen.edu.au" className="btn-link mt-3">Get in touch</a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="thank_you_card thank_you_order_card shadow border-light mb-3">
                                <Card.Body>
                                    <Card.Title as="h2">Order #{UserOrderDetail.order_number}</Card.Title>
                                    <Row className="justify-content-between mt-5">
                                        <Col lg={3} md={6} xs={12}>
                                            <Card.Text as="div">
                                                {UserOrderDetail.billing_address != null && UserOrderDetail.billing_address
                                                    ? <><h6 className="display-6 text-secondary">Billing Address</h6>
                                                        <p className="small">
                                                            <span className="d-block">{UserOrderDetail.billing_address.first_name} {UserOrderDetail.billing_address.last_name}</span>
                                                            {UserOrderDetail.billing_address.company !== null ? <span className="d-block">{UserOrderDetail.billing_address.company} </span> : null}
                                                            <span className="d-block">
                                                                {UserOrderDetail.billing_address.address1}&nbsp;{UserOrderDetail.billing_address.address2}<br />
                                                                {UserOrderDetail.billing_address.city}&nbsp;{UserOrderDetail.billing_address.province}&nbsp;{UserOrderDetail.billing_address.zip}<br />
                                                                {UserOrderDetail.billing_address.country}

                                                            </span>
                                                            {UserOrderDetail.billing_address.phone != null
                                                                ? <span className="d-block">
                                                                    <a href={"tel:" + UserOrderDetail.billing_address.phone}>Phone {UserOrderDetail.billing_address.phone}</a>
                                                                </span>
                                                                : ''}
                                                        </p></>
                                                    : ""}
                                            </Card.Text>
                                        </Col>
                                        <Col lg={3} md={6} xs={12}>
                                            {UserOrderDetail.shipping_address != null && UserOrderDetail.shipping_address.length
                                                ? <> <Card.Text as="div">
                                                    <h6 className="display-6 text-secondary">Shipping Address</h6>
                                                    <p className="small">
                                                        <span className="d-block">{UserOrderDetail.shipping_address.first_name} {UserOrderDetail.shipping_address.last_name}</span>
                                                        {UserOrderDetail.shipping_address.company !== null ? <span className="d-block">{UserOrderDetail.shipping_address.company} </span> : null}
                                                        <span className="d-block">
                                                            {UserOrderDetail.shipping_address.address1}{UserOrderDetail.shipping_address.address2}<br />
                                                            {UserOrderDetail.shipping_address.city}&nbsp;{UserOrderDetail.shipping_address.province}&nbsp;{UserOrderDetail.shipping_address.zip}<br />
                                                            {UserOrderDetail.shipping_address.country}

                                                        </span>
                                                        {UserOrderDetail.shipping_address.phone != null
                                                            ? <span className="d-block">
                                                                <a href={"tel:" + UserOrderDetail.billing_address.phone}>Phone {UserOrderDetail.billing_address.phone}</a>
                                                            </span>
                                                            : ''}
                                                    </p>
                                                </Card.Text>
                                                </>
                                                : <><><h6 className="display-6 text-secondary">Shipping Address</h6>
                                                    <p className="small">
                                                        <span className="d-block">{UserOrderDetail.billing_address.first_name} {UserOrderDetail.billing_address.last_name}</span>
                                                        {UserOrderDetail.billing_address.company !== null ? <span className="d-block">{UserOrderDetail.billing_address.company} </span> : null}
                                                        <span className="d-block">
                                                            {UserOrderDetail.billing_address.address1} {UserOrderDetail.billing_address.address2}<br />
                                                            {UserOrderDetail.billing_address.city}&nbsp;{UserOrderDetail.billing_address.province}&nbsp;{UserOrderDetail.billing_address.zip}<br />
                                                            {UserOrderDetail.billing_address.country}
                                                        </span>
                                                        {UserOrderDetail.billing_address.phone != null
                                                            ? <span className="d-block">
                                                                <a href={"tel:" + UserOrderDetail.billing_address.phone}>Phone {UserOrderDetail.billing_address.phone}</a>
                                                            </span>
                                                            : ''}
                                                    </p></></>}
                                        </Col>
                                        {was_book !== null && was_book === "true" ?
                                            <Col lg={3} md={6} xs={12}>
                                                <Card.Text as="div">
                                                    <h6 className="display-6 text-secondary">Shipments</h6>
                                                    <p className="small">
                                                        <span className="d-block">Local Delivery</span>
                                                    </p>
                                                </Card.Text>
                                            </Col> : ''}
                                        <Col lg={3} md={6} xs={12}>
                                            <Card.Text as="div">
                                                <h6 className="display-6 text-secondary">Payment Information</h6>
                                                <p className="small">
                                                    <span className="d-block">{UserOrderDetail.payment_gateway_names[0] !== "shopify_payments" ? UserOrderDetail.payment_gateway_names[0] : "Credit Card"}</span>
                                                </p>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <div className="bg-white shadow border-light">
                                <div className="thankyou_programs_listing">
                                    {UserOrderDetail.line_items.map(product =>
                                        <Card className="thankyou_program_card border-0">
                                            <Card.Body>
                                                <div className="program_cate2 d-none d-md-flex justify-content-top flex-column">
                                                    <img src={getTags(product.title)} alt={product.title} />
                                                </div>
                                                <Row className="justify-content-between">
                                                    <Col xl={6} md={7} xs={12}>
                                                        <Link to={"/product/" + getSlug(product.title)}>
                                                            <Card.Title as="h3">{product.title}</Card.Title></Link>
                                                        <Card.Text as="div">
                                                            <ListGroup horizontal as="ul" className="justify-content-between">
                                                                <ListGroup.Item as="li" className="code_number">Code {product.sku}</ListGroup.Item>
                                                            </ListGroup>
                                                        </Card.Text>
                                                    </Col>
                                                    <Col lg={4} md={5} xs={12}>
                                                        <ListGroup horizontal as="ul" className="justify-content-between mt-4 mt-md-0">
                                                            <ListGroup.Item as="li" className="text-md-end">
                                                                <h6 className="display-6 text-secondary">Price</h6>
                                                                <p>${numberWithCommas(product.price)}</p>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item as="li" className="text-center">
                                                                <h6 className="display-6 text-secondary">Qty</h6>
                                                                <p>{product.quantity}</p>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item as="li" className="text-end">
                                                                <h6 className="display-6 text-secondary">Total</h6>
                                                                <p>${numberWithCommas(product.quantity * product.price)}</p>
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>)}
                                </div>
                                <div className="subtotal_block">
                                    <Row className="justify-content-end">
                                        <Col xl={7} lg={9} md={11} xs={12}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h3 className="mb-0">Subtotal</h3>
                                                <h3 className="mb-0">${numberWithCommas(UserOrderDetail.subtotal_price)}</h3>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="total_block">
                                    <Row className="justify-content-end">
                                        <Col xl={7} lg={9} md={11} xs={12}>
                                            <ListGroup horizontal as="ul" className="flex-column">
                                                {UserOrderDetail.shipping_lines.map(shippingcharge =>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between">
                                                        <p className="text-secondary">{shippingcharge.code} </p>
                                                        <p className="text-secondary ms-3 ms-md-5">${numberWithCommas(shippingcharge.price)}</p>
                                                    </ListGroup.Item>
                                                )}

                                            </ListGroup>
                                            <div className="order_gst_total mt-5 d-flex align-items-center justify-content-between">
                                                <h3 className="mb-0 text-md-end">Order Total(Include GST)</h3>
                                                <h3 className="mb-0 ms-3 ms-md-5">$ {numberWithCommas(UserOrderDetail.total_price)}</h3>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
    return <div className="pre_loader"><LoaderIcon /> <h5>Please wait, your order is being processed</h5></div>;
}
