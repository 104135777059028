import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#004e6d",
  width = "20.696",
  height = "20.5",
  className = "",
  viewBox = "0 0 20.696 20.5"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(0.348 0.5)">
    <path d="M10,15,6.58,19.4l.206-5.567L1.34,15l3.736-4.132L.152,8.264,5.67,7.5l-2.1-5.16L8.29,5.3,10,0l1.71,5.3L16.428,2.34,14.33,7.5l5.518.764-4.924,2.6L18.66,15l-5.446-1.17L13.42,19.4Z" fill={fill} stroke={stroke} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  </g>
  </svg>
);

export default SVG;
