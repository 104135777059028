import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke = "#fff",
  width = "30",
  height = "15.121",
  className = "",
  viewBox = "0 0 30 15.121"
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.688 490.688"><path xmlns="http://www.w3.org/2000/svg" d="M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083  c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262  c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279  C250.748,372.281,248.039,373.408,245.213,373.415z" fill="#91b2c1" data-original="#91b2c1" className=""></path></svg>
);

export default SVG;



