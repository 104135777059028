// versionControl.js

// Version number of your application
const store_version = '3.0.1';

// Function to check and reset localStorage
export function checkAndResetLocalStorage() {

    const useStoreVersion = localStorage.getItem("store_version") !== null ? localStorage.getItem("store_version") : store_version;
  
   
      if (useStoreVersion !== undefined && store_version !== useStoreVersion) {
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("checkout_token");
        localStorage.setItem("store_version", store_version);
        window.location.reload();
      }
      else {
        localStorage.setItem("store_version", store_version);
      }
}