import React from "react";

const SVG = ({
  style = {},
  //fill = "none",
  //stroke="#989795",
  width = "20",
  height = "21.096",
  className = "",
  viewBox = "0 0 20 21.096"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(0 0.501)"><path d="M7,0A7.642,7.642,0,0,0,0,8c0,7.5,7,12,7,12s7-4.5,7-12A7.642,7.642,0,0,0,7,0Z" transform="translate(3)" fill="none" stroke="#004e6d" strokeMiterlimit="10" strokeWidth="1"/><circle cx="3" cy="3" r="3" transform="translate(7 4)" fill="none" stroke="#004e6d" strokeMiterlimit="10" strokeWidth="1"/></g></svg>
);

export default SVG;
