import React from "react";

const SVG = ({
  style = {},
  fill ='none',
  stroke='#004e6d',
  width = "20",
  height = "20.5",
  className = "",
  viewBox = "0 0 20 20.5"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path d="M0,0,2.5,2.794,5,0,7.5,2.794,10,0l2.5,2.794L15,0V19H0Z" transform="translate(2.5 1)" fill={fill} stroke={stroke} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  <path d="M0,.5H5.5" transform="translate(9.5 11)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  <path d="M0,.5H10" transform="translate(5 13)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  <path d="M0,.5H10" transform="translate(5 15)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  <path d="M0,.5H5.5" transform="translate(5 17)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  <circle cx="1.5" cy="1.5" r="1.5" transform="translate(9 6)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
  <text transform="translate(4 11)" fill={stroke} fontSize="7"><tspan x="0" y="0">$</tspan></text>
</svg>
);

export default SVG;