import React from "react";

const SVG = ({
  style = {},
  fill = "#707070",
  stroke="none",
  width = "15px",
  className = "",
  viewBox = "0 0 15.196 15.2"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
    fill={fill}
    d="M19.518,18.593l-4.226-4.266a6.023,6.023,0,1,0-.914.926l4.2,4.238a.65.65,0,0,0,.918.024A.655.655,0,0,0,19.518,18.593ZM10.559,15.3a4.756,4.756,0,1,1,3.364-1.393A4.727,4.727,0,0,1,10.559,15.3Z" transform="translate(-4.5 -4.493)" />
  </svg>
);

export default SVG;