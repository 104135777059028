import React, { useState, useEffect, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    PlusPrimaryIcon,
    InformationIcon,
    LoaderIcon
} from "../../icons";
import {
    Container,
    Button,
    Row,
    Col,
    ListGroup
} from 'react-bootstrap';
import pm from '../../images/pm-ic.png';
import ps from '../../images/ps-ic.png';
import sl from '../../images/sl-ic.png';
import ep from '../../images/ep-ic.png';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { AdminSidebar } from './Sidebar/AdminSidebar';
import BlueBg from "../../images/my-profile-banner.png";
import { ManuallyCPD } from '../Modals/ManuallyCPD';


export const AdminCPDPoints = (props) => {
    //console.log('cpd_props', props.UserData);
    let history = useHistory();
    const My_Account_button_click = () => {
        if (props.useLoggedIn)
            history.push('/admin-my-library');
        else
            props.ShowLoginPopup_();
    }

    // Orders states
    const [cpdPoints, setCpdPoints] = useState([]);
    const [CustomcpdPoints, setCustomcpdPoints] = useState([]);

    const [loading, setLoading] = useState(true);
    const [program_years_data_past_upcoming, set_program_years_data_past_upcoming] = useState([]);
    const [year_wise__filter_data, set_year_wise__filter_data] = useState([]);
    let past_program_index = 0;
    const [my_orders, set_my_orders] = useState([]);

    let order_item_index = 0;
    let isMounted;

    const [user_email, set_user_email] = useState('');
    const [user_token, set_user_token] = useState('');
    const [user_firstname, set_user_firstname] = useState('');
    const [user_lastname, set_user_lastname] = useState('');
    const [error, set_error] = useState('');
    let cpd_years_index = 0;

    // useEffect(() => {
    //     var userDetail = (localStorage.getItem("userDetail"));

    //     var userLoginData = JSON.parse(userDetail);
    //     if(userLoginData !== null && userLoginData !== undefined)
    //     {
    //         var user_email = userLoginData.email;
    //         var accessToken = userLoginData.accessToken;
    //         if(user_email !== undefined && user_email !== '')
    //         {
    //             set_user_email(userLoginData.email);
    //         }
    //         if(accessToken !== undefined && accessToken !== '')
    //         {
    //             let orders_data = getData(user_email);
    //             set_user_token(userDetail.accessToken);
    //         }
    //     }
    //     // alert(user_email);
    // },[])

    async function getData(email = '', user_lname = '') {
        if (email == '') {
            email = user_email;
        }
        if (user_lname == '') {
            user_lname = user_lastname;
        }
        if (email != '' && user_lname != '') {
            await axios.get(`${props.EngineURL}salesforce-query/checkout/new-cpd-leo.php?contact_id=${email}&user_lname=${user_lname}`, { crossdomain: true })
                .then(function (response) {
                    if (response.data.error !== undefined && response.data.error !== '') {
                        set_error(response.data.error);
                    }
                    else {
                        set_error('');
                        //console.log('response', response);
                        // handle success
                        setCpdPoints(response.data.program_years_data);
                        setCustomcpdPoints(response.data.custom_points);
                        set_program_years_data_past_upcoming(response.data.program_years_data_past_upcoming);
                    }
                    setLoading(false);
                    return response;
                })
                .catch(function (error) {
                    // handle error
                })
                .then(function () {
                    // always executed
                });
        }

    }

    useEffect(() => {
        var userDetail = (localStorage.getItem("userDetail"));
        var userLoginData = JSON.parse(userDetail);
        let user_lname = '';
        let accessToken = '';
        if (props.UserData != undefined && props.UserData != null && props.UserData.customer !== null) {
            // console.log('setting');
            set_user_lastname(props.UserData.customer.lastName);
            set_user_firstname(props.UserData.customer.firstName)
            user_lname = props.UserData.customer.lastName;
            // console.log('get_user_lname', user_lname);
            //console.log('user_lastname', user_lastname);
            set_user_lastname(user_lname);
        }
        if (userLoginData !== null && userLoginData !== undefined) {
            var login_user_email = userLoginData.email;
            accessToken = userLoginData.accessToken;
            if (login_user_email !== undefined && login_user_email !== '') {
                set_user_email(userLoginData.email);
            }
            if (accessToken !== undefined && accessToken !== '' && user_lname != '') {

                set_user_token(userDetail.accessToken);
            }
        }
        if (accessToken !== undefined && accessToken !== '' && user_lname != '') {
            //  console.log('user_lnamedddd', user_lname);
            let orders_data = getData(login_user_email, user_lname);
        }
        // console.log('my props', props.UserData);
    }, [props.UserData]);


    useEffect(() => {
        // console.log('cpdPoints', cpdPoints);
        // console.log('props.useLoggedIn', props.useLoggedIn);
        // console.log('error', props.useLoggedIn);
    }, [cpdPoints]);

    useEffect(() => {
        isMounted = true;
        let orders_data = getData();

        if (isMounted) {

        }

        return () => {
            isMounted = false;
        };
    }, []);

    const doLogout = () => {
        localStorage.removeItem("userDetail");
        localStorage.setItem('isLoggedin', false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        window.location.href = "/";
    }

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
}

    const [isPdfDownload, setPdfDownloading] = useState(false);
    const CPD_PDF = (data, cpd_year_id) => {
        setPdfDownloading(true);
        var cpd_history = [];
        data.map((year_program_inner) => {
            let program_name = year_program_inner.program_name;
            let program_start_date_format = year_program_inner.program_start_date_format;
            let Ethics = year_program_inner.Ethics_Professional_Responsibility__c;
            let Practice_Management = year_program_inner.Practice_Management_Business_Skills__c;
            let Professional_Skills = year_program_inner.Professional_Skills__c;
            let Substantive_Law = year_program_inner.Substantive_Law__c;
            cpd_history.push({
                "date": program_start_date_format,
                "name": program_name,
                "ethics": Ethics,
                "practice": Practice_Management,
                "professional": Professional_Skills,
                "substantive": Substantive_Law
            })
        })
        if(CustomcpdPoints[cpd_year_id] !== undefined)
        {
            CustomcpdPoints[cpd_year_id].map((year_program_inner) => {
                let program_name = year_program_inner.program_name;
                let program_start_date_format = year_program_inner.program_date;
                let Ethics = year_program_inner.ep;
                let Practice_Management = year_program_inner.pm;
                let Professional_Skills = year_program_inner.ps;
                let Substantive_Law = year_program_inner.sl;
                cpd_history.push({
                    "date": formatDate(program_start_date_format),
                    "name": program_name,
                    "ethics": Ethics,
                    "practice": Practice_Management,
                    "professional": Professional_Skills,
                    "substantive": Substantive_Law
                })
            }) 
        }

        var SendData = {
            "year": cpd_year_id,
            "name": user_firstname+" "+user_lastname,
            "email": user_email,
            "cpd_points": cpd_history
        }

      
        axios.post(props.AdminPanelURL + 'api/cpd_pdf', SendData)
            .then(function (response) {

                if (response.status === 200) {
                    window.open(response.data.pdf, '_blank').focus();

                } else {
                    alert("Something went wrong!")
                }
                setPdfDownloading(false);
            });
    }

    function download_csv_file(csvFileData) {

        //define the heading for each row of the data
        var csv = 'Name,Type,Date,EP,PM,PS,SL,TOTAL\n';

        //merge the data with CSV
        csvFileData.forEach(function (row) {
            csv += row.join(',');
            csv += "\n";
        });

        //display the created CSV data on the web browser
        //document.write(csv);


        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        // hiddenElement.target = '_blank';

        //provide the name for the CSV file to be downloaded
        hiddenElement.download = 'CPD points.csv';
        hiddenElement.click();
    }

    const exportCpdPoints = (cpd_year_id) => {
        if (cpd_year_id != '') {
            let cpdPointsExport = cpdPoints[cpd_year_id];
            var csvFileData = [

            ];
            let export_subtotal = 0;
            cpdPointsExport.map((export_item) => {
                export_subtotal = parseFloat(export_subtotal) + parseFloat(export_item.Ethics_Professional_Responsibility__c) + parseFloat(export_item.Practice_Management_Business_Skills__c) + parseFloat(export_item.Professional_Skills__c) + parseFloat(export_item.Substantive_Law__c)
                let new_row = [
                    export_item.program_name,
                    export_item.product_record_type_name,
                    export_item.program_start_date_format,
                    export_item.Ethics_Professional_Responsibility__c,
                    export_item.Practice_Management_Business_Skills__c,
                    export_item.Professional_Skills__c,
                    export_item.Substantive_Law__c,
                    parseFloat(export_item.Ethics_Professional_Responsibility__c) + parseFloat(export_item.Practice_Management_Business_Skills__c) + parseFloat(export_item.Professional_Skills__c) + parseFloat(export_item.Substantive_Law__c)
                ];
                csvFileData.push(new_row);
            });
            csvFileData.push([
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'Subtotal: ' + export_subtotal,
            ]);

            download_csv_file(csvFileData);
        }
    }

    const [cdp_point_popup, set_cpd_point_popup] = useState(false);

    const randm = () => {
        return (Math.random() + 1).toString(36).substring(7);
    }
    const twoDigit = (n) => {
        return n > 9 ? "" + n : "0" + n;
    }
    const currentMonth = new Date().getMonth() + 1;
    const CPDyear = () => {
        return (currentMonth <= 3) ? parseInt(new Date().getFullYear() - 1) + "-" + new Date().getFullYear() : new Date().getFullYear() + "-" + parseInt(new Date().getFullYear() + 1);
    }
    // console.log(CustomcpdPoints);
    return (

        <div className="admin_page admin_cpd_points" style={{ backgroundImage: "url(" + BlueBg + ")" }}>
            <Helmet>
                <title>My CPD Points | Leo Cussen Centre for Law</title>
            </Helmet>
            <Container>
                {
                    props.useLoggedIn !== undefined && props.useLoggedIn == true && error == ''
                        ?

                        <div className="admin_wrapper position-relative">
                            {props.UserData !== null && props.UserData !== undefined ?
                                <div className='admin_details_block mb-5'>
                                    <h1 className="m-0">{props.Good},  {props.UserData.customer.firstName}</h1>
                                </div>
                                : null}
                            <Row>
                                <Col xl={3} lg={4} xs={12} className="d-none d-lg-block">
                                    <AdminSidebar doLogout={doLogout} />
                                </Col>
                                <Col xl={9} lg={8} xs={12}>
                                    <div className='admin_sidebar_mobile d-lg-none'>
                                        <AdminSidebar doLogout={doLogout} />
                                    </div>
                                    <div className="admin_right_content_block">
                                        <div className="information_block card border-exlight">
                                            <div className="information_content">
                                                <InformationIcon />
                                                <p className="small mb-0">
                                                    <strong>Disclaimer</strong>
                                                </p>
                                                <p className="mt-2 small small-dec mb-0">CPD customers please note that we cannot certify your attendance at our programs, only that you have registered to attend*. CPD point history is retained for the current and previous CPD years (1 April – 31 March). For enquiries contact <a href="mailto:registration@leocussen.edu.au">registration@leocussen.edu.au</a>.</p>
                                                <p className="mt-2 small small-extra mb-0">*Attendance at regulatory programs such as our Practice Management Course, Supervised Legal Traineeship Workshops and Mental Health First Aid for Legal Professionals Accredited Workshops can be certified.</p>
                                            </div>
                                        </div>
                                        <Row xs="auto" className='justify-content-between align-items-center mb-4'>
                                            <Col>
                                                <h4 className="m-0 text-dark">My CPD Points</h4>
                                            </Col>
                                            <Col className='d-flex align-items-center '>
                                                <div className='manually_add_point d-flex align-items-center' onClick={() => set_cpd_point_popup(true)}><PlusPrimaryIcon /> Manually add points</div>
                                            </Col>
                                        </Row>
                                        {
                                            cpdPoints !== null && cpdPoints !== undefined && Object.keys(cpdPoints).length > 0

                                                ?
                                                <div className="cpd_point_data_wrapper">


                                                    {

                                                        Object.keys(cpdPoints).map((cpd_event_year) => {
                                                            let cpd_year_id = cpd_event_year;
                                                            let cpd_year_programs = cpdPoints[cpd_year_id];
                                                            cpd_years_index = cpd_years_index + 1;
                                                            let year_type = program_years_data_past_upcoming !== undefined && program_years_data_past_upcoming[cpd_year_id] !== undefined ? program_years_data_past_upcoming[cpd_year_id] : '';
                                                            // console.log('year_type', year_type);
                                                            let Practice_Management_Business_Skills_otal_points = cpd_year_programs.reduce(function (sum, current) {
                                                                return parseInt(sum) + parseInt(current.Practice_Management_Business_Skills__c);
                                                            }, 0);

                                                            let Professional_Skills_total_points = cpd_year_programs.reduce(function (sum, current) {
                                                                return parseInt(sum) + parseInt(current.Professional_Skills__c);
                                                            }, 0);

                                                            let Substantive_Law_total_points = cpd_year_programs.reduce(function (sum, current) {
                                                                return parseInt(sum) + parseInt(current.Substantive_Law__c);
                                                            }, 0);

                                                            let Ethics_total_points = cpd_year_programs.reduce(function (sum, current) {
                                                                return parseInt(sum) + parseInt(current.Ethics_Professional_Responsibility__c);
                                                            }, 0);

                                                            if (CustomcpdPoints[cpd_year_id] !== undefined) {
                                                                Practice_Management_Business_Skills_otal_points += CustomcpdPoints[cpd_year_id].reduce(function (sum, current) {
                                                                    return parseInt(sum) + parseInt(current.pm);
                                                                }, 0);

                                                                Professional_Skills_total_points += CustomcpdPoints[cpd_year_id].reduce(function (sum, current) {
                                                                    return parseInt(sum) + parseInt(current.ps);
                                                                }, 0);

                                                                Substantive_Law_total_points += CustomcpdPoints[cpd_year_id].reduce(function (sum, current) {
                                                                    return parseInt(sum) + parseInt(current.sl);
                                                                }, 0);

                                                                Ethics_total_points += CustomcpdPoints[cpd_year_id].reduce(function (sum, current) {
                                                                    return parseInt(sum) + parseInt(current.ep);
                                                                }, 0);


                                                            }

                                                            let total_points = parseFloat(Practice_Management_Business_Skills_otal_points) + parseFloat(Professional_Skills_total_points) + parseFloat(Substantive_Law_total_points) + parseFloat(Ethics_total_points);

                                                            if (cpd_year_programs.length > 0 && total_points > 0) {
                                                                return <Fragment key={cpd_years_index}>
                                                                    <div className="admin_head d-flex flex-wrap align-items-center justify-content-between">
                                                                        <h5 className="text-secondary display-5 w-100 mb-2">{cpd_year_id}  <a className='btn' onClick={() => CPD_PDF(cpd_year_programs, cpd_year_id)}>{isPdfDownload ? 'Processing...' : 'Download'}</a></h5>
                                                                        <h5 className="m-0 h5 text-dark">My CPD Points: {total_points}</h5>
                                                                        <ListGroup horizontal as="ul" className="justify-content-between cpd_topics">
                                                                            <ListGroup.Item as="li">
                                                                                <span>{Ethics_total_points}</span>
                                                                                <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" />
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item as="li">
                                                                                <span>{Practice_Management_Business_Skills_otal_points}</span>
                                                                                <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" />
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item as="li">
                                                                                <span>{Professional_Skills_total_points}</span>
                                                                                <img src={ps} alt="Professional Skills" title="Professional Skills" />
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item as="li">
                                                                                <span>{Substantive_Law_total_points}</span>
                                                                                <img src={sl} alt="Substantiative Law" title="Substantiative Law" />
                                                                            </ListGroup.Item>
                                                                            {year_type == 'past' ?

                                                                                <ListGroup.Item as="li">
                                                                                    <a onClick={() => exportCpdPoints(cpd_year_id)} className="btn btn-primary rounded-pill">Export</a>
                                                                                </ListGroup.Item>
                                                                                : null
                                                                            }
                                                                        </ListGroup>
                                                                    </div>
                                                                    <div className="cpd_point_data_head shadow bg-white rounded-2">
                                                                        <ListGroup horizontal as="ul" className="align-items-center justify-content-between">
                                                                            <ListGroup.Item as="li">Event</ListGroup.Item>
                                                                            <ListGroup.Item as="li"></ListGroup.Item>
                                                                            <ListGroup.Item as="li">Type</ListGroup.Item>
                                                                            <ListGroup.Item as="li">Date</ListGroup.Item>
                                                                            <ListGroup.Item as="li">CPD Points</ListGroup.Item>
                                                                        </ListGroup>
                                                                    </div>

                                                                    {
                                                                        cpd_year_programs.map((year_program_inner) => {
                                                                            let program_name = year_program_inner.program_name;
                                                                            let program_name_slug = year_program_inner.Slug__c !== null && year_program_inner.Slug__c !== undefined ? year_program_inner.Slug__c : '';
                                                                            let attendee_names = year_program_inner.attendees;
                                                                            let order_name = year_program_inner.Shopify_Order_Number__c;
                                                                            let Ethics = year_program_inner.Ethics_Professional_Responsibility__c;
                                                                            let Practice_Management = year_program_inner.Practice_Management_Business_Skills__c;
                                                                            let Professional_Skills = year_program_inner.Professional_Skills__c;
                                                                            let Substantive_Law = year_program_inner.Substantive_Law__c;
                                                                            let program_record_type = year_program_inner.product_record_type_name;
                                                                            let program_start_date = year_program_inner.program_start_date_format;
                                                                            let total_event_points = parseFloat(Ethics) + parseFloat(Practice_Management) + parseFloat(Professional_Skills) + parseFloat(Substantive_Law);

                                                                            if (total_event_points > 0) {
                                                                                return <div className="cpd_point_data_listing_block" key={order_name}>
                                                                                    <div className="cpd_point_details_card card shadow border-light">
                                                                                        <ListGroup horizontal as="ul" className="align-items-center justify-content-between">
                                                                                            <ListGroup.Item as="li" className="cpd_point_property_block">
                                                                                                <h6 className="cpd_info"><Link to={'/product/' + program_name_slug}>{program_name}</Link></h6>
                                                                                                <p className="attendee">Attendees: {attendee_names !== undefined && attendee_names !== null ? attendee_names.join(', ') : null}</p>
                                                                                                <p className="cpd_order_no">Order: {order_name}</p>
                                                                                            </ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_topics">
                                                                                                <ListGroup horizontal as="ul" className="justify-content-between">
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{Ethics}</span>
                                                                                                        <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{Practice_Management}</span>
                                                                                                        <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{Professional_Skills}</span>
                                                                                                        <img src={ps} alt="Professional Skills" title="Professional Skills" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{Substantive_Law}</span>
                                                                                                        <img src={sl} alt="Substantiative Law" title="Substantiative Law" />
                                                                                                    </ListGroup.Item>
                                                                                                </ListGroup>
                                                                                            </ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_type">{program_record_type}</ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_date">{program_start_date}</ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_total">{total_event_points}</ListGroup.Item>
                                                                                        </ListGroup>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        })

                                                                    }
                                                                    {
                                                                        CustomcpdPoints[cpd_year_id] !== undefined
                                                                            ?
                                                                            CustomcpdPoints[cpd_year_id].map((custom_program) => {
                                                                                let total_event_points = parseInt(custom_program.ep) + parseInt(custom_program.pm) + parseInt(custom_program.ps) + parseInt(custom_program.sl);
                                                                                let newDate = new Date(custom_program.program_date)
                                                                                return <div className="cpd_point_data_listing_block manually-addded" key={randm()}>
                                                                                    <div className="cpd_point_details_card card shadow border-light">
                                                                                        <ListGroup horizontal as="ul" className="align-items-center justify-content-between">
                                                                                            <ListGroup.Item as="li" className="cpd_point_property_block">
                                                                                                <h6 className="cpd_info">{custom_program.program_name}</h6>
                                                                                                <p className="cpd_order_no">Training provider: {custom_program.training_provider}</p>
                                                                                            </ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_topics">
                                                                                                <ListGroup horizontal as="ul" className="justify-content-between">
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{custom_program.ep}</span>
                                                                                                        <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{custom_program.pm}</span>
                                                                                                        <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{custom_program.ps}</span>
                                                                                                        <img src={ps} alt="Professional Skills" title="Professional Skills" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{custom_program.sl}</span>
                                                                                                        <img src={sl} alt="Substantiative Law" title="Substantiative Law" />
                                                                                                    </ListGroup.Item>
                                                                                                </ListGroup>
                                                                                            </ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_type">{custom_program.program_format}</ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_date">{newDate.getDate() + '/' + twoDigit(newDate.getMonth() + 1) + '/' + newDate.getFullYear()}</ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_total">{total_event_points}</ListGroup.Item>
                                                                                        </ListGroup>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                            : null
                                                                    }


                                                                </Fragment>
                                                            }
                                                        })
                                                    }





                                                </div>
                                                : loading ? <div className="pre_loader"><LoaderIcon /></div> : null

                                        }
                                        {
                                            cpdPoints === null || cpdPoints === undefined || Object.keys(cpdPoints).length === 0
                                                ?
                                                CustomcpdPoints[CPDyear()] !== undefined
                                                    ?
                                                    <div className="cpd_point_data_wrapper">
                                                        {CustomcpdPoints[CPDyear()].map((cpd_event_year) => {
                                                            console.log("cpd_event_year - " + CPDyear());
                                                            let cpd_year_id = CPDyear();
                                                            let cpd_year_programs = CustomcpdPoints[cpd_year_id];
                                                            // console.log('year_type', year_type);
                                                            let Practice_Management_Business_Skills_otal_points = 0;

                                                            let Professional_Skills_total_points = 0;

                                                            let Substantive_Law_total_points = 0;

                                                            let Ethics_total_points = 0;

                                                            if (CustomcpdPoints[cpd_year_id] !== undefined) {
                                                                Practice_Management_Business_Skills_otal_points += CustomcpdPoints[cpd_year_id].reduce(function (sum, current) {
                                                                    return parseInt(sum) + parseInt(current.pm);
                                                                }, 0);

                                                                Professional_Skills_total_points += CustomcpdPoints[cpd_year_id].reduce(function (sum, current) {
                                                                    return parseInt(sum) + parseInt(current.ps);
                                                                }, 0);

                                                                Substantive_Law_total_points += CustomcpdPoints[cpd_year_id].reduce(function (sum, current) {
                                                                    return parseInt(sum) + parseInt(current.sl);
                                                                }, 0);

                                                                Ethics_total_points += CustomcpdPoints[cpd_year_id].reduce(function (sum, current) {
                                                                    return parseInt(sum) + parseInt(current.ep);
                                                                }, 0);


                                                            }


                                                            let total_points = parseFloat(Practice_Management_Business_Skills_otal_points) + parseFloat(Professional_Skills_total_points) + parseFloat(Substantive_Law_total_points) + parseFloat(Ethics_total_points);

                                                            if (total_points > 0) {
                                                                return <Fragment key={cpd_years_index}>
                                                                    <div className="admin_head d-flex flex-wrap align-items-center justify-content-between">
                                                                        <h5 className="text-secondary display-5 w-100 mb-2">{cpd_year_id}</h5>
                                                                        <h5 className="m-0 h5 text-dark">My CPD Points: {total_points}</h5>
                                                                        <ListGroup horizontal as="ul" className="justify-content-between cpd_topics">
                                                                            <ListGroup.Item as="li">
                                                                                <span>{Ethics_total_points}</span>
                                                                                <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" />
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item as="li">
                                                                                <span>{Practice_Management_Business_Skills_otal_points}</span>
                                                                                <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" />
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item as="li">
                                                                                <span>{Professional_Skills_total_points}</span>
                                                                                <img src={ps} alt="Professional Skills" title="Professional Skills" />
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item as="li">
                                                                                <span>{Substantive_Law_total_points}</span>
                                                                                <img src={sl} alt="Substantiative Law" title="Substantiative Law" />
                                                                            </ListGroup.Item>

                                                                        </ListGroup>
                                                                    </div>
                                                                    <div className="cpd_point_data_head shadow bg-white rounded-2">
                                                                        <ListGroup horizontal as="ul" className="align-items-center justify-content-between">
                                                                            <ListGroup.Item as="li">Event</ListGroup.Item>
                                                                            <ListGroup.Item as="li"></ListGroup.Item>
                                                                            <ListGroup.Item as="li">Type</ListGroup.Item>
                                                                            <ListGroup.Item as="li">Date</ListGroup.Item>
                                                                            <ListGroup.Item as="li">CPD Points</ListGroup.Item>
                                                                        </ListGroup>
                                                                    </div>


                                                                    {
                                                                        CustomcpdPoints[cpd_year_id] !== undefined
                                                                            ?
                                                                            CustomcpdPoints[cpd_year_id].map((custom_program) => {
                                                                                let total_event_points = parseInt(custom_program.ep) + parseInt(custom_program.pm) + parseInt(custom_program.ps) + parseInt(custom_program.sl);
                                                                                let newDate = new Date(custom_program.program_date)
                                                                                return <div className="cpd_point_data_listing_block manually-addded" key={randm()}>
                                                                                    <div className="cpd_point_details_card card shadow border-light">
                                                                                        <ListGroup horizontal as="ul" className="align-items-center justify-content-between">
                                                                                            <ListGroup.Item as="li" className="cpd_point_property_block">
                                                                                                <h6 className="cpd_info">{custom_program.program_name}</h6>
                                                                                                <p className="cpd_order_no">Training provider: {custom_program.training_provider}</p>
                                                                                            </ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_topics">
                                                                                                <ListGroup horizontal as="ul" className="justify-content-between">
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{custom_program.ep}</span>
                                                                                                        <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{custom_program.pm}</span>
                                                                                                        <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{custom_program.ps}</span>
                                                                                                        <img src={ps} alt="Professional Skills" title="Professional Skills" />
                                                                                                    </ListGroup.Item>
                                                                                                    <ListGroup.Item as="li">
                                                                                                        <span>{custom_program.sl}</span>
                                                                                                        <img src={sl} alt="Substantiative Law" title="Substantiative Law" />
                                                                                                    </ListGroup.Item>
                                                                                                </ListGroup>
                                                                                            </ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_type">{custom_program.program_format}</ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_date">{newDate.getDate() + '/' + twoDigit(newDate.getMonth() + 1) + '/' + newDate.getFullYear()}</ListGroup.Item>
                                                                                            <ListGroup.Item as="li" className="cpd_point_total">{total_event_points}</ListGroup.Item>
                                                                                        </ListGroup>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                            : null
                                                                    }


                                                                </Fragment>
                                                            }

                                                        })}
                                                    </div>

                                                    : null

                                                : null

                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div >

                        :
                        <div className="text-center">
                            {
                                error != ''
                                    ?
                                    <h3 className="text-center">
                                        {
                                            error == 'user not found.' ?
                                                <p>There are no data found with your account. <a href='mailto:registration@leocussen.edu.au' className="btn-link">Get in touch</a></p>
                                                : 'Please login to access My profile section.'
                                        }
                                    </h3>
                                    : null
                            }
                            {
                                error == ''
                                    ?
                                    <>
                                        <h3>Please Login</h3>
                                        <a onClick={() => My_Account_button_click()} className="btn btn-primary rounded-pill m-0 stest"> Login</a>
                                    </>
                                    : null
                            }
                        </div>
                }
            </Container >
            <ManuallyCPD
                EngineURL={props.EngineURL}
                cdp_point_popup={cdp_point_popup}
                set_cpd_point_popup={set_cpd_point_popup}
                email={user_email}
            />
        </div >
    )
}
