import React from "react";

const SVG = ({
  style = {},
  fill = "#004e6d",
  stroke="none",
  width = "20px",
  height = "20px",
  className = "",
  viewBox = "0 0 29.121 29.121"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<g>
	<path fill={fill} stroke={stroke} d="M21.706,6.146c1.116,0,2.02-0.898,2.02-2.016V2.02c0-1.119-0.903-2.02-2.02-2.02
		s-2.019,0.9-2.019,2.02v2.111C19.688,5.248,20.59,6.146,21.706,6.146z"/>
	<path fill={fill} stroke={stroke} d="M28.882,3.494h-4.066v1.027c0,1.695-1.377,3.076-3.075,3.076c-1.7,0-3.074-1.381-3.074-3.076V3.494
		h-8.205v1.027c0,1.695-1.379,3.076-3.076,3.076s-3.075-1.38-3.075-3.076V3.494L0.208,3.443v25.678H2.26h24.604l2.049-0.006
		L28.882,3.494z M26.862,27.076H2.26V10.672h24.604v16.404H26.862z"/>
	<path fill={fill} stroke={stroke} d="M7.354,6.146c1.116,0,2.021-0.898,2.021-2.016V2.02C9.375,0.9,8.47,0,7.354,0S5.336,0.9,5.336,2.02
		v2.111C5.336,5.248,6.237,6.146,7.354,6.146z"/>
	<rect fill={fill} stroke={stroke} x="10.468" y="12.873" width="3.231" height="2.852"/>
	<rect fill={fill} stroke={stroke} x="15.692" y="12.873" width="3.234" height="2.852"/>
	<rect fill={fill} stroke={stroke} x="20.537" y="12.873" width="3.231" height="2.852"/>
	<rect fill={fill} stroke={stroke} x="10.468" y="17.609" width="3.231" height="2.85"/>
	<rect fill={fill} stroke={stroke} x="15.692" y="17.609" width="3.234" height="2.85"/>
	<rect fill={fill} stroke={stroke} x="20.537" y="17.609" width="3.231" height="2.85"/>
	<rect fill={fill} stroke={stroke} x="10.468" y="22.439" width="3.231" height="2.85"/>
	<rect fill={fill} stroke={stroke} x="5.336" y="17.609" width="3.229" height="2.85"/>
	<rect fill={fill} stroke={stroke} x="5.336" y="22.439" width="3.229" height="2.85"/>
	<rect fill={fill} stroke={stroke} x="15.692" y="22.439" width="3.234" height="2.85"/>
	<rect fill={fill} stroke={stroke} x="20.537" y="22.439" width="3.231" height="2.85"/>
</g>
  </svg>
);

export default SVG;