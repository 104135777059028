import React from "react";

const SVG = ({
  style = {},
  fillNone = "none",
  fill = "#004e6d",
  stroke="#004e6d",
  width = "18",
  height = "18",
  className = "",
  viewBox = "0 0 18 18"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(17.5 0.5)">
        <circle cx="2" cy="2" r="2" transform="translate(-4)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
        <circle cx="2" cy="2" r="2" transform="translate(-17 6)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
        <circle cx="2.5" cy="2.5" r="2.5" transform="translate(-5 12)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
        <path d="M0,0-9,4.5" transform="translate(-4 3)" fill={fillNone} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
        <path d="M0,5-8.5,0" transform="translate(-5 8.5)" fill={fillNone} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
    </g>
  </svg>
);

export default SVG;