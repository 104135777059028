import React from "react";

const SVG = ({
  style = {},
//   fill = "#707070",
//   stroke="none",
  width = "13.314px",
  height = "17.216px",
  className = "leo_brand",
  viewBox = "0 0 13.314 17.216"
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M6.661,17.216h0L1.393,11.95A4.643,4.643,0,0,1,0,8.553,4.938,4.938,0,0,1,1.432,5.195l.2-.2a4.377,4.377,0,0,0-.622,2.1,3.922,3.922,0,0,0,1.2,2.859L6.67,14.415,11.083,10a4.14,4.14,0,0,0,.651-4.951l.184.185a4.631,4.631,0,0,1,1.4,3.4,4.912,4.912,0,0,1-1.436,3.357L6.662,17.215Zm0-5.578h0L3.081,8.06a3.119,3.119,0,0,1-.935-2.281,3.316,3.316,0,0,1,.961-2.254l.719-.719a2.381,2.381,0,0,0-.25.977,2.113,2.113,0,0,0,.634,1.546L6.661,7.78,9.089,5.347a2.237,2.237,0,0,0,.653-1.529,2.157,2.157,0,0,0-.225-.984l.718.717a3.1,3.1,0,0,1,.936,2.28,3.3,3.3,0,0,1-.963,2.252L6.662,11.637ZM6.652,4.97h0L5.14,3.46a1.341,1.341,0,0,1-.4-.984A1.431,1.431,0,0,1,5.148,1.5L6.653,0,8.166,1.513a1.343,1.343,0,0,1,.405.984,1.422,1.422,0,0,1-.416.971l-1.5,1.5Z" transform="translate(0 0)" fill="#01293a"/></svg>
);

export default SVG;