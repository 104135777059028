import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#e6ab11",
  width = "30px",
  height = "30px",
  className = "",
  viewBox = "0 0 30 30"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-647 -495)">
    <path fill={fill} stroke={stroke} d="M4565.079,6771.934v-1.26s.31-1.71,1.592-.879,5.869,4.182,5.869,4.182a.964.964,0,0,1,0,1.615c-1.022.855-5.869,4.039-5.869,4.039s-1.5.642-1.592-.737S4565.079,6771.934,4565.079,6771.934Z" transform="translate(-3906.594 -6264.572)" strokeWidth="1.5"/>
    <g fill={fill} stroke={stroke} transform="translate(647 495)" strokeWidth="1.5">
      <circle cx="15" cy="15" r="15" stroke="none"/>
      <circle cx="15" cy="15" r="14.25" fill="none"/>
    </g>
  </g>
  </svg>
);

export default SVG;