import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#004e6d",
  width = "17.429px",
  className = "",
  viewBox = "0 0 17.429 17.429"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(0.429 0.429)">
    <circle cx="8" cy="8" r="8" transform="translate(0.071 0.071)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M0,0V5L3.5,8.5" transform="translate(8 3.5)" fill={fill} stroke={stroke} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  </g>
  </svg>
);

export default SVG;