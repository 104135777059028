import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    // Card,
    Button,
    Modal,
    Alert,
    Form,
    InputGroup,
    Col
} from 'react-bootstrap';
import axios from 'axios';

export const Invite = (props) => {
    const [userEmail, setUserEmail] = useState("");

    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);
    //console.log(privacyPolicy);
    const [userEmail_ER, setUserEmail_ER] = useState(false);

    var setUserEmaDil_ER_var = true;

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const handleSubmit = (event) => {

        setSuccessMessage(undefined);
        setErrorMessage(undefined);

        setUserEmail_ER(false);


        setUserEmaDil_ER_var = true;


        event.preventDefault();



        if (!userEmail) {
            setUserEmail_ER(true);
            setUserEmaDil_ER_var = false;
        }




        if (setUserEmaDil_ER_var) {
            setSomethingUpadating(true);
            var inquirData = {
                email: userEmail,
                user_first_name: props.userfname,
                user_last_name: props.userlname,
                user_email: props.useremail,
                refferal: props.userRefer
            };

            axios.post(props.EngineURL + 'invite/index.php', inquirData)
                .then(function (response) {

                    setTimeout(function () {
                        if (response != null) {
                            if (response.data.code == 200) {
                                setSuccessMessage(response.data.message);

                            }
                            else {
                                setErrorMessage(response.data.message);
                            }
                        }
                        else {
                            setErrorMessage("Something went wrong!");

                        }
                        setSomethingUpadating(false);
                    }, 500);

                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }


    return (
        <>
            <Modal className="signup_model" show={props.CanWeinvite} onHide={() => props.setCanWeinvite(false)}>
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body className="px-4">
                    {
                        SuccessMessage !== undefined ?
                            <h5 className="text-center">Your referral code has been sent.</h5> :
                            <Modal.Title className="text-center"> Share your referral code!</Modal.Title>}
                    <div className="about_page_wrapper">
                        {
                            SuccessMessage == undefined ?
                                <div className="wFormContainer" >
                                    <Form className="" onSubmit={handleSubmit}>
                                        <Form.Group as={Col} md="12" className="form-group">
                                            <Form.Label htmlFor="canwehelpemail_">Email Addresses of Contacts</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    id="canwehelpemail_"
                                                    className={userEmail_ER ? 'form-error' : ''}
                                                    placeholder="Your friend email address"
                                                    aria-describedby="inputGroupPrepend"
                                                    value={userEmail}
                                                    onChange={(e) => setUserEmail(e.target.value)}
                                                />
                                            </InputGroup>
                                            <Form.Text id="canwehelpemail__" muted>Please separate multiple email addresses with a comma (,)</Form.Text>
                                        </Form.Group>



                                        {
                                            ErrorMessage !== undefined ?
                                                <Alert variant="danger">
                                                    {ErrorMessage}
                                                </Alert>
                                                : ''
                                        }
                                        {
                                            SuccessMessage !== undefined ?
                                                <Alert variant="success">
                                                    {SuccessMessage}
                                                </Alert>
                                                : ''
                                        }
                                        {
                                            SomethingUpadating === true ?
                                                <Alert variant="primary">
                                                    Sending....
                                                </Alert>
                                                : ''
                                        }
                                        {
                                            SuccessMessage == undefined
                                                ? <Form.Group as={Col} xs="12" className="form-group text-center">
                                                    <Button variant="primary" type="submit" className="btn-lg border-2 rounded-2">Send</Button>
                                                </Form.Group> : ''
                                        }
                                    </Form>
                                </div> : null
                        }
                        {
                            ErrorMessage !== undefined ?
                                <Alert variant="danger">
                                    {ErrorMessage}
                                </Alert>
                                : null
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}