import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  stroke="#707070",
  width = "12px",
  height = "12px",
  className = "",
  viewBox = "0 0 12 12"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-478 -486)">
    <g fill={fill} stroke={stroke} transform="translate(478 486)" strokeWidth="1">
      <circle cx="6" cy="6" r="6" stroke="none"/>
      <circle cx="6" cy="6" r="5.5" fill="none"/>
    </g>
    <circle cx="2" cy="2" r="2" transform="translate(482 490)" fill="#707070"/>
  </g>
  </svg>
);

export default SVG;
