import React from "react";

const SVG = ({
  style = {},
  fill = "#e6ab11",
  width = "19px",
  height = "19px",
  className = "",
  viewBox = "0 0 19 19"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-3.375 -3.375)">
    <path fill={fill} d="M12.875,5.293a7.579,7.579,0,1,1-5.362,2.22,7.55,7.55,0,0,1,5.362-2.22m0-1.918a9.5,9.5,0,1,0,9.5,9.5,9.5,9.5,0,0,0-9.5-9.5Z"/>
    <path fill={fill} d="M18.442,20.188H16.523V14.478h1.918Zm0-7.582H16.523V10.688h1.918Z" transform="translate(-4.608 -2.563)"/>
  </g>
  </svg>
);

export default SVG;