import React from 'react'
import {
    FaceBookIcon,
    LinkedInIcon,
    TwitterIcon,
    LogoReversed,
} from "../../icons";
import Footerbg from "../../images/leo-pattern-deep-teal-light.png"
import InfoIc from "../../images/information-ic.png"
import {
    Container,
    Row,
    Col,
    Nav,
} from 'react-bootstrap';
import CTASection from '../Sections/CTASection';

export const Footer = (props) => {
    return (
        <>
            <CTASection EngineURL={props.EngineURL} />
            <footer className="footer position-relative" role="contentinfo" style={{ backgroundImage: "url(" + Footerbg + ")" }}>
                <div className='can_we_help_button' onClick={() => props.setCanWeHelpStatus(true)}>
                    <img src={InfoIc} alt="Information Icon" className='d-lg-none' />
                    <span className='d-none d-lg-block'>Can we help ?</span>
                </div>
                <div className="footer_top">
                    <Container>
                    <Row>
                        <Col sm={12} xs={6}>
                            <a className="footer-brand" href="/">
                                <LogoReversed />
                            </a>
                        </Col>
                        <Col sm={12} xs={6} className="text-end d-sm-none">
                            <a className="i-call" href="tel:1300039031">1300 039 031</a>
                            <div className="social_media">
                                <ul className="list-unstyled d-flex align-items-center justify-content-end m-0">
                                    <li className="mb-0"><a href="https://www.linkedin.com/company/leo-cussen-centre-for-law" className="d-block" target="_blank"><LinkedInIcon /></a></li>
                                    <li className="mb-0"><a href="https://twitter.com/LeoCussen" className="d-block" target="_blank"><TwitterIcon fill='#fff' /></a></li>
                                    <li className="mb-0"><a href="https://www.facebook.com/leocussencentreforlaw" className="d-block" target="_blank"><FaceBookIcon fill='#fff' /></a></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
                <div className="footer_middle">
                    <Container>
                        <Row>
                            <Col xl={2} xs={6}>
                                <div className="footer_navbar">
                                    <h6>Quick Links</h6>
                                    <Nav className="flex-column mb-3 mb-lg-0">
                                        <a href="https://www.leocussen.edu.au/about-us/" target="_blank" className="nav-link">About Us</a>
                                        <a href="https://www.leocussen.edu.au/about-us/our-graduate-alumni/" target="_blank" className="nav-link">Our Graduate Alumni</a>
                                        <a href="https://www.leocussen.edu.au/cpd-for-lawyers-staff/" target="_blank" className="nav-link">Continuing Professional Development (CPD) Courses</a>
                                        <a href="https://store.leocussen.edu.au/corporate_training_division" target="_blank" className="nav-link">Corporate Training Division</a>
                                    </Nav>
                                </div>
                            </Col>
                            <Col xl={2} xs={6}>
                                <div className="footer_navbar">
                                    <h6>About</h6>
                                    <Nav className="flex-column mb-3 mb-lg-0">
                                        <a href="https://www.leocussen.edu.au/about-us/our-organisation/" target="_blank" className="nav-link">Our Organisation</a>
                                        <a href="https://www.leocussen.edu.au/about-us/careers/" target="_blank" className="nav-link">Careers</a>
                                        <a href="https://www.leocussen.edu.au/about-us/our-graduate-alumni/" target="_blank" className="nav-link">Our Graduate Alumni</a>
                                        <a href="https://www.leocussen.edu.au/about-us/our-organisation/links-and-resources/" target="_blank" className="nav-link">Online Legal Resources & Links</a>
                                    </Nav>
                                </div>
                            </Col>
                            <Col xl={2} xs={6}>
                                <div className="footer_navbar">
                                    <h6>Law Students & Graduates</h6>
                                    <Nav className="flex-column mb-3 mb-lg-0">
                                        <a href="https://www.leocussen.edu.au/practical-legal-training/" target="_blank" className="nav-link">Practical Legal Training</a>
                                        <a href="https://www.leocussen.edu.au/supervised-legal-trainees/" target="_blank" className="nav-link">Traineeship Workshops</a>
                                        <a href="https://www.leocussen.edu.au/practical-legal-training/about-our-plt/overseas-lawyers/" target="_blank" className="nav-link">Overseas Lawyers</a>
                                        <a href="https://www.leocussen.edu.au/practical-legal-training/about-our-plt/requalifying-lawyers/" target="_blank" className="nav-link">Requalifying Lawyers</a>
                                    </Nav>
                                </div>
                            </Col>
                            <Col xl={2} xs={6}>
                                <div className="footer_navbar">
                                    <h6>CPD Programs - Lawyers & Support Staff</h6>
                                    <Nav className="flex-column mb-3 mb-lg-0">
                                        <a href="https://www.leocussen.edu.au/cpd-for-lawyers-staff/about-our-cpd/" target="_blank" className="nav-link">About our CPD</a>
                                        <a href="https://store.leocussen.edu.au/" target="_blank" className="nav-link">Find CPD Programs</a>
                                        <a href="https://www.leocussen.edu.au/cpd-for-lawyers-staff/about-our-cpd/terms-and-conditions" target="_blank" className="nav-link">Terms & Conditions</a>
                                    </Nav>
                                </div>
                            </Col>
                            <Col xl={2} xs={6}>
                                <div className="footer_navbar">
                                    <Nav className="flex-column mb-3 mb-lg-0">
                                        <a href="https://store.leocussen.edu.au/corporate_training_division" target="_blank" className="nav-link"><h6 className="mb-0">Corporate Training Division</h6></a>
                                        <a href="https://store.leocussen.edu.au/" target="_blank" className="nav-link"><h6 className="mb-0">Find Programs</h6></a>
                                        <a href="https://www.leocussen.edu.au/contact/" target="_blank" className="nav-link"><h6 className="mb-0">Contact Us</h6></a>
                                    </Nav>
                                </div>
                            </Col>
                            <Col xl={2} xs={6} className="text-sm-end d-none d-sm-block">
                                <a className="i-call" href="tel:1300039031">1300 039 031</a>
                                <div className="social_media">
                                    <ul className="list-unstyled d-flex align-items-center justify-content-sm-end m-0">
                                        <li className="mb-0"><a href="https://www.linkedin.com/company/leo-cussen-centre-for-law" className="d-block" target="_blank"><LinkedInIcon /></a></li>
                                        <li className="mb-0"><a href="https://twitter.com/LeoCussen" className="d-block" target="_blank"><TwitterIcon fill='#fff' /></a></li>
                                        <li className="mb-0"><a href="https://www.facebook.com/leocussencentreforlaw" className="d-block" target="_blank"><FaceBookIcon fill='#fff' /></a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="footer_bottom bg-dark">
                    <Container>
                        <ul className="list-unstyled mb-0 d-flex flex-wrap">
                            <li><a href="https://www.leocussen.edu.au/copyright/" target="_blank">Copyright</a></li>
                            <li><a href="https://www.leocussen.edu.au/disclaimer/" target="_blank">Disclaimer</a></li>
                            <li><a href="https://www.leocussen.edu.au/privacy-policy/" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://outlook.office.com/" target="_blank">Staff</a></li>
                            <li><a href="https://www.leocussen.edu.au/sitemap/" target="_blank">Sitemap</a></li>
                            <li><a href="https://www.leocussen.edu.au/contact/" target="_blank">Contact</a></li>
                        </ul>
                        <p className="mb-0 text-white">© 2022 Leo Cussen Institute trading as Leo Cussen Centre for Law. All Rights Reserved. ABN 16 949 282 178. CRICOS providing number: 02224A</p>
                    </Container>
                </div>
            </footer>
        </>
    )
}
