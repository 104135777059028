import React from "react";

const SVG = ({
  style = {},
  //fill = "none",
  //stroke="#004e6d",
  width = "20px",
  height = "20.5px",
  className = "",
  viewBox = "0 0 20 20.5"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(0 0.5)"><circle cx="4.5" cy="4.5" r="4.5" transform="translate(5.571)" fill="none" stroke="#004e6d" strokeMiterlimit="10" strokeWidth="1"/><path d="M17.609,7.094A9,9,0,0,0,0,7.158" transform="translate(1.26 12)" fill="none" stroke="#004e6d" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/></g></svg>
);

export default SVG;
