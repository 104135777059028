import React, {
  useState,
  useEffect
} from 'react'

import {
  ProductGrid
} from './ProductGrid/ProductGrid'


export const ProductListing = (props) => {

  /***
   * Values from Parent component
   */
  const totalProduct = props.data.length;
  const Ppp = Math.ceil(props.data.length / props.pageSize);
  const offset = (props.currentPage - 1) * props.pageSize;
  const nextt = props.currentPage * props.pageSize
  const newdata = props.data.slice(offset, nextt);

  /***
   * Return total product to main component
   */
  useEffect(() => {
    props.statehandler(totalProduct);
  }, [totalProduct]);
  const randm = () => {
    return (Math.random() + 1).toString(36).substring(7);
}

  /**
   * retutn product grid
   */


  if (newdata.length === 0) {

    return (
      <div><h4>No programs could be found under the current search criteria</h4></div>
    )
  }
  return newdata.map(products => {
    let product = Object.values(products)[1];
    return <ProductGrid key={
      randm()
    }
      product={
        product
      }
      GAurl={props.GAurl}
      Program_structure_images={props.Program_structure_images}
      AddtoCart={props.AddtoCart}
      is_ten_minite_less={props.is_ten_minite_less}
      program_date_is_gone_or_not={props.program_date_is_gone_or_not}
      AddtoWishlist={props.AddtoWishlist}
      getAllWishlist={props.getAllWishlist}
      Program_structure_color={props.Program_structure_color}
      expandAll={props.expandAll}
    />
  });
}
