import React from "react";

const SVG = ({
  style = {},
  fill = "#004e6d",
  stroke="none",
  width = "20px",
  height = "20px",
  className = "",
  viewBox = "0 0 512.017 512.017"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<g>
	<path fill={fill} stroke={stroke} d="M330.682,32.202h-53.333c-3.88-0.008-7.458,2.092-9.344,5.483l-97.323,175.211L73.338,37.685
		c-1.882-3.384-5.45-5.483-9.323-5.483H10.682C4.791,32.194,0.008,36.962,0,42.854c-0.003,1.844,0.473,3.657,1.38,5.263
		l137.131,242.603l-10.496,178.197c-0.342,5.881,4.149,10.926,10.03,11.267c0.212,0.012,0.424,0.018,0.637,0.018h64
		c5.891,0.01,10.675-4.758,10.685-10.649c0-0.212-0.006-0.425-0.018-0.637l-10.475-178.197L339.983,48.117
		c2.898-5.129,1.09-11.636-4.038-14.534C334.339,32.675,332.526,32.199,330.682,32.202z"/>
	<path fill={fill} stroke={stroke} d="M416.015,416.202c-17.673,0-32,14.327-32,32s14.327,32,32,32s32-14.327,32-32
		S433.688,416.202,416.015,416.202z M416.015,458.869c-5.891,0-10.667-4.776-10.667-10.667c0-5.891,4.776-10.667,10.667-10.667
		c5.891,0,10.667,4.776,10.667,10.667C426.682,454.093,421.906,458.869,416.015,458.869z"/>
	<path fill={fill} stroke={stroke} d="M435.834,373.535c0.511,0.036,1.025,0.036,1.536,0c5.007-0.016,9.328-3.513,10.389-8.405
		c64.256-296.661,64.256-299.477,64.256-300.928c0.212-17.673-13.943-32.172-31.616-32.384
		c-17.673-0.212-32.172,13.943-32.384,31.616l-21.333,298.667C426.22,367.735,430.235,372.751,435.834,373.535z"/>
</g>
  </svg>
);

export default SVG;
