import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import {
    SearchIcon,
    LocationIcon,
    DocumentIcon,
    LiveStreamIcon,
    LoaderIcon,
    GoogleIcon,
    YahooIcon,
    OutlookIcon,
    ICalendarIcon,
    InformationIcon
} from "../../icons";
import {
    Container,
    Row,
    Col,
    ListGroup,
    Form,
    FormControl,
    Button,
    Card,
    Modal
    // Modal,
} from 'react-bootstrap';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { AdminSidebar } from './Sidebar/AdminSidebar';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import BlueBg from "../../images/my-profile-banner.png";

export const AdminMyEvents = (props) => {


    let history = useHistory();
    const [events, setevents] = useState([]);
    const localizer = momentLocalizer(moment);
    const [LayoutView, setLayoutView] = useState("List View");
    const My_Account_button_click = () => {
        if (props.useLoggedIn)
            history.push('/admin-my-library');
        else
            props.ShowLoginPopup_();
    }

    // Orders states
    const [pastEvents, setPastEvent] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [calendarPopup, setCalendarPopup] = useState(false);
    const [googleLink, setGoogleLink] = useState('');
    const [yahooLink, setYahooLink] = useState('');
    const [outlookLink, setOutlookLink] = useState('');
    const [icalLink, setIcalLink] = useState('');

    const openCalendarPopUp = (program_name = '', start_date = '', total_hours = 0) => {
        // setCalendarPopup(true);
        const event = {
            title: program_name,
            description: "",
            start: start_date, //"2021-09-11 18:00:00",
            duration: [total_hours, "hour"],
            yahooStart: "20210911T1800",
            yahooEnd: "20210921T1100"
        };

        // Then fetch the link
        const google_link = google(event); // https://calendar.google.com/calendar/render...
        const outlook_event = outlook(event); // https://outlook.live.com/owa/...
        office365(event); // https://outlook.office.com/owa/...
        const yahoo_link = yahoo(event); // https://calendar.yahoo.com/?v=60&title=...
        const ical_event = ics(event); // standard ICS file based on https://icalendar.org
        setGoogleLink(google_link);
        setYahooLink(yahoo_link);
        setOutlookLink(outlook_event);
        setIcalLink(ical_event);
        window.location.href = ical_event;
    }

    // SEARCH QUERY
    const [upcomingSearch, setUpcomingSearch] = useState('');
    const [pastSearch, setPastSearch] = useState('');

    const [user_email, set_user_email] = useState('');
    const [user_token, set_user_token] = useState('');
    const [error, set_error] = useState('');
    const [user_lastname, set_user_lastname] = useState('');

    const reRenderPage = () => {
        setUpcomingSearch('');
        setPastSearch('');
        setLoading(true);
        getData(user_email);
    }

    let past_program_index = 0;
    const [my_orders, set_my_orders] = useState([]);

    useEffect(() => {
        // console.log('pastSearch', pastSearch);
        if (pastSearch == '') {
            setLoading(true);
            // console.log('lets reset query');
            getPastSearchData();
        }
    }, [pastSearch]);

    useEffect(() => {
        // console.log('upcomingSearch', upcomingSearch);
        if (upcomingSearch == '') {
            setLoading(true);
            // console.log('lets reset query');
            getSearchData();
        }
    }, [upcomingSearch]);


    let order_item_index = 0;
    let isMounted;



    useEffect(() => {
        var userDetail = (localStorage.getItem("userDetail"));
        var userLoginData = JSON.parse(userDetail);
        let user_lname = '';
        let accessToken = '';
        if (props.UserData !== undefined && props.UserData !== null && props.UserData.customer !== null) {
            // console.log('setting');
            set_user_lastname(props.UserData.customer.lastName);
            user_lname = props.UserData.customer.lastName;
            // console.log('get_user_lname', user_lname);
            // console.log('user_lastname', user_lastname);

        }
        if (userLoginData !== null && userLoginData !== undefined) {
            var login_user_email = userLoginData.email;
            accessToken = userLoginData.accessToken;
            if (login_user_email !== undefined && login_user_email !== '') {
                set_user_email(userLoginData.email);
            }
            if (accessToken !== undefined && accessToken !== '' && user_lname != '') {

                set_user_token(userDetail.accessToken);
            }
        }
        if (accessToken !== undefined && accessToken !== '' && user_lname != '') {
            set_user_lastname(user_lname);
            //  console.log('user_lnamedddd', user_lname);
            let orders_data = getData(login_user_email, user_lname);
        }
        // console.log('my props', props.UserData);
    }, [props.UserData]);


    async function getPastSearchData(email = '') {
        if (email == '') {
            email = user_email;
        }
        let lname = user_lastname;
        if (email != '' && lname != '') {

            await axios.get(`${props.EngineURL}salesforce-query/checkout/leo_events2_debug.php?contact_id=${email}&past_query=${pastSearch}&lname=${lname}`, { crossdomain: true })
                .then(function (response) {


                    if (response.data.program_registrations.past !== undefined) {
                        setPastEvent(response.data.program_registrations.past);
                    }

                    setLoading(false);
                    return response;
                })
                .catch(function (error) {
                    // handle error
                })
                .then(function () {
                    // always executed
                });
        }
    }

    async function getSearchData(email = '') {

        if (email == '') {
            email = user_email;
        }
        let lname = user_lastname;
        if (email != '' && lname != '') {
            await axios.get(`${props.EngineURL}salesforce-query/checkout/leo_events2_debug.php?contact_id=${email}&upcoming_query=${upcomingSearch}&lname=${lname}`, { crossdomain: true })
                .then(function (response) {
                    // handle success
                    if (response.data.program_registrations.upcoming !== undefined) {
                        setUpcomingEvents(response.data.program_registrations.upcoming);
                        UpdateCalendarEvent(response.data.program_registrations.upcoming);
                    }


                    setLoading(false);
                    return response;
                })
                .catch(function (error) {
                    // handle error
                })
                .then(function () {
                    // always executed
                });
        }

    }




    /****
     * Update calendar event list
     * Phase 2
     */
    const UpdateCalendarEvent = (myEvents) => {
        var event_list = [];

        for (var s = 0; s < myEvents.length; s++) {

            if (myEvents[s].program_start_date !== undefined && myEvents[s].program_start_date !== null) {
                event_list.push({
                    start: new Date(myEvents[s].program_start_date),
                    end: new Date(myEvents[s].program_end_date),
                    url: "https://store.leocussen.edu.au/product/" + myEvents[s].program_slug,
                    title: myEvents[s].program_name
                })

            }
        }
        setevents(event_list);
    }

    /***
     * Event click
     * Phase 2
     */
    const EventClick = (event) => {
        window.location.href = event.url;
    }

    async function getData(email = '', lname = '') {

        if (email == '') {
            email = user_email;
        }
        if (lname == '') {
            lname = user_lastname;
        }
        //console.log('getData_getData_lname', lname);
        if (email != '' && lname != '') {
            await axios.get(`${props.EngineURL}salesforce-query/checkout/leo_events2_debug.php?contact_id=${email}&lname=${lname}`, { crossdomain: true })
                .then(function (response) {
                    // handle success
                    if (response.data.error !== undefined && response.data.error !== '') {
                        set_error(response.data.error);
                    }
                    else {
                        set_error('');
                        if (response.data.program_registrations.upcoming !== undefined) {
                            setUpcomingEvents(response.data.program_registrations.upcoming);
                            UpdateCalendarEvent(response.data.program_registrations.upcoming);
                        }

                        if (response.data.program_registrations.past !== undefined) {
                            setPastEvent(response.data.program_registrations.past);
                        }
                    }

                    setLoading(false);
                    return response;
                })
                .catch(function (error) {
                    // handle error
                })
                .then(function () {
                    // always executed
                });
        }

    }


    useEffect(() => {
        isMounted = true;
        let orders_data = getData(user_email);

        if (isMounted) {
            //setOrderDetails(orders_data.data !== undefined && orders_data.data.orders !== undefined ? orders_data.data.orders : [] );
        }

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
    }, [pastEvents, upcomingEvents])

    useEffect(() => {
        var userDetail = (localStorage.getItem("userDetail"));

        var userLoginData = JSON.parse(userDetail);
        if (userLoginData !== null && userLoginData !== undefined) {
            var user_email = userLoginData.email;
            var accessToken = userLoginData.accessToken;
            if (user_email !== undefined && user_email !== '') {
                set_user_email(user_email);
            }
            if (accessToken !== undefined && accessToken !== '') {
                let orders_data = getData(user_email);
                set_user_token(userDetail.accessToken);
            }
        }
    }, [])

    const doLogout = () => {
        localStorage.removeItem("userDetail");
        localStorage.setItem('isLoggedin', false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        window.location.href = "/";
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    function formateDateTime(date) {
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var day = date.getDate();
        var hours = ('0' + date.getHours()).slice(-2);
        var minutes = ('0' + date.getMinutes()).slice(-2);
        var seconds = ('0' + date.getSeconds()).slice(-2);
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    function timeDiffCalc(dateFuture, dateNow) {
        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        return days * 24;


    }

    return (
        <div className="admin_page admin_events" style={{ backgroundImage: "url(" + BlueBg + ")" }}>
            <Helmet>
                <title>My Events | Leo Cussen Centre for Law</title>
            </Helmet>
            <Container>
                {
                    props.useLoggedIn !== undefined && props.useLoggedIn == true && error == ''
                        ?
                        <div className="admin_wrapper position-relative">
                            {props.UserData !== null && props.UserData !== undefined ?
                                <div className='admin_details_block mb-5'>
                                    <h1 className="m-0">{props.Good},  {props.UserData.customer.firstName}</h1>
                                </div>
                                : null}
                            <Row>
                                <Col xl={3} lg={4} xs={12} className="d-none d-lg-block">
                                    <AdminSidebar doLogout={doLogout} />
                                </Col>
                                <Col xl={9} lg={8} xs={12}>
                                    <div className='admin_sidebar_mobile d-lg-none'>
                                        <AdminSidebar doLogout={doLogout} />
                                    </div>
                                    <div className="admin_right_content_block">
                                        <>
                                            <div className="information_block card border-exlight">
                                                <div className="information_content">
                                                    <InformationIcon />
                                                    <p className="small mb-0">
                                                        <strong>Disclaimer</strong>
                                                    </p>
                                                    <p className="mt-2 small small-dec mb-0">CPD customers please note that past event history is retained for the current and previous CPD years (1 April – 31 March). For enquiries contact <a href="mailto:registration@leocussen.edu.au">registration@leocussen.edu.au</a>.</p>

                                                </div>
                                            </div>
                                            <div className="admin_events_list_card position-relative">
                                                <div className="admin_head d-flex flex-wrap align-items-center justify-content-between">
                                                    <h4 className="m-0 text-dark">My Upcoming Events</h4>

                                                    <Form className="d-flex search_block" onSubmit={(e) => {
                                                        e.preventDefault();

                                                        getSearchData();
                                                        setLoading(true);
                                                    }}>
                                                        <div className="search_group position-relative">
                                                            <FormControl
                                                                type="search"
                                                                placeholder="Type and enter to search"
                                                                className="search_filed"
                                                                aria-label="Search"
                                                                value={upcomingSearch}
                                                                onChange=
                                                                {
                                                                    (e) => {
                                                                        setUpcomingSearch(e.target.value);

                                                                    }
                                                                }
                                                            />
                                                            <Button variant="search"><SearchIcon /></Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                                <div className='results_sort_order_block d-flex flex-column flex-md-row align-items-sm-center justify-content-between'>
                                                    <div className='results_block d-flex align-items-center'>
                                                        <h5 className='mb-0 me-3'>Results </h5>
                                                        <Form.Check
                                                            inline
                                                            label="List View"
                                                            name="switch_view"
                                                            value="List View"
                                                            defaultChecked={LayoutView === "List View"}
                                                            onChange={(e) => setLayoutView(e.target.value)}
                                                            type="radio"
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Calendar View"
                                                            name="switch_view"
                                                            value="Calendar View"
                                                            defaultChecked={LayoutView === "Calendar View"}
                                                            onChange={(e) => setLayoutView(e.target.value)}
                                                            type="radio"
                                                        />
                                                    </div>

                                                </div>
                                                <Calendar
                                                    localizer={localizer}
                                                    onSelectEvent={(event) => EventClick(event)}
                                                    startAccessor="start"
                                                    endAccessor="end"
                                                    style={{ height: 500 }}
                                                    className={LayoutView === "List View" ? 'd-none' : ''}
                                                    events={events}
                                                    popup
                                                    key="mycalendar"
                                                />
                                                <div className={LayoutView !== "List View" ? 'd-none' : ''}>
                                                    {

                                                        upcomingEvents.length > 0
                                                            ?

                                                            upcomingEvents.map((past_event_item) => {

                                                                let program_name = past_event_item['program_name'];
                                                                let program_start_date_time = formateDateTime(new Date(past_event_item.program_start_date));
                                                                let program_end_date_time = formateDateTime(new Date(past_event_item.program_end_date));
                                                                let hours_diff = timeDiffCalc(new Date(past_event_item.program_start_date), new Date(past_event_item.program_end_date));
                                                                let program_slug = past_event_item['program_slug'] !== null && past_event_item['program_slug'] !== undefined ? past_event_item['program_slug'] : '';
                                                                let order_online_number = past_event_item['Shopify_Order_Number__c'];
                                                                let registration_date = past_event_item['Registration_Date__c'] !== undefined && past_event_item['Registration_Date__c'] !== null ? past_event_item['Registration_Date__c'].split('-').reverse().join('/') : null;
                                                                let live_stream = past_event_item['Live_Stream__c'];
                                                                let venue = past_event_item['program_venue'];
                                                                let attendees = past_event_item['attendees'];
                                                                let program_downloads = past_event_item['program_downloads'];
                                                                let program_downloads_keys = Object.keys(program_downloads);
                                                                let program_downloads_length = Object.keys(program_downloads).length;
                                                                let program_date = past_event_item.program_start_date_format2 !== undefined && past_event_item.program_start_date_format2 !== null ? past_event_item.program_start_date_format2 : '';
                                                                let program_time = past_event_item.program_start_time_format2 !== undefined && past_event_item.program_start_time_format2 !== null ? past_event_item.program_start_time_format2 : null;
                                                                let zoom_url = past_event_item.Join_Zoom_Webinar_URL__c !== undefined && past_event_item.Join_Zoom_Webinar_URL__c !== null && past_event_item.Join_Zoom_Webinar_URL__c != '' ? past_event_item.Join_Zoom_Webinar_URL__c : '';
                                                                program_time = formatAMPM(new Date(past_event_item.program_start_date)).toUpperCase();
                                                                // console.log('upcoming venue',venue);
                                                                // console.log('upcoming event',past_event_item);
                                                                return <Card className="admin_event_card shadow border-light">
                                                                    <Card.Body>
                                                                        <Link to={'/product/' + program_slug}><Card.Title as="h3">{program_name}</Card.Title></Link>
                                                                        <Card.Text as="div">
                                                                            <ListGroup horizontal as="ul" className="justify-content-between">
                                                                                <ListGroup.Item as="li" className="online_order">ONLINE ORDER NO: {order_online_number !== '' && order_online_number !== null && order_online_number !== undefined ? 'R' + order_online_number : ''}</ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="registration_date">REGISTRATION DATE: {registration_date} (AEST)</ListGroup.Item>
                                                                            </ListGroup>
                                                                            <ListGroup horizontal as="ul">
                                                                                {live_stream === true ? <ListGroup.Item as="li" className="live_stream_access small"><LiveStreamIcon />Live Stream Access</ListGroup.Item> : <a target="_blank" href={venue !== 'Live Stream Only' && venue !== null && venue !== undefined && venue !== '' ? 'https://www.google.com/maps/search/?api=1&query=' + venue : ''}><ListGroup.Item as="li" className="stream_location small"><LocationIcon />{venue}</ListGroup.Item></a>}
                                                                                {attendees.length > 0 ? <ListGroup.Item as="li" className="attendee small">Attendees: {attendees.join(',')}</ListGroup.Item> : null}
                                                                            </ListGroup>
                                                                            {
                                                                                program_downloads_length > 0
                                                                                    ?

                                                                                    <div className="downloadable_material_block">
                                                                                        <p>Downloadable Materials</p>
                                                                                        <ListGroup as="ul">
                                                                                            {
                                                                                                program_downloads_keys.map((download_key) => {
                                                                                                    let download_detail = program_downloads[download_key];
                                                                                                    // if(download_detail.NEILON__Category__c == 'Program Material')
                                                                                                    // {
                                                                                                    let download_file_name = download_detail.Name;
                                                                                                    let download_file_link = download_detail.NEILON__File_Presigned_URL__c;
                                                                                                    return <ListGroup.Item as="li" className=""><a className="download_link_item" target="_blank" href={download_file_link}><DocumentIcon />{download_file_name}</a></ListGroup.Item>
                                                                                                    // }
                                                                                                })
                                                                                            }

                                                                                        </ListGroup>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                            <div className="event_card_right_block">
                                                                                <div className="event_time_date_block">
                                                                                    <span className="date_holder">{program_date}</span>
                                                                                    <span className="time_holder">{program_time}</span>
                                                                                    <small>(Australia/Melbourne)</small>
                                                                                </div>
                                                                                {live_stream === true && zoom_url !== '' ? <a href={zoom_url} className="btn btn-primary rounded-pill w-100">Join</a> : null}
                                                                                {past_event_item.program_end_date !== undefined && past_event_item.program_end_date !== null && past_event_item.program_start_date !== undefined && past_event_item.program_start_date !== null ?
                                                                                    <a onClick={() => openCalendarPopUp(program_name, program_start_date_time, hours_diff)} className="btn btn-outline-primary  rounded-pill w-100">Add to Calendar</a>
                                                                                    : null
                                                                                }
                                                                            </div>
                                                                        </Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            })
                                                            : loading === true ? <div className="pre_loader"><LoaderIcon /></div> : <div className='not_found bg-white text-primary rounded text-center p-3 border'><h6 className='h3 mb-0'>No events found</h6></div>
                                                    }
                                                </div>
                                            </div>
                                        </>


                                        <>
                                            <div className="admin_events_list_card posotion-relative">
                                                <div className="admin_head d-flex flex-wrap align-items-center justify-content-between">
                                                    <h4 className="m-0 text-dark">My Past Events</h4>

                                                    <Form className="d-flex search_block" onSubmit={(e) => {
                                                        e.preventDefault();

                                                        getPastSearchData();
                                                        setLoading(true);
                                                    }}>
                                                        <div className="search_group position-relative">
                                                            <FormControl
                                                                type="search"
                                                                placeholder="Type and enter to search"
                                                                className="search_filed"
                                                                aria-label="Search"
                                                                value={pastSearch}
                                                                onChange={(e) => {
                                                                    setPastSearch(e.target.value);
                                                                    if (e.target.value == '') {
                                                                        setPastSearch('');
                                                                        // setLoading(true);
                                                                        // getPastSearchData();
                                                                    }
                                                                }
                                                                }
                                                            />
                                                            <Button variant="search"><SearchIcon /></Button>
                                                        </div>
                                                    </Form>
                                                </div>

                                                {
                                                    pastEvents.length > 0
                                                        ?
                                                        pastEvents.map((past_event_item) => {
                                                            // console.log('past_event_item',past_event_item);
                                                            let program_start_date_time = formateDateTime(new Date(past_event_item.program_start_date));
                                                            let program_end_date_time = formateDateTime(new Date(past_event_item.program_end_date));
                                                            let hours_diff = timeDiffCalc(new Date(past_event_item.program_start_date), new Date(past_event_item.program_end_date));
                                                            // console.log('program_start_date_time',program_start_date_time);
                                                            // console.log('program_end_date_time',program_end_date_time);
                                                            let program_name = past_event_item['program_name'];
                                                            let program_slug = past_event_item['program_slug'] !== null && past_event_item['program_slug'] !== undefined ? past_event_item['program_slug'] : '';
                                                            let order_online_number = past_event_item['Shopify_Order_Number__c'];
                                                            let registration_date = past_event_item['Registration_Date__c'] !== undefined && past_event_item['Registration_Date__c'] !== null ? past_event_item['Registration_Date__c'].split('-').reverse().join('/') : null;
                                                            let live_stream = past_event_item['Live_Stream__c'];
                                                            let venue = past_event_item['program_venue'];
                                                            let attendees = past_event_item['attendees'];
                                                            let program_downloads = past_event_item['program_downloads'];
                                                            let program_downloads_keys = Object.keys(program_downloads);
                                                            let program_downloads_length = Object.keys(program_downloads).length;
                                                            let program_date = past_event_item.program_start_date_format2 !== undefined && past_event_item.program_start_date_format2 !== null ? past_event_item.program_start_date_format2 : '';
                                                            let program_time = past_event_item.program_start_time_format2 !== undefined && past_event_item.program_start_time_format2 !== null ? past_event_item.program_start_time_format2 : null;
                                                            let zoom_url = past_event_item.Join_Zoom_Webinar_URL__c !== undefined && past_event_item.Join_Zoom_Webinar_URL__c !== null && past_event_item.Join_Zoom_Webinar_URL__c != '' ? past_event_item.Join_Zoom_Webinar_URL__c : '';
                                                            program_time = formatAMPM(new Date(past_event_item.program_start_date)).toUpperCase();
                                                            return <Card className="admin_event_card shadow border-light">
                                                                <Card.Body>
                                                                    <Link to={'/product/' + program_slug}><Card.Title as="h3">{program_name}</Card.Title></Link>
                                                                    <Card.Text as="div">
                                                                        <ListGroup horizontal as="ul" className="justify-content-between">
                                                                            <ListGroup.Item as="li" className="online_order">ONLINE ORDER NO: {order_online_number !== '' && order_online_number !== null && order_online_number !== undefined ? order_online_number : ''}</ListGroup.Item>
                                                                            <ListGroup.Item as="li" className="registration_date">REGISTRATION DATE: {registration_date} (AEST)</ListGroup.Item>
                                                                        </ListGroup>
                                                                        <ListGroup horizontal as="ul">
                                                                            {live_stream === true ? <ListGroup.Item as="li" className="live_stream_access small"><LiveStreamIcon />Live Stream Access</ListGroup.Item> : <a target="_blank" href={venue !== 'Live Stream Only ' && venue !== null && venue !== undefined && venue !== '' ? 'https://www.google.com/maps/search/?api=1&query=' + venue : ''}><ListGroup.Item as="li" className="stream_location small"><LocationIcon />{venue}</ListGroup.Item></a>}
                                                                            {attendees.length > 0 ? <ListGroup.Item as="li" className="attendee small">Attendees: {attendees.join(',')}</ListGroup.Item> : null}
                                                                        </ListGroup>
                                                                        {
                                                                            program_downloads_length > 0
                                                                                ?
                                                                                <div className="downloadable_material_block">
                                                                                    <p>Downloadable Materials</p>
                                                                                    <ListGroup as="ul">
                                                                                        {
                                                                                            program_downloads_keys.map((download_key) => {
                                                                                                let download_detail = program_downloads[download_key];
                                                                                                // if(download_detail.NEILON__Category__c == 'Program Material')
                                                                                                // {
                                                                                                let download_file_name = download_detail.Name;
                                                                                                let download_file_link = download_detail.NEILON__File_Presigned_URL__c;
                                                                                                return <ListGroup.Item as="li" className=""><a className="download_link_item" target="_blank" href={download_file_link}><DocumentIcon />{download_file_name}</a></ListGroup.Item>
                                                                                                // }
                                                                                            })
                                                                                        }

                                                                                    </ListGroup>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        <div className="event_card_right_block">
                                                                            <div className="event_time_date_block">
                                                                                <span className="date_holder">{program_date}</span>
                                                                                <span className="time_holder">{program_time}</span>
                                                                                <small>(Australia/Melbourne)</small>
                                                                            </div>
                                                                            {live_stream === true && zoom_url != '' ? <a href={zoom_url} target="_blank" className="btn btn-primary rounded-pill w-100">Join</a> : null}
                                                                            {
                                                                                past_event_item.program_end_date !== undefined && past_event_item.program_end_date !== null && past_event_item.program_start_date !== undefined && past_event_item.program_start_date !== null
                                                                                    ?
                                                                                    <a onClick={() => openCalendarPopUp(program_name, program_start_date_time, hours_diff)} className="btn btn-outline-primary  rounded-pill w-100">Add to Calendar</a>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        })
                                                        : loading === true ? null : <div className='not_found bg-white text-primary rounded text-center p-3 border'><h6 className='h3 mb-0'>No events found</h6></div>
                                                }
                                            </div>

                                        </>

                                    </div>
                                </Col>
                            </Row>
                        </div >
                        :
                        <div className="text-center">
                            {
                                error != ''
                                    ?
                                    <h3 className="text-center">
                                        {
                                            error == 'user not found.' ?
                                                <p>There are no data found with your account. <a href='mailto:registration@leocussen.edu.au' className="btn-link">Get in touch</a></p>
                                                : 'Please login to access My profile section.'
                                        }
                                    </h3>
                                    : null
                            }
                            {
                                error == ''
                                    ?
                                    <>
                                        <h3>Please Login</h3>
                                        <a onClick={() => My_Account_button_click()} className="btn btn-primary rounded-pill m-0 stest"> Login</a>
                                    </>
                                    : null
                            }
                        </div>
                }
            </Container >
            <>
                <Modal show={calendarPopup} onHide={() => setCalendarPopup(false)} className="share_modal">
                    <Modal.Header closeButton className="border-0">
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Title className="text-center">Add To Your Calendar</Modal.Title>
                        <ListGroup horizontal as="ul" className="social_media_list justify-content-center">
                            <ListGroup.Item as="li" className="social_item">
                                <a target="_blank" href={googleLink}><GoogleIcon fill="#004e6d" /></a>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="social_item">
                                <a target="_blank" href={yahooLink}><YahooIcon fill="#004e6d" /></a>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="social_item">
                                <a target="_blank" href={outlookLink}><OutlookIcon fill="#004e6d" /></a>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="social_item">
                                <a target="_blank" href={icalLink}><ICalendarIcon fill="#004e6d" /></a>
                            </ListGroup.Item>
                        </ListGroup>
                    </Modal.Body>
                </Modal>
            </>
        </div >
    )
}
