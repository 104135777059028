import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#707070",
  width = "12.913px",
  height = "18.135px",
  className = "",
  viewBox = "0 0 12.913 18.135"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-388 -1341)">
    <path fill={fill} stroke={stroke} d="M4415,6644.853v12.211h11.913V6640h-8.091Z" transform="translate(-4026.5 -5298.428)" strokeWidth="1"/>
    <path fill={fill} stroke={stroke} d="M4421.092,6640v5.961H4416" transform="translate(-4027.5 -5299)" strokeWidth="1"/>
    <line fill={fill} stroke={stroke} x2="6.954" transform="translate(391.5 1351.906)" strokeWidth="1"/>
    <line fill={fill} stroke={stroke} x2="6.954" transform="translate(391.5 1355.38)" strokeWidth="1"/>
  </g>
  </svg>
);

export default SVG;