import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#004e6d",
  width = "12.5",
  height = "12.5",
  className = "",
  viewBox = "0 0 12.5 12.5"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(0.5 0.5)">
    <path d="M.5,0V11.5" transform="translate(5.25)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M11.5.5H0" transform="translate(0 5.25)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  </g>
  </svg>
);

export default SVG;