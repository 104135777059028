import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#004e6d",
  width = "10.367px",
  height = "12.2px",
  className = "",
  viewBox = "0 0 10.367 12.2"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-280.067 -567.4)">
    <path fill={fill} stroke={stroke} d="M10.667,26.167V24.944A2.444,2.444,0,0,0,8.222,22.5H3.944A2.444,2.444,0,0,0,1.5,24.944v1.222" transform="translate(279.167 552.833)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
    <path fill={fill} stroke={stroke} d="M11.639,6.944A2.444,2.444,0,1,1,9.194,4.5,2.444,2.444,0,0,1,11.639,6.944Z" transform="translate(276.056 563.5)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
  </g>
  </svg>
);

export default SVG;
