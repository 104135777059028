import React, { useState, useEffect } from 'react'
import {
    // Card,
    Button,
    Modal,
    Alert,
    Form
} from 'react-bootstrap';
import { useLocation } from 'react-router';
import { isValidPhoneNumber } from 'react-phone-number-input';
import axios from 'axios';



export const Login = (props) => {

    const location = useLocation();
    //console.log("Login ->");
    //console.log(location);


    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserpassword] = useState("");
    const [rememberMe, setrememberMe] = useState(false);

    /***
     * Error Handling
     */
    const [userEmailerrror, setUserEmailerror] = useState(false);
    const [userPasswordError, setUserpasswordError] = useState(false);

    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);


    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase()) || isValidPhoneNumber(email);
    }
    const validateEmailOnly = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const isCustomNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    }

    const submit = (e) => {
        e.preventDefault();
        setUserEmailerror(false);
        setUserpasswordError(false);

        setErrorMessage(undefined);
        props.setLoginErrorMessage(undefined);
        setSomethingUpadating(false);
        setSuccessMessage(undefined);

        var emailValid = true;
        var emailPassword = true;

        if (!userEmail && !userPassword) {
            setUserEmailerror(true);
            setUserpasswordError(true);
            emailValid = false;
            emailPassword = false;
        }

        if (!validateEmail(userEmail)) {
            setUserEmailerror(true);
            emailValid = false;
        }

        if (!userPassword) {
            setUserpasswordError(true);
            emailPassword = false;
        }

        if (emailValid && emailPassword) {
            setSomethingUpadating(true)
            if (isValidPhoneNumber(userEmail)) {
                //do login with mobile number
                axios.post(props.EngineURL + 'create-account/get_email.php', { mobile: userEmail.replace(/\s/g, "") })
                    .then(function (response) {
                        if (response.status === 200 && response.data.status === 200) {
                          //  console.log(response.data.email);
                            props.DoLoginAuth(response.data.email, userPassword, rememberMe, "Login", location.pathname);
                        }
                        else {
                            setErrorMessage(response.data.message);
                            setSomethingUpadating(false);
                        }
                    })
                    .catch(function (error) {
                      //  console.log(error);
                    });


            }
            else {
                props.DoLoginAuth(userEmail, userPassword, rememberMe, "Login", location.pathname);
            }

        }
        else if (!emailValid && !emailPassword) {
            setErrorMessage("Fields are required!");
        }
        else if (isCustomNumber(userEmail.replace("+", "")) && !isValidPhoneNumber(userEmail)) {
            setErrorMessage("Invalid Mobile Number");
        }
        else if (!validateEmailOnly(userEmail)) {
            setErrorMessage("Your email format is not correct!");
        }
        else {
            setErrorMessage("Field validation error!");
        }

    }

    useEffect(() => {
        if (props.LoginErrorMsgm != undefined) {
            setErrorMessage(props.LoginErrorMsgm);
            setSomethingUpadating(false);
        }
    }, [props.LoginErrorMsgm]);


    const ForgotPassword = () => {
        props.forgot_password_popup_show();
        props.HideLoginPopup();
    }

    const ForgotUsername = () => {
        // console.log('props',props);
        props.forgot_username_popup_show();
        props.HideLoginPopup();
    }


    return (
        <>
            <Modal
                className="login_modal"
                show={props.LoginPopup}
                onHide={props.HideLoginPopup}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body>
                    <Form className="" onSubmit={submit}>
                        <Modal.Title className="text-center">Returning Customers</Modal.Title>
                        <Form.Group className="mb-4" controlId="LoginEmail">
                            <Form.Control className={userEmailerrror ? 'form-error' : ''} type="text" onChange={(e) => setUserEmail(e.target.value)} value={userEmail} placeholder="Enter email or Mobile number" />
                            <small className='form-check-label-small'>Mobile number format e.g +61 000000000</small>
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="LoginPassword">
                            <Form.Control className={userPasswordError ? 'form-error' : ''} type="password" onChange={(e) => setUserpassword(e.target.value)} value={userPassword} placeholder="Password" />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="LoginCheckbox">
                            <Form.Check checked={rememberMe == true} type="checkbox" label="Remember me" onChange={(e) => {
                                const checked = rememberMe == true;
                                setrememberMe(() =>
                                    checked
                                        ? false
                                        : true

                                );
                            }} />
                        </Form.Group>
                        <ul className="d-flex list-inline justify-content-around ">
                            <li><a onClick={(e) => ForgotPassword()} className="btn-link">Forgot Password?</a></li>
                            <li><a onClick={(e) => ForgotUsername()} className="btn-link">Forgot Username?</a></li>
                        </ul>
                        <div className="responseMessage">
                            {ErrorMessage !== undefined ?
                                <Alert variant="danger">
                                    {ErrorMessage}
                                </Alert>
                                : ''}

                            {SuccessMessage !== undefined ?
                                <Alert variant="success">
                                    {SuccessMessage}
                                </Alert>
                                : ''}
                            {SomethingUpadating === true ?
                                <Alert variant="primary">
                                    Validating...
                                </Alert>
                                : ''}
                        </div>
                        <Button variant="primary" type="submit" className="w-100 rounded-2 btn-lg border-2">Login</Button>
                        <Button variant="primary" type="button" onClick={() => props.OpenRegisterPopupFromLogin()} className="w-100 rounded-2 btn-lg border-2">Create a New Account</Button>
                    </Form>

                </Modal.Body>
            </Modal>
        </>
    )
}
