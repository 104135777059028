import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Modal,
} from 'react-bootstrap';
export const TermsCondition = (props) => {
    return (
        <>
            <Modal className="term_condition_modal" size="lg" show={props.show} onHide={() => props.hide()} scrollable>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title as="h2" className="mb-0">CPD Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <div className="about_page_wrapper">
                        <div className="conditions">
                            <h1>Terms and Conditions</h1>
                            <h2>Leo Cussen Digital Products including Papers, Audio and Video</h2>
                            <p>All CPD products including webcast are for personal use and are not for viewing or use by the general public. They are sold for use by the purchaser only and are not to be distributed or reproduced in any format without prior written consent by Leo Cussen Centre for Law. There are no refunds or credit notes for CPD Products.</p>
                            <p>All Products remain the Copyright of Leo Cussen Institute unless stated otherwise. All Rights Reserved. Products are reproduced under licence where appropriate. Apart from any fair dealing for the purpose of private study, research or as permitted under the Copyright Act, no part may be reproduced, distributed or copied in any form or by any means without prior permission.</p>
                            <p>Leo Cussen Institute may not have edited Products before publication. Products cannot be construed as legal advice. No warranty of accuracy or reliability as to information included in Products is given, and no responsibility for loss arising in any way from or in connection with errors or omissions in any information provided (including responsibility to any person by reason of negligence) is accepted by the Institute or by any of its agents or employees or by any person who has authored any product or presented any content.</p>
                            <h2>CPD Programs</h2>
                            <h3>Payment</h3>
                            <p>For online bookings, payment must be made by credit card (Visa, Mastercard or AMEX). For all other bookings, payment may be made by credit card (Visa, Mastercard or AMEX) or bank deposit.</p>
                            <p>Bookings will not be processed until payment is received and cleared.</p>
                            <p>Entry to a program will not be permitted in circumstances where payment has not been received and cleared prior to the program commencing.</p>
                            <p>By registering into any program you agree to receive marketing material/s from Leo Cussen Centre for Law.</p>
                            <h3>Discounts</h3>
                            <p>A 10% discount may be offered on full-priced programs to employees of Victoria Legal Aid and community legal centres.</p>
                            <p>No discount is available on early-bird pricing, or for programs conducted in association with other organisations and <a href="https://www.leocussen.edu.au/supervised-legal-trainees/about-our-slt/">Traineeship (SLT) Workshops</a>.</p>
                            <h3>Cancellation</h3>
                            <h5>Programs other than Traineeship (SLT) Workshops and PMC</h5>
                            <p>Registrations are transferable to another person, subject to the timing of the request.</p>
                            <p>Refunds, credit notes or transfers between programs are available if requested in writing at least 14 days prior to the program. A fee of $70 per seminar, $250 per workshop, $250 per full day conference and $150 per Intensive or half day conference will be charged if a refund, credit note or transfer between programs is granted. No discounts, refunds, transfers or credit notes are available for programs conducted in association with other organisations.</p>
                            <p>Refunds, credit notes and transfers between programs are not available if requested within 14 days of a program.</p>
                            <h5>Traineeship (SLT) Workshops</h5>
                            <p>Refunds or credit notes are not available for <a target="_blank" href="https://www.leocussen.edu.au/supervised-legal-trainees/about-our-slt/">Traineeship (SLT) Workshops</a>.</p>
                            <p>Registrations for Traineeship (SLT) Workshops are transferable to another person at the discretion of Leo Cussen Centre for Law.</p>
                            <p>Transfers between Traineeship (SLT) Workshops are not available. Transfers to the same Traineeship (SLT) Workshop commencing on an alternative date will be granted at the discretion of Leo Cussen Centre for Law and may be requested up to 30 days prior to the program. An administration fee of $250 per Traineeship (SLT) Workshop will be charged if a transfer is granted.</p>
                            <h5>CPD Special Offer/s</h5>
                            <p>No Discounts, credit notes, refunds or transfers are available for this Offer. Special offers and discounts cannot be used in conjunction any other offer.</p>
                            <h5>Live Stream</h5>
                            <p>Registrants who fail to access live stream programs are not entitled to a refund, credit note or transfer.</p>
                            <p>It is the sole responsibility of the registrant to access live stream programs through their unique links at the time of the program. Leo Cussen Centre for Law will not be held responsible for any failure by the registrant who cannot access the program.</p>
                            <p>The live stream is for personal viewing by the registrant only and not for viewing or use by the general public. Registration into a program does not allow a registrant to share a program link.</p>
                            <p>Registration into a program does not include a free program recording. Recordings are subject to program quality and presenter licence. Programs converted to webcast recording can be purchased through our online store.</p>
                            <h3>Program changes</h3>
                            <p>Leo Cussen Centre for Law reserves the right to cancel or reschedule programs, change speakers and revise program content.</p>
                            <h3>Privacy</h3>
                            <p>By booking into a program you acknowledge and accept that Leo Cussen Centre for Law may audio and /or video record the program and that audio/video may be used by Leo Cussen Centre for Law for training purposes, to review content, and in promoting its programs or for sale.</p>
                            <p>By booking into a program, you consent to Leo Cussen Centre for Law providing your name and organisation details to the presenter(s) of the relevant program(s). For full details of the Leo Cussen Centre for Law privacy policy see <a href="http://www.leocussen.edu.au/">www.leocussen.edu.au</a>.</p>
                            <h2>Practice Management Course Cancellation/Transfers/Credit Note</h2>
                            <p>Registrants who fail to attend or complete the Course are not entitled to a refund, transfer or credit note.</p>
                            <p>Registrations are transferable to another person at the discretion of Leo Cussen Centre for Law.</p>
                            <p>Transfers between Course Intakes are available if requested in writing at least 21 days prior to the face to face (onsite or livestream) Workshop and subject to availability. An administration fee of $450 will be charged if a transfer is granted.</p>
                            <p>Refunds are available if requested in writing at least 21 days prior to the face to face (onsite or livestream) Workshop. An administration fee of $700 will be charged if a refund is granted.</p>
                            <p>A discount is available on the full price fee for CLC employees.</p>
                            <p>Any person booking into the PMC agrees to comply with the Terms and Conditions that apply to the PMC including that the person confirms that all work in the Modules and during the Course is solely their own work. And they agree not to share the details of the Course content including the materials and online activities, with anyone else.</p>
                            <h2>Practice Management Course Individual Modules</h2>
                            <p>Any person booking into the individual PMC Modules agrees to comply with the Terms and Conditions that apply to the PMC including that the person confirms that all work in the Modules and during the Course is solely their own work. And they agree not to share the details of the Course content including the materials and online activities, with anyone else.</p>
                            <h2>Onsite Programs and Onsite Attendance during COVID-19</h2>
                            <p>Clients should not attend an onsite program if they are unwell or in quarantine. If a program includes a live stream option, they may request a transfer into the live stream option providing the request is made no later than 8am on the day of the program and subject to availability.</p>
                            <p>If a program does not include a live stream option, no refund, transfer or credit note will be permitted should an attendee fail to attend the program. Leo Cussen Centre for Law may, in its sole discretion, allow a partial refund where a written request is made within 24 hours of the program, accompanied by either a medical certificate, statutory declaration or their personal professional undertaking indicating that the failure to attend was COVID-19 related.</p>
                            <p>All staff and visitors at Leo Cussen are required to be fully vaccinated before entry.</p>
                            <h2>Onsite Programs converted to Online Delivery</h2>
                            <p>Leo Cussen Centre for Law reserves the right to convert the delivery of an advertised onsite program, to online delivery, at any time. Leo Cussen Centre for Law will endeavour to give as much advance notice of the conversion as possible. No refund, credit note or transfer is available. Leo Cussen Centre for Law is not responsible for any loss or damage including travel or accommodation costs caused by converting any onsite program to online delivery.</p>
                            <h2>CPD Category Classifications</h2>
                            <p>CPD category classifications for Leo Cussen Centre for Law programs and products are provided as a guide only and are based on information available at the date of publication.</p>
                            <p>Allocation of CPD points are subject to the CPD requirements of the purchaser’s jurisdiction, personal circumstances and practice requirements.</p>
                            <h2>Compliance of CPD programs/products with the Western Australian CPD rules</h2>
                            <p>All our “live” programs onsite or livestream (seminars, intensives, conferences, workshops, PMC) are interactive CPD activities for WA practitioners. Our CPD Online Modules are also interactive electronic CPD activities for WA practitioners.</p>
                            <p>We record your attendance to those activities either onsite, or by electronic log in to a program or Module.</p>
                            <p>Our Webcasts (pre-recorded programs able to purchase and download on demand from our Online Store) are not interactive or electronic activities as defined by WA CPD e-learning and WA practitioners cannot claim a CPD point from our Webcasts.</p>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}