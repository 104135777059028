import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    // Card,
    Button,
    Modal,
    Alert,
    Form,
    InputGroup,
    Col
} from 'react-bootstrap';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

export const CanWeHelp = (props) => {
    const [userEmail, setUserEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [newMobile, setnewMobile] = useState("");
    const [Inquiry, setInquiry] = useState("");
    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);
    //console.log(privacyPolicy);
    const [userEmail_ER, setUserEmail_ER] = useState(false);
    const [firstName_ER, setFirstName_ER] = useState(false);
    const [Mobile_ER, setMobile_ER] = useState(false);
    var setUserEmaDil_ER_var = true;
    var setFirstName_ER_var = true;
    var setMobile_ER_var = true;
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const handleSubmit = (event) => {

        setSuccessMessage(undefined);
        setErrorMessage(undefined);

        setUserEmail_ER(false);
        setFirstName_ER(false);
        setMobile_ER(false);

        setUserEmaDil_ER_var = true;
        setFirstName_ER_var = true;
        setMobile_ER_var = true;

        event.preventDefault();

        if (!firstName) {
            setFirstName_ER(true);
            setFirstName_ER_var = false;
        }



        if (!userEmail) {
            setUserEmail_ER(true);
            setUserEmaDil_ER_var = false;
        }
        if (!validateEmail(userEmail)) {
            setUserEmail_ER(true);
            setUserEmaDil_ER_var = false;
        }
        if (!newMobile) {
            setMobile_ER(true);
            setMobile_ER_var = false;
        }

        if (!isValidPhoneNumber(newMobile)) {
            // console.log('isValidPhoneNumber');
            setMobile_ER(true);
            setMobile_ER_var = false;
        }



        if (setUserEmaDil_ER_var && setFirstName_ER_var && setMobile_ER_var) {
            setSomethingUpadating(true);
            var inquirData = {
                name: firstName,
                email: userEmail,
                phone: newMobile,
                message: Inquiry
            };

            axios.post(props.EngineURL + 'canwehelp/index.php',inquirData)
                .then(function (response) {

                    setTimeout(function () {
                        if (response != null) {
                            if (response.data.code == 200) {
                                setSuccessMessage(response.data.message);

                            }
                            else {
                                setErrorMessage(response.data.message);
                            }
                        }
                        else {
                            setErrorMessage("Something went wrong!");

                        }
                        setSomethingUpadating(false);
                    }, 500);

                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }


    return (
        <>
            <Modal className="signup_model" show={props.CanWeHelpStatus} onHide={() => props.setCanWeHelpStatus(false)}>
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body className="px-4">
                    {SuccessMessage !== undefined ?
                        <h5 className="text-center">We received your enquiry. We’ll get back to you as soon as practical.</h5> :
                        <Modal.Title className="text-center"> How can we help you?</Modal.Title>}
                    <div className="about_page_wrapper">
                        {SuccessMessage == undefined ?
                            <div className="wFormContainer" >
                                <Form className="" onSubmit={handleSubmit}>
                                    <Form.Group as={Col} md="12" xs="12" className="form-group">
                                        <Form.Label htmlFor="canwehelpname">Your name*</Form.Label>
                                        <Form.Control
                                            className={firstName_ER ? 'form-error' : ''}
                                            type="text"
                                            id="canwehelpname"
                                            placeholder="Your full name"
                                            defaultValue="John"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" className="form-group">
                                        <Form.Label htmlFor="canwehelpemail">Your Email*</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="email"
                                                id="canwehelpemail"
                                                className={userEmail_ER ? 'form-error' : ''}
                                                placeholder="Your email address"
                                                aria-describedby="inputGroupPrepend"
                                                value={userEmail}
                                                onChange={(e) => setUserEmail(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Wrong Email Address.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" className="form-group">
                                        <Form.Label htmlFor="canwehelpmobile">Your Mobile*</Form.Label>
                                        <PhoneInput
                                            international
                                            className={Mobile_ER ? 'form-error' : ''}
                                            defaultCountry="AU"
                                            placeholder="Your mobile number"
                                            value={newMobile}
                                            onChange={setnewMobile}
                                        />

                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" className="form-group">
                                        <Form.Label htmlFor="canwehelpinquiry">Your Inquiry</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="text"
                                                id="canwehelpinquiry"
                                                placeholder="Your inquiry"
                                                value={Inquiry}
                                                as="textarea"
                                                rows={3}
                                                onChange={(e) => setInquiry(e.target.value)}
                                            />

                                        </InputGroup>
                                    </Form.Group>

                                    {ErrorMessage !== undefined ?
                                        <Alert variant="danger">
                                            {ErrorMessage}
                                        </Alert>
                                        : ''}
                                    {SuccessMessage !== undefined ?
                                        <Alert variant="success">
                                            {SuccessMessage}
                                        </Alert>
                                        : ''}
                                    {SomethingUpadating === true ?
                                        <Alert variant="primary">
                                            Sending....
                                        </Alert>
                                        : ''}
                                    {SuccessMessage == undefined
                                        ? <Form.Group as={Col} xs="12" className="form-group text-center">
                                            <Button variant="primary" type="submit" className="btn-lg border-2 rounded-2">Submit</Button>
                                        </Form.Group> : ''}
                                </Form>
                            </div> : null}
                        {ErrorMessage !== undefined ?
                            <Alert variant="danger">
                                {ErrorMessage}
                            </Alert>
                            : ''}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}