import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#e6ab11",
  width = "30px",
  height = "30px",
  className = "",
  viewBox = "0 0 30 30"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-605 -495)">
    <g transform="translate(-4003.406 -5854.755)">
      <path fill={fill} stroke={stroke} d="M4654.75,6359.75v8.381" transform="translate(-31.7)" strokeLinecap="round" strokeWidth="1.5"/>
      <path fill={fill} stroke={stroke} d="M4633.75,6397.5l3.129,2.831,3.2-2.831" transform="translate(-13.829 -32.126)" strokeLinecap="round" strokeWidth="1.5"/>
      <path fill={fill} stroke={stroke} d="M4617.5,6424.75v1.75h11.063v-1.75" transform="translate(0 -56.21)" strokeLinecap="round" strokeWidth="1.5"/>
    </g>
    <g fill={fill} stroke={stroke} transform="translate(605 495)" strokeWidth="1.5">
      <circle cx="15" cy="15" r="15" stroke="none"/>
      <circle cx="15" cy="15" r="14.25" fill="none"/>
    </g>
  </g>
  </svg>
);

export default SVG;
