import React from "react";

const SVG = ({
  style = {},
  //fill = "#707070",
  //stroke="none",
  width = "35",
  height = "35",
  className = "",
  viewBox = "0 0 35 35"
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(1 1)"><circle cx="10.321" cy="10.321" r="10.321" transform="translate(0 0)" fill="none" stroke="#004e6d" strokeMiterlimit="10" strokeWidth="2"/><path d="M0,0,11.354,11.354" transform="translate(17.546 17.546)" fill="none" stroke="#004e6d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/><path d="M6.8,6.8A6.8,6.8,0,0,0,0,0" transform="translate(10.321 3.521)" fill="none" stroke="#004e6d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/></g></svg>
);

export default SVG;