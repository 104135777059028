import React from "react";

const SVG = ({
  style = {},
  fill = "#004e6d",
  stroke="none",
  width = "17.777px",
  height = "17.776px",
  className = "",
  viewBox = "0 0 17.777 17.776"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path fill={fill} stroke={stroke}
  d="M3.979,17.777H.294V5.909H3.979ZM2.134,4.29A2.145,2.145,0,1,1,4.269,2.135,2.152,2.152,0,0,1,2.134,4.29ZM17.773,17.777H14.1V12c0-1.377-.028-3.143-1.916-3.143-1.916,0-2.21,1.5-2.21,3.043v5.877H6.288V5.909H9.822V7.528h.052a3.873,3.873,0,0,1,3.487-1.917c3.73,0,4.416,2.456,4.416,5.646v6.519Z" transform="translate(0 -0.001)" />
  </svg>
);

export default SVG;