import React, { useState, useEffect } from 'react';
import Select from 'react-select'

import {
    // Card,
    Button,
    Modal,
    Alert,
    Form,
    InputGroup,
    Col
} from 'react-bootstrap';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';


export const ManuallyCPD = (props) => {
    const [TrainingProvider, setTrainingProvider] = useState("");
    const [ProgramName, setProgramName] = useState("");
    const [ProgrameFormat, setProgrameFormat] = useState("");
    const [ProgrameDate, setProgrameDate] = useState("");

    const [PS, setPS] = useState(0);
    const [PM, setPM] = useState(0);
    const [SL, setSL] = useState(0);
    const [EP, setEP] = useState(0);

    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);
    //console.log(privacyPolicy);
    const [TrainingProvider_ER, setTrainingProvider_ER] = useState(false);
    const [ProgramName_ER, setProgramName_ER] = useState(false);
    const [ProgrameFormat_ER, setProgrameFormat_ER] = useState(false);
    const [ProgrameDate_ER, setProgrameDate_ER] = useState(false);


    const [PS_ER, setPS_ER] = useState(null);
    const [PM_ER, setPM_ER] = useState(null);
    const [SL_ER, setSL_ER] = useState(null);
    const [EP_ER, setEP_ER] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSuccessMessage(undefined);
        setErrorMessage(undefined);

        setTrainingProvider_ER(false);
        setProgramName_ER(false);
        setProgrameFormat_ER(false);
        setProgrameDate_ER(false);
        setPS_ER(false);
        setPM_ER(false);
        setSL_ER(false);
        setEP_ER(false);


        if (TrainingProvider === null || TrainingProvider === "") {
            setTrainingProvider_ER(true);
        }
        if (ProgramName === null || ProgramName === "") {
            setProgramName_ER(true);
        }
        if (ProgrameFormat === null || ProgrameFormat === "" || ProgrameFormat === undefined) {
            setProgrameFormat_ER(true);
        }
        if (ProgrameDate === null || ProgrameDate === "" || ProgrameDate === undefined) {
            setProgrameDate_ER(true);
        }

        if (PS === null || PS === "") {
            setPS_ER(true);
        }
        if (PM === null || PM === "") {
            setPM_ER(true);
        }
        if (SL === null || SL === "") {
            setSL_ER(true);
        }
        if (EP === null || EP === "") {
            setEP_ER(true);
        }

        if (!TrainingProvider_ER && !ProgramName_ER && !ProgrameFormat_ER && !ProgrameDate_ER && !PS_ER && !PM_ER && !SL_ER && !EP_ER) {
            setSomethingUpadating(true);
            var inquirData = {
                email: props.email,
                data: {
                    ep: EP,
                    ps: PS,
                    sl: SL,
                    pm: PM,
                    program_date: ProgrameDate,
                    program_format: ProgrameFormat,
                    training_provider: TrainingProvider,
                    program_name: ProgramName
                }
            };

            axios.post(props.EngineURL + 'cpd_points/insert_cpd_points.php', inquirData)
                .then(function (response) {

                    setTimeout(function () {
                        if (response != null) {
                            if (response.data.status == 200) {
                                setSuccessMessage(response.data.message);

                            }
                            else {
                                setErrorMessage(response.data.message);
                            }
                        }
                        else {
                            setErrorMessage("Something went wrong!");

                        }
                        setSomethingUpadating(false);
                    }, 500);

                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }

    const ProgrameOption = [
        { label: "Live Event", value: "Live Event" },
        { label: "Recording", value: "Recording" },
        { label: "Self-paced Learning", value: "Self-paced Learning" },
        { label: "Other", value: "Other" }];

    const getLabel = (optionList, selected) => {
        var label = "";
        optionList.map((value) => {
            if (value.value === selected) {
                label = value.label;
            }
        });
        // console.log(label);
        return label;

    }


    return (
        <>
            <Modal className="manually_points_modal signup_model" show={props.cdp_point_popup} onHide={() => props.set_cpd_point_popup(false)}>
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body className="px-4">
                    {SuccessMessage !== undefined ?
                        <h5 className="text-center">Your CPD points has been submitted successfully.</h5> :
                        <Modal.Title className="text-center">Enter your CPD point details</Modal.Title>}
                    <div className="about_page_wrapper">
                        {SuccessMessage === undefined ?
                            <div className="wFormContainer" >
                                <Form className="" onSubmit={handleSubmit}>
                                    <Form.Group as={Col} md="12" trainig_providerxs="12" className="form-group">
                                        <Form.Label htmlFor="trainig_provider">Training provider *</Form.Label>
                                        <Form.Control
                                            className={TrainingProvider_ER ? 'form-error' : ''}
                                            type="text"
                                            value={TrainingProvider}
                                            id="trainig_provider"
                                            onChange={(e) => setTrainingProvider(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" xs="12" className="form-group">
                                        <Form.Label htmlFor="program_name">Program name *</Form.Label>
                                        <Form.Control
                                            className={ProgramName_ER ? 'form-error' : ''}
                                            type="text"
                                            id="program_name"
                                            value={ProgramName}
                                            onChange={(e) => setProgramName(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" xs="12" className="form-group">
                                        <Form.Label htmlFor="program_format">Program format *</Form.Label>
                                        <Select
                                            className={ProgrameFormat_ER ? 'form-error' : ''}
                                            aria-label="select"
                                            options={ProgrameOption}
                                            placeholder='Program format'
                                            isClearable={true}
                                            id="program_format"
                                            onChange={val => setProgrameFormat(val.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" xs="12" className="form-group">
                                        <Form.Label htmlFor="program_date">Program date *</Form.Label>
                                        <Form.Control
                                            type="date"
                                            className={ProgrameDate_ER ? 'date_filed form-error' : 'date_filed'}
                                            placeholder="To Date"
                                            aria-label="To Date"
                                            id="program_date"
                                            value={ProgrameDate}
                                            onChange={(e) => setProgrameDate(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" xs="12" className="form-group">
                                        <Form.Label htmlFor="cpd_ep">Ethics & Professional Responsibility points *</Form.Label>
                                        <Form.Control
                                            className={EP_ER ? 'form-error' : ''}
                                            type="number"
                                            placeholder="Ethics & Professional Responsibility points"
                                            value={EP}
                                            id="cpd_ep"
                                            onChange={(e) => setEP(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" xs="12" className="form-group">
                                        <Form.Label htmlFor="cpd_ps">Professional Skills points *</Form.Label>
                                        <Form.Control
                                            className={PS_ER ? 'form-error' : ''}
                                            type="number"
                                            id="cpd_ps"
                                            placeholder="Professional Skills points"
                                            value={PS}
                                            onChange={(e) => setPS(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" xs="12" className="form-group">
                                        <Form.Label htmlFor="cpd_pm">Practice Management & Business Skills points *</Form.Label>
                                        <Form.Control
                                            className={PM_ER ? 'form-error' : ''}
                                            type="number"
                                            id="cpd_pm"
                                            placeholder="Practice Management & Business Skills points"
                                            value={PM}
                                            onChange={(e) => setPM(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" xs="12" className="form-group">
                                        <Form.Label htmlFor="cpd_sl">Substantive Law points *</Form.Label>

                                        <Form.Control
                                            className={SL_ER ? 'form-error' : ''}
                                            type="number"
                                            placeholder="Substantive Law points"
                                            value={SL}
                                            id="cpd_sl"
                                            disabled={SomethingUpadating}
                                            onChange={(e) => setSL(e.target.value)}
                                        />
                                    </Form.Group>

                                    {ErrorMessage !== undefined ?
                                        <Alert variant="danger">
                                            {ErrorMessage}
                                        </Alert>
                                        : ''}
                                    {SuccessMessage !== undefined ?
                                        <Alert variant="success">
                                            {SuccessMessage}
                                        </Alert>
                                        : ''}
                                    {SomethingUpadating === true ?
                                        <Alert variant="primary">
                                            Sending....
                                        </Alert>
                                        : ''}
                                    {SuccessMessage === undefined
                                        ? <Form.Group as={Col} xs="12" className="form-group text-center">
                                            <Button variant="primary" type="submit" className="btn-lg border-2 rounded-2">Submit</Button>
                                        </Form.Group> : ''}
                                </Form>
                            </div> : null}

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}