import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    PlusIcon,
    MinusIcon,
    LoaderIcon
} from "../../icons";
import {
    Button,
    Modal,
    ListGroup,
    Form
} from 'react-bootstrap';

import { useLocation } from 'react-router';
import queryString, { parse } from 'query-string';

import { useHistory } from "react-router-dom"


export const CartSidebar = (props) => {
    const location = useLocation();
    let history = useHistory();
    const queryParam = queryString.parse(location.search);
    const GAurl = queryParam['gclid'] !== undefined ? '?gclid=' + queryParam['gclid'] : '';

    const cart_item = localStorage.getItem("cart_session_sku");
    const CardItem_sku = cart_item !== null
        ? JSON.parse(cart_item)
        : { lineItems: [] };

    const cart_item1 = localStorage.getItem("checkout_id");
    const CartData = cart_item1 !== null
        ? JSON.parse(cart_item1)
        : undefined;

    //console.log("realoe");
    const RedirecttoAttendees = (e) => {
        e.preventDefault();
        props.HideCartSidebarPopup();
        history.push('/attendees' + GAurl);

    }


    const RedirecttoCart = (e) => {
        props.HideCartSidebarPopup();
        history.push('/cart');
    }


    /***
* Get product record type
*/
    function getProgramStructureImage(variantID) {
        let psku = undefined;
        CardItem_sku.lineItems.map(function (linedata) {
            if (linedata.variantId == variantID) {
                psku = linedata.pimage[0];
            }
        })
        return psku;
    }

    /***
* Get product slug
*/
    function getProgram_slug(variantID) {
        let psku = undefined;
        CardItem_sku.lineItems.map(function (linedata) {
            if (linedata.variantId == variantID) {
                psku = linedata.slug;
            }
        })
        return psku;
    }

    const numberWithCommas = (x) => {
        return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
        <>
            <Modal className="cart_modal" size="lg" show={props.CartSidebarPopup} onHide={props.HideCartSidebarPopup} scrollable>
                <Modal.Header closeButton className="px-4 border-0 bg-light">
                    <Modal.Title as="h5" className="text-center">Shopping Cart ({CartData != undefined ? CartData.checkout.lineItems.edges.length : 0}) </Modal.Title>
                </Modal.Header>
                {(CartData != undefined && CartData.checkout.lineItems.edges.length) ?
                    <>
                        <Modal.Body className="px-4">
                            <div className="cart_products">

                                <ListGroup as="ul" className="cart_products_listing list-group position-sticky top-0">
                                    {(props.RemoveItemLoading || props.UpdateItemLoading || props.AddtocartMoreloading || props.Addtocartloading)
                                        ? <div className="pre_loader"><LoaderIcon /></div>
                                        : CartData.checkout.lineItems.edges.map((cartItem, index) => {
                                            return <ListGroup.Item as="li" key={index}>
                                                <div className="d-none d-md-flex justify-content-center flex-column product_cate">
                                                    <img src={getProgramStructureImage(cartItem.node.variant.id)} alt={cartItem.node.title} />
                                                </div>
                                                <Link to={"/product/" + getProgram_slug(cartItem.node.variant.id)} className="link-item"><h6>{cartItem.node.title}</h6></Link>
                                                <p className="cart_price display-6">${numberWithCommas(cartItem.node.variant.price.amount)}</p>
                                                <Form.Group>
                                                    <div className="quantity_group position-relative d-inline-flex align-items-center">
                                                        <Form.Control type="number" name="quantity" placeholder="1" value={cartItem.node.quantity} onChange={(e) => props.UpdateCartItem(e.target.value, cartItem.node.id)} />
                                                        <div className="quantity_changer">
                                                            <span className="up_no" onClick={(e) => props.UpdateCartItem(++cartItem.node.quantity, cartItem.node.id)}><PlusIcon /></span>
                                                            <span className="down_no" onClick={(e) => props.UpdateCartItem(--cartItem.node.quantity, cartItem.node.id)}><MinusIcon /></span>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Button variant="link" onClick={(e) => props.removeItemfromCart(e.target.value, cartItem.node.id)} className="btn-close p-0"></Button>
                                            </ListGroup.Item>
                                        })
                                    }

                                </ListGroup>

                            </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center py-4 px-4">
                            <div className="subtotal d-flex justify-content-between align-items-center w-100 mb-4">
                                <span className="subtotal-title">Subtotal</span>
                                <span className="subtotal-price">${CartData != undefined ? numberWithCommas(CartData.checkout.totalPrice.amount) : 0.00}</span>
                            </div>
                            <a href="#" onClick={(e) => RedirecttoAttendees(e)} className="btn btn-primary rounded-2 checkout-btn m-2">Checkout</a>
                            <Link to={"/" + GAurl} onClick={(e) => props.HideCartSidebarPopup()} className="btn btn-secondary rounded-2 viewcart-btn m-2">Continue Shopping</Link>

                        </Modal.Footer>
                    </>
                    : <Modal.Body className="px-4"><h3>Cart is empty</h3> </Modal.Body>
                }
                {props.Addtocartloading ? <div className="pre_loader"><LoaderIcon /></div> : ''}
            </Modal>
        </>
    )

    return <div className="pre_loader"><LoaderIcon /></div>
}
