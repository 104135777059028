import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#004e6d",
  width = "20px",
  height = "17.075px",
  className = "",
  viewBox = "0 0 20 17.075"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(0 0.075)">
    <rect width="18" height="11.05" transform="translate(1 2.55)" fill={fill} stroke={stroke} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M.5,0V4.25" transform="translate(4.5 0.425)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M.5,0V4.25" transform="translate(14.5 0.425)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M0,.5H17.5" transform="translate(1.5 6.3)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
  </g>
  </svg>
);

export default SVG;