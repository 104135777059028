import React from 'react';
import {
    Modal,
    Button
} from 'react-bootstrap';
export const CookiePref = (props) => {
    return (
        <>
            <Modal size="lg" show={props.CookiePrefPopup} onHide={props.HideCookiePrefPopup} scrollable>
                <Modal.Header closeButton className="border-0 pb-0">
                </Modal.Header>
                <Modal.Body className="px-4">
                    <Modal.Title as="h4" className="mb-4 text-center">Privacy Preferences</Modal.Title>
                    <div className="about_page_wrapper">
                        <p>Our website uses cookies and similar technology to monitor a user’s use and navigation around our website. We may use that information to market more effectively to users and improve our services including our website.</p>
                        <p>We may engage third parties to use the information provided by cookies and similar technology, in order to direct advertising to our website users. Those third parties may have privacy policies which we do not control.</p>
                        <p>You may set your browser to block or manage cookies, however that may result in reduced effectiveness or experience of our website.</p>
                    </div>
                    <div className="form-group text-center">
                        <Button variant="primary" type="button" onClick={props.HideCookiePrefPopup} className="btn-lg border-2 rounded-2">Accept!</Button>
                    </div>
                </Modal.Body>
            </Modal>    </>
    )
}