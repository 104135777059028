import React, { useState, useEffect } from 'react'
import {
    // Card,
    Button,
    Modal,
    Alert,
    Form
} from 'react-bootstrap';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

const customerRecover = gql`
mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
  `;


export const ForgotUsername = (props) => {

    const [RecoverPassword, {
        loading: recoverging_passsword,
        data: recovered_data,
        error: recover_error
    }
    ] = useMutation(customerRecover);




    const [userEmail, setUserEmail] = useState("");
    const [userMobile, setUserMobile] = useState("");



    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);

    async function user_forgot_username_request()
    {
        await axios.get(props.EngineURL + `salesforce-query/forgot-username/index.php?mobile=${userMobile}`, { crossdomain: true })
                .then(function (response) {
                   if(response)
                   {
                    setSuccessMessage(undefined);
                    setErrorMessage(undefined);
                    setSomethingUpadating(false);
                    if(response.data.code == 400)
                    {
                        setErrorMessage(response.data.message);
                    }
                    else if(response.data.code == 200 )
                    {
                        setSuccessMessage(response.data.message);
                    }
                   }
                })
                .catch(function (error) {
                    // handle error
                })
                .then(function () {
                    // always executed
                });
    }

    const submit = (e) => {
        e.preventDefault();
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        setSomethingUpadating(false);

        if (!userMobile) {
            setErrorMessage("Mobile field is required")
        }
        else {
            setSomethingUpadating(true);
            user_forgot_username_request();
        }
    }




    const openLoginPopup = () => {
        props.forgot_username_popup_hide();
        props.ShowLoginPopup();
    }


    return (
        <>
            <Modal
                className="login_modal"
                show={props.forgot_username_popup}
                onHide={props.forgot_username_popup_hide}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body>
                    <Form className="" onSubmit={submit}>
                        <Modal.Title className="text-center">Forgot Your Registered Email ?</Modal.Title>
                        <p className="text-center">We will send you an SMS with your registered email address</p>
                        {SuccessMessage == undefined
                            ?
                            <Form.Group className="mb-3" >
                                   <PhoneInput
                                            international
                                            defaultCountry="AU"
                                            placeholder="Enter Mobile number"
                                            value={userMobile}
                                            onChange={setUserMobile}
                                        />
                            </Form.Group>
                            : ''
                        }
                        {ErrorMessage !== undefined ?
                            <Alert variant="danger">
                                {ErrorMessage}
                            </Alert>
                            : ''}
                        {SuccessMessage !== undefined ? <>
                            <Alert variant="success">
                                {SuccessMessage}
                            </Alert>
                            <Button className="w-100 rounded-2 btn-lg border-2" onClick={(e) => openLoginPopup()}>Login</Button></>
                            : ''}
                        {SomethingUpadating === true ?
                            <Alert variant="primary">
                                Validating...
                            </Alert>
                            : ''}


                        {SuccessMessage == undefined
                            ?
                            <>
                            <div className="text-center d-flex justify-content-between">
                                <Button variant="primary" type="submit" className="rounded-2 btn-lg border-2 ms-0 m-2 w-50">Submit</Button> 
                                <Button variant="outline-secondary" type="submit" className="rounded-2 btn-lg border-2 m-2 me-0 w-50"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.ShowLoginPopup();
                                    props.forgot_username_popup_hide();
                                    } }>Cancel</Button> 
                            </div>
                            </>
                            : ''}
                    </Form>




                </Modal.Body>
            </Modal>
        </>
    )
}
