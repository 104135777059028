import React from 'react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import Bg from "../../images/bg.jpg"
export const AdvantageSection = (props) => {
    return (
        <div className='advantage_wrapper'>
            <Container>
                <div className='advantage_inner' style={{ backgroundImage: "url(" + Bg + ")" }}>
                    <div className='title_wrap text-center'>
                        <h2 className='text-white'>{props.AdvantageTitle}</h2>
                    </div>
                    <div className='advantage_block_wrap'>
                        <Row className="align-items-center justify-content-center justify-content-lg-between">
                            {props.AdvantageList.map((advantages, index) => {
                                return <Col xs={12} md="auto" key={index}>
                                    <div className='advantage_block d-flex align-items-center'>
                                        <div className='advantage_block_icon'>
                                            <img src={advantages.icon_url} alt={advantages.title}></img>
                                        </div>
                                        <div className='advantage_block_content'>
                                            <h5 dangerouslySetInnerHTML={{ __html: advantages.title }} className="mb-0"></h5>
                                        </div>
                                    </div>
                                </Col>
                            })

                            }

                        </Row>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AdvantageSection;
