import React from "react";

const SVG = ({
  style = {},
  fill = "#848484",
  width = "8px",
  height = "6px",
  className = "",
  viewBox = "0 0 8 6"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path fill={fill} d="M4,0,8,6H0Z" />
  </svg>
);

export default SVG;