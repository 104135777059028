import React from 'react'
import {
    Container,
    Row,
    Col,
    Accordion,
    Form,
    FormControl,
    Button,
    Pagination,
    InputGroup
} from 'react-bootstrap';
export const Paginator = (props) => {
    const totalPages = props.totalPages;
    const pagination = [];
    function createLink(i) {
     
        return (
            <Pagination.Item
                key={"inside"+i}
                className={i == props.currentPage ? 'active' : null}
                onClick={() => props.setcurrentPage(i)}>
                {i}
            </Pagination.Item>
        );
    }

    function createDots() {
        return <li key="dfdc" className="page-item"><span className="page-link" role="button">...</span></li>;
    }

    // If there are no pages return a message
    if (!totalPages) return <div>No pages</div>;

    // If totalPages is less than seven, iterate
    // over that number and return the page links
    if (totalPages < 7) {
        for (let i = 1; i <= totalPages; i++) {
            pagination.push(createLink(i));
        }
        return pagination;
    }

    // Otherwise create the first three page links
    for (let i = 1; i <= 3; i++) {
        pagination.push(createLink(i));
    }

    if (props.currentPage > 3 && props.currentPage < totalPages - 2) {
        pagination.push(createDots());
        pagination.push(createLink(props.currentPage));
        pagination.push(createDots());

    }
    else {
        // Create the dots
        pagination.push(createDots());
    }


    // Last three page links
    for (let i = totalPages - 2; i <= totalPages; i++) {
        pagination.push(createLink(i));
    }

    return pagination;

}