import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke = "#004e6d",
  width = "18",
  height = "9.121",
  className = "",
  viewBox = "0 0 18 9.121"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(17 7.053) rotate(90)">
        <path d="M0,.654H.971" transform="translate(0 7.846)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="2"/>
    <path d="M0,0,7,7,0,14" transform="translate(-6.346 1.5)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="2"/>
  </g>
  </svg>
);

export default SVG;
