import React from "react";

const SVG = ({
  style = {},
  fill = "#004e6d",
  stroke="none",
  width = "23.696",
  height = "19",
  className = "",
  viewBox = "0 0 23.696 19"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path d="M23.7,2.257a8.835,8.835,0,0,1-2.805.728A4.718,4.718,0,0,0,23.031.364a9.385,9.385,0,0,1-3.1,1.165A4.783,4.783,0,0,0,16.461,0a4.834,4.834,0,0,0-4.872,4.8A5.546,5.546,0,0,0,11.737,5.9,13.982,13.982,0,0,1,1.7.874a4.534,4.534,0,0,0-.664,2.4,4.906,4.906,0,0,0,2.141,4A4.558,4.558,0,0,1,.96,6.7V6.77a4.781,4.781,0,0,0,3.912,4.659,7.37,7.37,0,0,1-1.255.146,3.643,3.643,0,0,1-.886-.073,4.921,4.921,0,0,0,4.5,3.349,9.62,9.62,0,0,1-6.053,2.038A6.437,6.437,0,0,1,0,16.816,13.748,13.748,0,0,0,7.456,19,13.6,13.6,0,0,0,21.26,5.387V4.8A13.243,13.243,0,0,0,23.7,2.257Z" fill={fill} /></svg>
);

export default SVG;