import React from 'react';
import {
    Modal,
    Form,
    Row,
    Col,
    FormCheck
} from 'react-bootstrap';
import {
    LogoIcon,
    LocationNew
} from "../../icons";
export const StatePopup = (props) => {
    return (
        <>
            <Modal className="location_modal" size="lg" show={props.showStatePopup} onHide={props.hidedStatePopup} centered>
                <Modal.Header className="border-0 justify-content-center flex-column">
                    <a href="/"><LogoIcon /></a>
                    <Modal.Title as="h3" className="text-center">Please select a state to view specific information for that location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="location_modal_content text-center">
                        <p className="mb-0  justify-content-center"><LocationNew /> You appear to be in &nbsp;<span className="text-uppercase text-decoration-underline d-inline-block fw-bold"> {props.userStateIp}</span>. Is this correct?</p>
                        <p className="mb-0">(You can update your selection anytime from the menu.)</p>
                    </div>
                    <Form className="location_option">

                        <Row>
                            <Col sm={6} xs={6}>
                                <Form.Check type='radio' onChange={(e) => props.setTempState("VIC")} name="location" id="VIC" label="VIC" checked={props.TempState === "VIC"} />
                            </Col>
                            <Col sm={6} xs={6}>
                                <Form.Check type='radio' onChange={(e) => props.setTempState("WA")} name="location" id="WA" label="WA" checked={props.TempState === "WA"} />
                            </Col>
                            <Col sm={6} xs={6}>
                                <Form.Check type='radio' onChange={(e) => props.setTempState("QLD")} name="location" id="QLD" label="QLD" checked={props.TempState === "QLD"} />
                            </Col>
                            <Col sm={6} xs={6}>
                                <Form.Check type='radio' onChange={(e) => props.setTempState('SA')} name="location" id="SA" label="SA" checked={props.TempState === "SA"} />
                            </Col>
                            <Col sm={6} xs={6}>
                                <Form.Check type='radio' onChange={(e) => props.setTempState("NSW")} name="location" id="NSW" label="NSW" checked={props.TempState === "NSW"} />
                            </Col>
                            <Col sm={6} xs={6}>
                                <Form.Check type='radio' onChange={(e) => props.setTempState("TAS")} name="location" id="TAS" label="TAS" checked={props.TempState === "TAS"} />
                            </Col>
                            <Col sm={6} xs={6}>
                                <Form.Check type='radio' onChange={(e) => props.setTempState("ACT")} name="location" id="ACT" label="ACT" checked={props.TempState === "ACT"} />
                            </Col>
                            <Col sm={6} xs={6}>
                                <Form.Check type='radio' onChange={(e) => props.setTempState("NT")} name="location" id="NT" label="NT" checked={props.TempState === "NT"} />
                            </Col>
                            <Col xs={12} className="text-center">
                                <button type="button" onClick={(e) => props.setStateforUser()} className="btn btn-secondary">Proceed</button>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
            </Modal>
        </>
    )
}