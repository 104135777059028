import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Modal,
    Table
} from 'react-bootstrap';
export const PrivacyPolicy = (props) => {
   // console.log('pp_props',props);
    return (
        <>
            <Modal className="privacy_policy_modal" size="lg" show={props.show} onHide={() => props.hide()} scrollable>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title as="h2" className="mb-0">Leo Cussen Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <div className="about_page_wrapper">
                        <div className="conditions">
                            <ol>
                                <li><strong>Your rights in relation to privacy</strong></li>
                            
                                <p>Leo Cussen Institute, trading as Leo Cussen Centre for Law, (Leo Cussen) understands the importance of protecting the privacy of an individual’s personal information. This policy sets out how Leo Cussen aims to protect the privacy of your personal information, your rights in relation to your personal information managed by Leo Cussen and the way Leo Cussen collects, holds, uses and discloses your personal information.</p>
                                <p>In handling your personal information, Leo Cussen will comply with the Privacy Act 1988 (Cth) (Privacy Act) and with the 13 Australian Privacy Principles in the Privacy Act. This policy may be updated from time to time.</p>

                                <li><strong>What kinds of personal information does Leo Cussen collect? </strong></li>
                                <p>Personal information is information or an opinion about an identified, or reasonably identifiable, individual. During the provision of its services, Leo Cussen may collect your personal information.</p>
                                <p>Generally, the kinds of personal information Leo Cussen collects are:</p>
                                <ul>
                                    <li>general contact and identification information such as your name, address, telephone number, email address, facsimile number, date of birth;</li>
                                    <li>information about citizenship/visa status, country of birth and your rights to study in Australia which may include details, copies or presentation of the originals of any applicable visa, passport, birth or citizenship certificate, or other relevant documentation;</li>
                                    <li>academic results from other institutions and enrolment details;</li>
                                    <li>any personal information relevant to the training programs you undertake, including your professional and academic progress at Leo Cussen’s practical training programs and registration in professional development programs, Leo Cussen events, surveys and for Leo Cussen products;</li>
                                    <li>information about your education and work history, qualifications and skills, including resumes and applications with supporting documentation, and other information obtained as part of reference or background checks;</li>
                                    <li>opinions of others about you and your work or study performance (whether true or not);</li>
                                    <li>sensitive information (e.g. information about your health, medical history or specific condition, criminal record, professional memberships etc.), which will only be collected with your consent;</li>
                                    <li>credit card details to facilitate payment for Leo Cussen’s services;</li>
                                    <li>tax file numbers, ABN numbers, relevant bank and superannuation account information necessary to facilitate the payment of wages and superannuation contributions and to ensure appropriate taxation treatment;</li>
                                    <li>any personal information relevant to the handling of complaints made by or against you during the course of your studies;</li>
                                    <li>recordings of teaching and learning activities involving your image or voice captured during participation in programs, lectures, mock hearings, role plays and other Leo Cussen events;</li>
                                    <li>other information such as notes and written statements made by staff and/or instructors, presenters and consultants and recordings of teaching and learning activities;</li>
                                    <li>your social network profile; and</li>
                                    <li>other information relevant in the circumstances.</li>
                                </ul>
                                <p>In some circumstances Leo Cussen may also hold other personal information provided by you.</p>

                                <li><strong>How does Leo Cussen collect personal information? </strong></li>
                                <p>Generally, Leo Cussen collects your personal information directly from you, through the completion of a manual or online form, an interaction or exchange in person or by way of telephone, facsimile, email, post or through the use of the Leo Cussen website, for example, to register for a course or event.</p>
                                <p>There may be occasions when Leo Cussen collects your personal information from other sources such as from:</p>
                                <ul>
                                    <li>other educational and academic institutions to verify enrolments and academic performance;</li>
                                    <li>regulatory authorities;</li>
                                    <li>an information services provider;</li>
                                    <li>a publicly maintained record or other publicly available sources of information including social media and similar websites; or</li>
                                    <li>if for recruitment purposes, an external recruitment or background screening services provider.</li>
                                </ul>
                                <p>Generally, Leo Cussen will only collect your personal information from sources other than you if it is unreasonable or impracticable to collect your personal information from you.</p>
                            
                                <li><strong>Why does Leo Cussen need your personal information? </strong></li>
                                <p>Leo Cussen collects, holds, uses and discloses your personal information where it is reasonably necessary for one or more of Leo Cussen’s functions or activities, including:</p>
                                <ul>
                                    <li>Leo Cussen providing its services to you, including conducting its courses, programs and other Leo Cussen events such as its practical legal training and ongoing professional development for law graduates seeking admission, lawyers, and legal support staff;</li>
                                    <li>verification of enrolments as required by the Legal Services Board or Legal Services Council;</li>
                                    <li>enabling students to be admitted to legal practice as required by admitting authorities such as the Victorian Legal Admissions Board;</li>
                                    <li>providing references to Commonwealth Higher Education authorities and potential employers of students (where required);</li>
                                    <li>accounting, billing and other internal administrative purposes, including product sales;</li>
                                    <li>business management purposes, including cloud service providers for data storage purposes;</li>
                                    <li>identifying and informing you of products and services that may be of interest to you from Leo Cussen or selected third parties, for example, where you have enquired to Leo Cussen about study opportunities or where you have applied to undertake a course of study with Leo Cussen or another Australian educational institution or an overseas education institution;</li>
                                    <li>conducting research for statistical, analytical and training purposes;</li>
                                    <li>responding to your questions;</li>
                                    <li>analysing student progress;</li>
                                    <li>where you are engaged to teach or otherwise facilitate a training course, program or other event, collect and collate feedback from participants for training and development;</li>
                                    <li>assessing your application for employment with Leo Cussen or otherwise for the purpose of engaging you as a contractor or consultant; and</li>
                                    <li>any other legal requirements.</li>
                                </ul>
                                <p>Leo Cussen may also use your personal information for purposes related to the above purposes and for which you would reasonably expect Leo Cussen to do so in the circumstances, or where you have consented or the use is otherwise in accordance with law.</p> 
                                <p>Where personal information is used or disclosed, Leo Cussen takes steps reasonable in the circumstances to ensure it is relevant to the purpose for which it is to be used or disclosed. You are under no obligation to provide your personal information to Leo Cussen. However, without certain information from you, Leo Cussen may not be able to provide its products and/or services to you.</p>

                                <li><strong>To whom does Leo Cussen disclose your personal information? </strong></li>
                                <p>Leo Cussen discloses your personal information for the purpose for which Leo Cussen collects it. That is, generally, Leo Cussen will only disclose your personal information for a purpose set out at paragraph 4. This may include disclosing your personal information to:</p>
                                <ul>
                                    <li>third parties engaged to perform administrative or other business management functions;</li>
                                    <li>people or entities considering acquiring an interest in Leo Cussen’s enterprise or assets;</li>
                                    <li>Leo Cussen’s professional advisors, contractors, consultants and related bodies corporate;</li>
                                    <li>insurance providers;</li>
                                    <li>regulatory bodies, including the Legal Services Board, or Legal Services Council;</li>
                                    <li>the Victorian Legal Admissions Board, Commonwealth Higher Education authorities or other such authorities;</li>
                                    <li>presenters, instructors and other consultants engaged by Leo Cussen to enable them to monitor attendees;</li>
                                    <li>potential employers of Leo Cussen students and participants by way of verbal or written references; and</li>
                                    <li>the police or other emergency services where Leo Cussen reasonably believes it is necessary for the conduct of investigations by law enforcement bodies.</li>
                                </ul>
                                <p>Leo Cussen’s disclosures of your personal information to third parties are on a confidential basis or otherwise in accordance with law. Leo Cussen may also disclose your personal information with your consent or if disclosure is required or authorised by law.</p>

                                <li><strong>Overseas disclosure</strong></li>
                                <p>Leo Cussen may disclose personal information to overseas-based cloud service providers for data storage purposes.</p>
                                <p>Overseas recipients of information may have different privacy and data protection standards. However, before disclosing any personal information to an overseas recipient, Leo Cussen will take reasonable steps in the circumstances to ensure the overseas recipient complies with the Australian Privacy Principles or is bound by a substantially similar privacy scheme, unless the disclosure is consented to by the relevant individual or otherwise required or permitted by law.</p>

                                <li><strong>Direct marketing</strong></li>
                                <p>Leo Cussen employs direct marketing for the purpose of carrying out its functions and activities set out in paragraph 4. In the event you do not wish to receive such communications, you can opt-out by contacting Leo Cussen’s Privacy Compliance Officer via the contact details set out in paragraph 11 or through any opt-out mechanism contained in a marketing communication to you.</p>
                            
                                <li><strong>Security of your personal information </strong></li>
                                <p>Leo Cussen takes steps reasonable in the circumstances to ensure that the personal information it holds is protected from misuse, interference and loss and from unauthorised access, modification or disclosure. Leo Cussen holds personal information in both hard copy and electronic forms in secure databases on secure premises, accessible only by authorised staff. Leo Cussen may also store personal information with a cloud service provider.</p>
                                <p>Leo Cussen will destroy or de-identify personal information in circumstances where it is no longer required, unless Leo Cussen is otherwise required or authorised by law to retain the information.</p>

                                <li><strong>Can you access and correct the personal information that Leo Cussen holds about you? </strong></li>
                                <p>Leo Cussen takes steps reasonable in the circumstances to ensure personal information it holds is accurate, up-to-date, complete, relevant and not misleading. Under the Privacy Act, you have a right to access and seek correction of your personal information that is collected and held by Leo Cussen. If at any time you would like to access or correct the personal information that Leo Cussen holds about you, or you would like more information on Leo Cussen’s approach to privacy, please contact Leo Cussen’s Privacy Compliance Officer on the details set out in paragraph 11 below.</p>
                                <p>Leo Cussen will grant access to the extent required or authorised by the Privacy Act or other law and take steps reasonable in the circumstances to correct personal information where necessary and appropriate.</p>
                                <p>To obtain access to your personal information:</p>
                                <ul>
                                    <li>you will have to provide proof of identity to ensure that personal information is provided only to the correct individuals and that the privacy of others is protected;</li>
                                    <li>Leo Cussen requests that you be reasonably specific about the information you require; and</li>
                                    <li>Leo Cussen may charge you a reasonable administration fee, which reflects the cost to Leo Cussen, for providing access in accordance with your request.</li>
                                </ul>
                                <p>If Leo Cussen refuses your request to access or correct your personal information, Leo Cussen will provide you with written reasons for the refusal and details of complaint mechanisms. Leo Cussen will also take steps reasonable in the circumstance to provide you with access in a manner that meets your needs and the needs of Leo Cussen.</p>
                                <p>Leo Cussen will endeavour to respond to your request to access or correct your personal information within a reasonable period.</p>

                                <li><strong>Cookies</strong></li>
                                <p>Our website uses cookies and similar technology to monitor a user’s use and navigation around our website. We may use that information to market more effectively to users and improve our services including our website.</p>
                                <p>We may engage third parties to use the information provided by cookies and similar technology, in order to direct advertising to our website users. Those third parties may have privacy policies which we do not control.</p>
                                <p>You may set your browser to block or manage cookies, however that may result in reduced effectiveness or experience of our website.</p>
                            
                                <li><strong>How to contact us </strong></li>
                                <p>For further information or enquiries regarding your personal information, or if you would like to opt-out of receiving any promotional or marketing communications, please contact Leo Cussen’s Privacy Compliance Officer within the business hours of 9am to 5pm, Monday to Friday.</p>
                                <p>The Leo Cussen Privacy Compliance Officer is Shirley Southgate, who can be contacted using the following details:</p>
                                <p>c/- Leo Cussen Centre for Law at 15 William Street, 3000, Melbourne</p>
                                <p>
                                    <strong>Phone:</strong> <a href="tel:+61 3 8667 5667">+61 3 8667 5667</a>.<br/>
                                    <strong>Email:</strong>&nbsp;<a href="mailto:privacy@leocussen.edu.au">privacy@leocussen.edu.au</a><br/>
                                    <strong>Address:</strong><br/>
                                    Level 16,<br/>
                                    15 William Street<br/>
                                    Melbourne, Victoria, 3000
                                </p>

                                <li><strong>Privacy complaints </strong></li>
                                <p>Please direct all privacy complaints to Leo Cussen’s Privacy Compliance Officer. At all times, privacy complaints:</p>
                                <ul>
                                    <li>will be treated seriously;</li>
                                    <li>will be dealt with promptly;</li>
                                    <li>will be dealt with in a confidential manner; and</li>
                                    <li>will not affect your existing obligations or affect the commercial arrangements between you and Leo Cussen.</li>
                                </ul>
                                <p>Leo Cussen’s Privacy Compliance Officer will commence an investigation into your complaint. You will be informed of the outcome of your complaint following completion of the investigation. In the event that you are dissatisfied with the outcome of your complaint, you are able to contact the Office of the Australian Information Commissioner.</p>
                            
                                <li><strong>Adoption and Review details</strong></li>
                                <p>This policy was approved for adoption by the Leo Cussen Management Team on 14 October 2015. Approved by: Leo Cussen Management Team</p>
                                <p>Effective date: 14 October 2015<br/>
                                    Review date: July 2017<br/>
                                    Policy Owner: Privacy Compliance Officer
                                </p>
                                <p>Amendment history:</p>
                            </ol>
                            <Table striped bordered hover>
                                <thead className="btn-primary">
                                    <tr>
                                        <th>Version</th>
                                        <th>Date</th>
                                        <th>Author</th>
                                        <th>Change Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1.01</td>
                                        <td>29/10/2019</td>
                                        <td>Jose Waihga</td>
                                        <td>Updated address</td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}