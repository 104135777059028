import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag';

import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { AdminSidebar } from './Sidebar/AdminSidebar';
import axios from 'axios'
import { LoveItem } from './whishlist-item/item';
import BlueBg from "../../images/my-profile-banner.png";


export const AdminWishlist = (props) => {
    const [getAllWishlist, setgetAllWishlist] = useState(() => {
        return (localStorage.getItem('wishlist_item') !== undefined && localStorage.getItem('wishlist_item') !== 'undefined' && localStorage.getItem('wishlist_item') !== null) ? JSON.parse(localStorage.getItem('wishlist_item')) : []
    });

    const FetchWishlistData = async () => {
        let userDetail = JSON.parse(localStorage.getItem("userDetail"));

        axios.get(props.EngineURL + 'wishlist/wishlist_get.php?email=' + userDetail.email)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.length !== 0 && response.data.result.length !== 0) {
                        localStorage.setItem('wishlist_item', JSON.stringify(response.data.result));
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    useEffect(() => {
        if (getAllWishlist.length === 0) {
            if (props.useLoggedIn) {
                FetchWishlistData();
            }
        }
    }, []);


    /**
     * Do logout
     */
    const doLogout = () => {
        localStorage.removeItem("userDetail");
        localStorage.setItem('isLoggedin', false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        window.location.href = "/";
    }






    return (
        <>   <div className="admin_page admin_cpd_points" style={{ backgroundImage: "url(" + BlueBg + ")" }}>
            <Helmet>
                <title>My Wishlists | Leo Cussen Centre for Law </title>
            </Helmet>
            <Container>
                {
                    props.useLoggedIn !== undefined && props.useLoggedIn == true
                        ?

                        <div className="admin_wrapper position-relative">
                            {props.UserData !== null && props.UserData !== undefined ?
                                <div className='admin_details_block mb-5'>
                                    <h1 className="m-0">{props.Good},  {props.UserData.customer.firstName}</h1>
                                </div>
                                : null}
                            <Row>
                                <Col xl={3} lg={4} xs={12} className="d-none d-lg-block">
                                    <AdminSidebar doLogout={doLogout} />
                                </Col>
                                <Col xl={9} lg={8} xs={12}>
                                    <div className='admin_sidebar_mobile d-lg-none'>
                                        <AdminSidebar doLogout={doLogout} />
                                    </div>
                                    <div className="admin_right_content_block wishlist_right_block">
                                        <h4 className="mb-4 text-dark">My Wishlists</h4>

                                        <div className="g-4 row row-cols-1">
                                            {getAllWishlist.length > 0
                                                ?
                                                <>{getAllWishlist.map((value, index) => {
                                                    return <LoveItem productHandle={value}
                                                        key={'ldo-' + index}
                                                        AddtoWishlist={props.AddtoWishlist}
                                                        useLoggedIn={props.useLoggedIn}
                                                        getAllWishlist={props.getAllWishlist}
                                                        Program_structure_images={props.Program_structure_images}
                                                        AddtoCart={props.AddtoCart}
                                                        Program_structure_color={props.Program_structure_color}
                                                    />
                                                })
                                                }</>
                                                :
                                                <h3>Your wishlist is empty</h3>
                                            }



                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        :
                        <>
                            <div className="text-center"><h3>Please login to access My profile section</h3><a onClick={() => props.ShowLoginPopup_()} className="btn btn-primary rounded-pill m-0 stest"> Login</a></div>
                        </>
                }
            </Container>
        </div>

        </>
    );
}