import React from "react";

const SVG = ({
  style = {},
  fill ="none",
  fillcolor ="#004e6d",
  stroke="#004e6d",
  width = "17.632",
  height = "18.132",
  className = "",
  viewBox = "0 0 17.632 18.132"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(0.5 0.632)">
    <path d="M6.5,0H0V13H13V7.5" transform="translate(0 4)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    <g transform="translate(16.425 2.197) rotate(135)">
      <rect width="14" height="3" transform="translate(0 0)" fill={fill} stroke={stroke} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
      <path d="M.5,0V3" transform="translate(3.015 0)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
      <path d="M0,0,4,1.5,0,3" transform="translate(14 0)" fill={fill} stroke={stroke} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
    </g>
  </g>
  </svg>
);

export default SVG;
