import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  stroke="none",
  width = "23.5",
  height = "23.5",
  className = "",
  viewBox = "0 0 24.5 24.5"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g  transform="translate(0.5 0.5)">
    <path fill={fill} stroke={stroke} d="M21.762,23.5H1.738A1.718,1.718,0,0,1,0,21.807V1.694A1.718,1.718,0,0,1,1.738,0H21.762A1.718,1.718,0,0,1,23.5,1.694V21.807A1.718,1.718,0,0,1,21.762,23.5Zm-7.03-11.9c1.813,0,1.813,1.719,1.813,2.974v5.453h3.48V13.871c0-2.775-.506-5.342-4.179-5.342a3.688,3.688,0,0,0-3.3,1.812H12.5V8.813H9.16V20.026h3.479V14.477C12.639,13.054,12.888,11.6,14.732,11.6ZM3.484,8.813V20.026H6.973V8.813ZM5.228,3.236A2.021,2.021,0,1,0,7.25,5.257,2.023,2.023,0,0,0,5.228,3.236Z" transform="translate(0)" strokeMiterlimit="10" strokeWidth="0"/>
  </g>
  </svg>
);

export default SVG;