import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke = "#848484",
  width = "20.533px",
  height = "20.533px",
  className = "",
  viewBox = "0 0 20.533 20.533"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-933.4 -1158.734)">
    <path fill={fill} stroke={stroke} d="M22.333,12.667A9.667,9.667,0,1,1,12.667,3,9.667,9.667,0,0,1,22.333,12.667Z" transform="translate(931 1156.334)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
    <path fill={fill} stroke={stroke} d="M12.934,10.326V11.8a.982.982,0,0,1-1.071.982,9.718,9.718,0,0,1-4.238-1.508A9.576,9.576,0,0,1,4.679,8.328,9.718,9.718,0,0,1,3.172,4.07.982.982,0,0,1,4.149,3H5.622a.982.982,0,0,1,.982.845,6.305,6.305,0,0,0,.344,1.38.982.982,0,0,1-.221,1.036L6.1,6.884A7.857,7.857,0,0,0,9.05,9.83l.624-.624a.982.982,0,0,1,1.036-.221,6.305,6.305,0,0,0,1.38.344.982.982,0,0,1,.845,1Z" transform="translate(935.866 1161.494)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
  </g>
  </svg>
);

export default SVG;
