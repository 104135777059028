import React, { useState, useEffect } from 'react';
import {
  PlusIcon,
  MinusIcon,
  LoaderIcon
} from "../../icons";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table
} from 'react-bootstrap';
import gql from 'graphql-tag'
import {
  useQuery
} from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
export const Cart = (props) => {
  const location = useLocation();
  let history = useHistory();
  const openAttendeesPage = () => {
    history.push('./attendees')
  }

  const [CardItem_sku, AdditemTOcart_sku] = useState(() => {
    const cart_item = localStorage.getItem("cart_session_sku");
    return cart_item !== null
      ? JSON.parse(cart_item)
      : { lineItems: [] }
  });


  /***
* Get product record type
*/
  function getProgramStructureImage(variantID) {
    let psku = undefined;
    props.CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = linedata.pimage[0];
      }
    })
    return psku;
  }


  if (props.CartData != undefined && props.CartData.checkout.lineItems.edges.length) {
    return (
      <div className="cart_page">
        <Helmet>
            <title>Cart | Leo Cussen Centre for Law</title>
            <meta name="description" content="" />
        </Helmet>
        <Container>
          <h1 className="mb-5">Cart</h1>
          <Row>
            <Col xs={12}>
              <Table className="card_product_listing">
                <thead>
                  <tr>
                    <th colSpan="6" className="p-0">
                      <Table className="card_product_listing_head shadow bg-white rounded-2 mb-0 border-0">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th>Total</th>
                            <th></th>
                          </tr>
                        </thead>
                      </Table>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="6" className="p-3"></td>
                  </tr>
                  <tr>
                    <td colSpan="6" className="p-0">
                      <Table className="card_product_listing_inner shadow bg-white rounded-2">
                        <tbody>
                          {props.CartData.checkout.lineItems.edges.map((cartItem) => {
                            return <tr>
                              <td className="cate_td">
                                <div className="d-none d-md-flex justify-content-center flex-column product_cate">
                                  <img src={getProgramStructureImage(cartItem.node.variant.id)} alt={cartItem.node.title} />
                                </div>
                              </td>
                              <td className="product_name">
                                <h6 className="display-6 text-secondary d-none mb-0">Product</h6>
                                <h5 className="mb-0">{cartItem.node.title}</h5>
                              </td>
                              <td className="product_price">
                                <h6 className="display-6 text-secondary d-sm-none">Price</h6>
                                ${parseFloat(cartItem.node.variant.price.amount).toFixed(2)}
                              </td>
                              <td className="product_qty">
                                <h6 className="display-6 text-secondary d-sm-none">Qty</h6>
                                <Form.Group className="form-group">
                                  <div className="quantity_group position-relative d-inline-flex align-items-center">
                                    <Form.Control type="number" value={cartItem.node.quantity} onChange={(e) => props.UpdateCartItem(e.target.value, cartItem.node.id)} />
                                    <div className="quantity_changer">
                                      <span className="up_no" onClick={(e) => props.UpdateCartItem(++cartItem.node.quantity, cartItem.node.id)}><PlusIcon /></span>
                                      <span className="down_no" onClick={(e) => props.UpdateCartItem(--cartItem.node.quantity, cartItem.node.id)}><MinusIcon /></span>
                                    </div>
                                  </div>
                                </Form.Group>
                              </td>
                              <td className="product_total">
                                <h6 className="display-6 text-secondary d-sm-none">Total</h6>
                                ${parseFloat(cartItem.node.variant.price.amount * cartItem.node.quantity).toFixed(2)}
                              </td>
                              <td align="end" className="px-4"><Button variant="link" onClick={(e) => props.removeItemfromCart(e.target.value, cartItem.node.id)} className="btn-close p-0"></Button></td>
                            </tr>
                          })
                          }
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col lg={8} md={7} xs={12}>
              <Button onClick={(e) => openAttendeesPage()} className="btn-lg border-2 rounded-pill">Checkout</Button>
            </Col>
            <Col lg={4} md={5} xs={12}>
              <div className="attendees_order_right_sidebar bg-white">
                <h3>Order</h3>
                <div className="attendees_order_item_total d-flex flex-wrap align-items-center justify-content-between">
                  <h6 className="display-6 text-secondary">Item Total</h6>
                  <h6 className="display-6 text-secondary">${props.CartData.checkout.subtotalPrice}</h6>
                </div>
                <div className="attendees_order_total_gst d-flex flex-wrap align-items-center justify-content-between">
                  <h3 className="text-white mb-0">Total
                    <small className="display-6 text-white mb-0">(Include GST)</small>
                  </h3>
                  <h3 className="text-white mb-0">${props.CartData.checkout.totalPrice}</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  return <h1>Cart is empty!</h1>
}
