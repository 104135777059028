import React from 'react';
import ImageNotFound from '../../images/404.png';
import {
    Container
} from 'react-bootstrap';
export const NotFound = () => {
    return (
        <>
            <Container>
                <div className='text-center'>
                    <img src={ImageNotFound} alt="not found" className='d-block mx-auto' />
                </div>
            </Container>
        </>
    );
}