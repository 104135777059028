import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import {
    RightAngleIcon,
    CalendarIcon,
    LocationIcon,
    ClockIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    LikeIcon
} from "../../../icons";
import {
    Row,
    Col,
    Form,
    Card,
    ListGroup
} from 'react-bootstrap';
import user_default_image from '../../../images/user.png';


import pm from '../../../images/pm-ic.png';
import ps from '../../../images/ps-ic.png';
import sl from '../../../images/sl-ic.png';
import ep from '../../../images/ep-ic.png';
export const ProductGrid = ({ product, AddtoCart, Program_structure_images, is_ten_minite_less, program_date_is_gone_or_not, AddtoWishlist, getAllWishlist, Program_structure_color, GAurl, expandAll }) => {

    const [metaData, set_metaData] = useState({});

    useEffect(() => {
        product.metafields.forEach((meta_val, meta_key) => {
            if (meta_val.key === "all_data") {
                set_metaData(JSON.parse(meta_val.value));
            }
        })
    }, []);




    const [substantive_law, setSubstantive_law] = useState(metaData.Substantive_Law__c !== undefined && metaData.Substantive_Law__c !== null ? metaData.Substantive_Law__c : 0);
    const [professional_skills, setProfessional_skills] = useState(metaData.Professional_Skills__c !== undefined && metaData.Professional_Skills__c !== null ? metaData.Professional_Skills__c : 0);
    const [practise_managment_skill, setPractise_managment_skill] = useState(metaData.Practice_Management_Business_Skills__c !== undefined && metaData.Practice_Management_Business_Skills__c !== null ? metaData.Practice_Management_Business_Skills__c : 0);
    const [ethics_professional_responsibi, setEthics_professional_responsibi] = useState(metaData.Ethics_Professional_Responsibility__c !== undefined && metaData.Ethics_Professional_Responsibility__c !== null ? metaData.Ethics_Professional_Responsibility__c : 0);
    const [totalCpdPoints, setTotalCpdPoints] = useState(substantive_law + professional_skills + practise_managment_skill + ethics_professional_responsibi);
    const [venue, setVenue] = useState('');
    const [DeliveryMode, setDeliveryMode] = useState([]);
    const [longPromotionMessage, setLongPromotionMessage] = useState('');
    const [shortPromotionMessage, setShortPromotionMessage] = useState('');
    const [program_date_time_line_2, setProgram_date_time_line_2] = useState('');
    const [program_date_time_line_1, setProgram_date_time_line_1] = useState('');
    const [reached_capacity, set_reached_capacity] = useState(false);
    const [Program_Types__c, setProgram_Types__c] = useState([]);
    const [Program_Types__c_color, setProgram_Types__c_color] = useState('#bc318c');
    const [Early_Bird_Price__c, setEarly_Bird_Price__c] = useState(undefined);
    const [DefaultRadioValue, setDefaultRadioValue] = useState("");

    const [DefaultRadio, setDefaultRadio] = useState(0);

    const [Program_Structure__c, setProgram_Structure__c] = useState(undefined);
    const [Program_Structure__c_Array, setProgram_Structure__c_Array] = useState([]);

    const [sDate, setsDate] = useState(undefined);

    const [presentor, setpresentor] = useState([])

    const [RecordType, setRecordType] = useState("")

    const UpdateRadio = (value, index) => {

        setDefaultRadioValue(value);
        setDefaultRadio(index);
    }


    const [TEN_MIntLeft, setTEN_MIntLeft] = useState(false);
    const [isProgramOver, setisProgramOver] = useState(false);


    const [Program_structure_image, setProgram_structure_image] = useState(['https://engine.leocussen.edu.au/sync/img/logo.png']);


    const isNotEmpty = (value) => {
        return value !== null && value !== undefined;
    }

    useEffect(() => {
        setSubstantive_law(isNotEmpty(metaData.Substantive_Law__c) ? metaData.Substantive_Law__c : 0);
        setProfessional_skills(isNotEmpty(metaData.Professional_Skills__c) ? metaData.Professional_Skills__c : 0);
        setPractise_managment_skill(isNotEmpty(metaData.Practice_Management_Business_Skills__c) ? metaData.Practice_Management_Business_Skills__c : 0);
        setEthics_professional_responsibi(isNotEmpty(metaData.Ethics_Professional_Responsibility__c) ? metaData.Ethics_Professional_Responsibility__c : 0);
        setTotalCpdPoints(substantive_law + professional_skills + practise_managment_skill + ethics_professional_responsibi);
        setProgram_date_time_line_2(isNotEmpty(metaData.Program_Date_Time_Line_2__c) ? metaData.Program_Date_Time_Line_2__c : "");
        setProgram_date_time_line_1(isNotEmpty(metaData.Program_Date_Time_Line_1__c) ? metaData.Program_Date_Time_Line_1__c : "");


        setVenue(isNotEmpty(metaData.Venue__c) ? metaData.Venue__c : "");
        setDeliveryMode(isNotEmpty(metaData.delivery_mode) ? metaData.delivery_mode : []);

        setLongPromotionMessage(isNotEmpty(metaData.Long_Promotion_Message__c) ? metaData.Long_Promotion_Message__c : '');
        setShortPromotionMessage(isNotEmpty(metaData.Short_Promotion_Message__c) ? metaData.Short_Promotion_Message__c : '');

        set_reached_capacity(isNotEmpty(metaData.Reached_Capacity__c) ? metaData.Reached_Capacity__c : false);

        setProgram_Types__c(isNotEmpty(metaData.Program_Types__c) ? metaData.Program_Types__c : [])
        setsDate(isNotEmpty(metaData.start_date_time) ? metaData.start_date_time : "");
        setRecordType(isNotEmpty(metaData.RecordType) ? metaData.RecordType : "");
        setpresentor(isNotEmpty(metaData.presentor) ? metaData.presentor : []);
        setEarly_Bird_Price__c(isNotEmpty(metaData.Compare_Price) ? metaData.Compare_Price : undefined);

        setProgram_Structure__c(isNotEmpty(metaData.Program_Structure__c) ? metaData.Program_Structure__c : undefined);
        setProgram_Structure__c_Array(isNotEmpty(metaData.Program_Structure__c) ? metaData.Program_Structure__c.split(" ") : []);
        Program_structure_images.filter(function (item, value) {
            var keyname = Object.keys(item);
            var tag_array = product.tags;
            if (tag_array.includes(keyname.toString())) {
                setProgram_structure_image(Object.values(item));
            }
        })
        Program_structure_color.filter(function (item, value) {
            var keyname = Object.keys(item);
            var tag_array = product.tags;
            if (tag_array.includes(keyname.toString())) {
                setProgram_Types__c_color(Object.values(item));
            }
        })


    }, [metaData])



    let author_index = 0;
    let top_author_index = 0;
    const randm = () => {
        return (Math.random() + 1).toString(36).substring(7);
    }



    useEffect(() => {
        if (sDate !== undefined && sDate !== null && sDate !== "") {
            setTEN_MIntLeft(is_ten_minite_less(sDate));
            setisProgramOver(program_date_is_gone_or_not(sDate));
            // console.log('program_date_is_gone_or_not -> '+sDate+' -> '+program_date_is_gone_or_not(sDate));
        }
    }, [sDate])

    useEffect(() => {
        setTotalCpdPoints(parseFloat(substantive_law) + parseFloat(professional_skills) + parseFloat(practise_managment_skill) + parseFloat(ethics_professional_responsibi));
    }, [substantive_law, professional_skills, practise_managment_skill, ethics_professional_responsibi])



    useEffect(() => {
        if (DeliveryMode.length) {
            const dfvalue = DeliveryMode[0];
            UpdateRadio(dfvalue == "" ? '' : dfvalue, 0);
        }

    }, [DeliveryMode])

    const price = product.priceRange.minVariantPrice.amount;
    const price_float = parseFloat(price);

    const [expandDetail, setexpandDetail] = useState(expandAll);

    const numberWithCommas = (x) => {
        return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (

        <>
            <Col
                data-date={isProgramOver}
            >
                <Card className="program_card">
                    <div className="program_card_inner d-flex flex-row">
                        <Card.Body>
                            <Card.Text as="div">
                                {Program_Structure__c !== undefined
                                    ? <div className="program_cate d-flex justify-content-center align-items-start flex-column" style={{ backgroundColor: Program_Types__c_color }}>
                                        {Program_Structure__c_Array.length > 1
                                            ? <>
                                                <h3>{Program_Structure__c_Array[0]}</h3>
                                                <small>{Program_Structure__c_Array.join(" ").replace(Program_Structure__c_Array[0], "")}</small>
                                            </>
                                            : <h3>{Program_Structure__c}</h3>
                                        }

                                    </div>
                                    :
                                    null
                                }
                                <a href={`/product/${product.handle}`} className="program_head">
                                    <Card.Title as="h3" className="card-title">{product.title} <span className='cpd_points'>CPD Points {totalCpdPoints}</span></Card.Title>
                                </a>

                                <ListGroup horizontal as="ul" className="align-items-sm-center flex-wrap flex-column flex-sm-row">
                                    {totalCpdPoints == 0 ? '' :
                                        <ListGroup.Item as="li" className="cpd_topics">
                                            {professional_skills > 0 ? <img src={ps} alt="Professional Skills" title="Professional Skills" /> : null}
                                            {practise_managment_skill > 0 ? <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" /> : null}
                                            {substantive_law > 0 ? <img src={sl} alt="Substantiative Law" title="Substantiative Law" /> : null}
                                            {ethics_professional_responsibi > 0 ? <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" /> : null}
                                        </ListGroup.Item>
                                    }
                                    {program_date_time_line_1 !== '' ?
                                        <ListGroup.Item as="li">
                                            <p className="address small"><CalendarIcon />{program_date_time_line_1}</p>
                                        </ListGroup.Item>
                                        : null}
                                    {program_date_time_line_2 !== '' ?
                                        <ListGroup.Item as="li">
                                            <p className='mb-0 program_time'><ClockIcon />{program_date_time_line_2}</p>
                                        </ListGroup.Item>
                                        : null}

                                </ListGroup>

                                {longPromotionMessage != '' ? <p className="price_sale"><ClockIcon stroke='#70c0c7' /> {longPromotionMessage}</p> : null}
                                {/* <p className="address small">(Australia/Melbourne Time)</p> */}
                                {/* <p className="address small">{venue ? <><LocationIcon /> {venue} </> : ''}</p> */}

                                {
                                    presentor.length <= 2 ?
                                        <div className="author_groups d-lg-none">
                                            <Row className="author_listing">
                                                {
                                                    presentor.map((top_author) => {
                                                        //  console.log('top_author',top_author);
                                                        top_author_index = top_author_index + 1;
                                                        let author_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Name : null;
                                                        let author_image = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Presenter_Profile_Photo_URL__c : null;
                                                        let author_role = top_author.Role__c !== undefined && top_author.Role__c !== null ? top_author.Role__c : null;
                                                        let author_job_title = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Job_Title_Position__c'] !== null ? top_author.Contact_info[0]['Job_Title_Position__c'] : '';

                                                        let author_account_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Account_Name'] !== null ? top_author.Contact_info[0]['Account_Name'] : '';


                                                        if (top_author_index <= 2) {
                                                            return <Col key={author_index + randm()} className="col-auto">
                                                                <div className="author_card">
                                                                    <img src={author_image == undefined || author_image == null ? user_default_image : author_image} alt={author_image} />
                                                                    <p>{author_name}</p>
                                                                    {author_account_name != '' && author_account_name !== null ? <small>{author_account_name}</small> : null}

                                                                </div>
                                                            </Col>
                                                        }
                                                    })
                                                }
                                            </Row>
                                        </div>
                                        : null
                                }

                                <div className="program_right d-flex flex-column align-items-start d-lg-none">
                                    <h3 className="text-lg-center" ><span className='discount_price'>{Early_Bird_Price__c !== undefined ? '$' + numberWithCommas(Early_Bird_Price__c) : null}</span>${numberWithCommas(price_float)}</h3>
                                    <button className={getAllWishlist.includes(product.handle) ? 'wishlist_link wishlist_link_add' : 'wishlist_link'} onClick={() => AddtoWishlist(product.handle, sDate)}> {getAllWishlist.includes(product.handle) ? <> <LikeIcon /> Added</> : <> <LikeIcon /> Add to wishlist</>}</button>
                                    {!TEN_MIntLeft ?
                                        isProgramOver ? null :
                                            reached_capacity
                                                ? ''
                                                : shortPromotionMessage !== ''
                                                    ? <div className="label_tag">{shortPromotionMessage}</div>
                                                    : null
                                        : null
                                    }

                                    {!TEN_MIntLeft ?
                                        isProgramOver ? <div className="label_tag">Bookings Closed</div> :
                                            reached_capacity
                                                ? <div className="label_tag">Fully Booked</div>
                                                : <> <div key={`inline-${randm()}`} className="form_check_group checkfont d-flex align-items-center">
                                                    {DeliveryMode.length > 1 ?
                                                        <>
                                                            <span>Select:</span>
                                                            {DeliveryMode.map((cpd, index) => {

                                                                return <Form.Check
                                                                    inline
                                                                    key={`inline5-${randm()}`}
                                                                    name={product.handle}
                                                                    label={cpd}
                                                                    value={cpd}
                                                                    checked={index === DefaultRadio}
                                                                    onChange={(e) => UpdateRadio(e.target.value, index)}
                                                                    type="radio"
                                                                    id={`inline-${randm()}-1`}
                                                                />
                                                            })
                                                            }
                                                        </>
                                                        : <span><strong>{DefaultRadioValue}</strong></span>
                                                    }
                                                </div>
                                                    <div className='program_cta d-flex align-items-center'>
                                                        <a className="btn-link" href={`/product/${product.handle + GAurl}`}>Learn more</a>
                                                        <a data-record={RecordType} data-test={DefaultRadioValue} className="btn btn-primary" onClick={(e) => AddtoCart(product.variants.edges[0].node.id, 1, product.variants.edges[0].node.sku, DefaultRadioValue, RecordType, product.tags, Program_structure_image, product.handle, sDate, Program_Structure__c)}>Add to Cart</a>
                                                        <div className="btn-expand-link" onClick={() => setexpandDetail(!expandDetail)}>{expandDetail ? 'Hide Detail' : 'Expand Detail'}  {expandDetail ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
                                                    </div>
                                                </>
                                        : <div className="label_tag">Bookings Closed</div>
                                    }
                                </div>
                            </Card.Text>
                            {
                                presentor.length <= 2 ?
                                    <div className="author_groups d-none d-lg-block">
                                        <Row className="author_listing">
                                            {
                                                presentor.map((top_author) => {
                                                    //  console.log('top_author',top_author);
                                                    top_author_index = top_author_index + 1;
                                                    let author_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Name : null;
                                                    let author_image = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Presenter_Profile_Photo_URL__c : null;
                                                    let author_role = top_author.Role__c !== undefined && top_author.Role__c !== null ? top_author.Role__c : null;
                                                    let author_job_title = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Job_Title_Position__c'] !== null ? top_author.Contact_info[0]['Job_Title_Position__c'] : '';

                                                    let author_account_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Account_Name'] !== null ? top_author.Contact_info[0]['Account_Name'] : '';


                                                    if (top_author_index <= 2) {
                                                        return <Col key={author_index} className="col-auto">
                                                            <div className="author_card">
                                                                <img src={author_image == undefined || author_image == null ? user_default_image : author_image} alt={author_image} />
                                                                <p>{author_name}</p>
                                                                {author_account_name != '' && author_account_name !== null ? <small>{author_account_name}</small> : null}

                                                            </div>
                                                        </Col>
                                                    }
                                                })
                                            }
                                        </Row>
                                    </div>
                                    : null
                            }
                        </Card.Body>
                        <div className="program_right d-none d-lg-flex flex-column align-items-end justify-content-between">
                            <div className='program_right_top d-flex flex-column align-items-end'>
                                <h3 className="text-lg-center" ><span className='discount_price'>{Early_Bird_Price__c !== undefined ? '$' + numberWithCommas(Early_Bird_Price__c) : null}</span>${numberWithCommas(price_float)}</h3>
                                <button className={getAllWishlist.includes(product.handle) ? 'wishlist_link wishlist_link_add' : 'wishlist_link'} onClick={() => AddtoWishlist(product.handle, sDate)}> {getAllWishlist.includes(product.handle) ? <> <LikeIcon /> Added</> : <> <LikeIcon /> Add to wishlist</>}</button>
                                {!TEN_MIntLeft ?
                                    isProgramOver ? null :
                                        reached_capacity
                                            ? ''
                                            : shortPromotionMessage !== ''
                                                ? <div className="label_tag">{shortPromotionMessage}</div>
                                                : null
                                    : null
                                }

                                {!TEN_MIntLeft ?
                                    isProgramOver ? <div className="label_tag">Bookings Closed</div> :
                                        reached_capacity
                                            ? <div className="label_tag">Fully Booked</div>
                                            : <> <div key={`inline-${randm()}`} className="form_check_group checkfont d-flex">
                                                {DeliveryMode.length > 1
                                                    ?
                                                    <>
                                                        <span>Select:</span>
                                                        {DeliveryMode.map((cpd, index) => {

                                                            return <Form.Check
                                                                inline
                                                                key={index}
                                                                name={product.handle}
                                                                label={cpd}
                                                                value={cpd}
                                                                checked={index == DefaultRadio}
                                                                onChange={(e) => UpdateRadio(e.target.value, index)}
                                                                type="radio"
                                                                id={`inline-${randm()}-1`}
                                                            />
                                                        })
                                                        }
                                                    </>
                                                    : <span><strong>{DefaultRadioValue}</strong></span>
                                                }
                                            </div>

                                            </>
                                    : <div className="label_tag">Bookings Closed</div>
                                }

                            </div>
                            {!TEN_MIntLeft ?
                                isProgramOver ? null :
                                    reached_capacity
                                        ? null
                                        : <>
                                            <div className='program_right_bottom'>
                                                <div className='program_cta d-flex align-items-center'>
                                                    <div className="btn-expand-link" onClick={() => setexpandDetail(!expandDetail)}>{expandDetail ? 'Hide Detail' : 'Expand Detail'}  {expandDetail ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
                                                    <a className="btn-link" href={`/product/${product.handle + GAurl}`}>Learn more</a>
                                                    <a data-record={RecordType} data-test={DefaultRadioValue} className="btn btn-primary" onClick={(e) => AddtoCart(product.variants.edges[0].node.id, 1, product.variants.edges[0].node.sku, DefaultRadioValue, RecordType, product.tags, Program_structure_image, product.handle, sDate, Program_Structure__c)}>Add to Cart</a>
                                                </div>
                                            </div>
                                        </>
                                : null
                            }



                        </div>
                    </div>
                    {expandDetail ?
                        <div className='expand_details_block active'>
                            <div dangerouslySetInnerHTML={{ __html: product.description }} />
                        </div>
                        : null}
                </Card>
            </Col>

        </>
    )
}
