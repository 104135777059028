import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios'
import Select from 'react-select'

// import styled from "styled-components";
import {
  LogoIcon,
  CartIcon,
  SearchIcon,
  ProgramIcon,
  UserIconTwo,
  BrandIcon
} from "../../icons";
import {
  Navbar,
  Container,
  Nav,
  Form,
  FormControl,
  Button,
  Badge,
  ListGroup,
  // NavDropdown
} from 'react-bootstrap';
import CloseIcon from "../../images/close-ic.png"
import CloseIcon2 from "../../images/close-icon.png"
import { useHistory } from 'react-router-dom';
import queryString, { parse } from 'query-string';


export const Header = (props) => {


  const location = useLocation();
  const queryParam = queryString.parse(location.search);

  let history = useHistory();
  const PathName = location.pathname;
  const productHandle = PathName.substring(PathName.lastIndexOf('/') + 1);
  const GAurl = queryParam['gclid'] !== undefined ? '?gclid=' + queryParam['gclid'] : '';

  const [search_text, set_search_text] = useState();
  const [TopBarText, setTopBarText] = useState();
  const [DisableBarStatus, setDisableBarStatus] = useState(false);
  const [DisableBar, setDisableBar] = useState(() => {
    const offerBar = localStorage.getItem("DisableBar");
    return offerBar !== null && (offerBar === false || offerBar === "false")
      ? false
      : true
  });

  useEffect(() => {
    const queryParam = queryString.parse(location.search);
    let search = queryParam['s'];
    if (search !== undefined) {

      set_search_text(search);
    }
  }, [])

  useEffect(() => {
    set_search_text(props.HeaderSearchText);
    const queryParam = queryString.parse(location.search);
    let search = queryParam['s'];
    if (search !== undefined) {
      set_search_text(search);
    }
  }, [props.HeaderSearchText])




  /****
   * Search submit function
   */
  const main_search_form = (event) => {
    event.preventDefault();
    props.setHeaderSearchText(search_text)
    history.push("/search?s=" + search_text);
  }


  /****
   * Close OfferBar
   */

  const CloseOfferBAR = () => {
    setDisableBar(false);
    localStorage.setItem("DisableBar", false);
  }



  const fetchData = async () => {

    try {

      const admin_panel_api_URL = props.AdminPanelURL + 'api/notification';
      const admin_panel_response = await axios(admin_panel_api_URL);
      // console.log(api_URL);

      if (admin_panel_response.status === 200) {
        var data = admin_panel_response.data;
        if (data.length !== 0) {

          // console.log(data);
          setTopBarText(data.notification_content);

          setDisableBarStatus(data.enable_notification);

        }
      }

    } catch (error) {
      console.log(true);
    }

  };
  useEffect(() => {
    fetchData();
  }, []);

  const StateOPtion = [
    { label: "Western Australia", value: "WA" },
    { label: "Queensland", value: "QLD" },
    { label: "South Australia", value: "SA" },
    { label: "New South Wales", value: "NSW" },
    { label: "Tasmania", value: "TAS" },
    { label: "Victoria", value: "VIC" },
    { label: "Australian Capital Territory", value: "ACT" }
  ];

  const getLabel = (optionList, selected) => {
    var label = "";
    optionList.map((value) => {
      if (value.value === selected) {
        label = value.label;
      }
    });
    return label;

  }
  const numberWithCommas = (x) => {
    return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }



  return (
    <>
      {DisableBarStatus ? DisableBar ?
        <div className="top_bar_wrapper bg-dark text-white">
          <Container>
            <div className="text-center top_bar_contnet">
              <div  onClick={() => props.ShowSignUpPopup()} dangerouslySetInnerHTML={{ __html: TopBarText }} />
            </div>
          </Container>
          <div onClick={() => CloseOfferBAR()} className="top_bar_close" aria-label="Close"><img src={CloseIcon2} alt="Close" /> </div>
        </div>
        : null : null}

      <Navbar bg="white" expand="lg" className="flex-wrap py-0" collapseOnSelect>
        <Container>
          <Navbar.Brand href="https://www.leocussen.edu.au/" target="_blank"><LogoIcon /></Navbar.Brand>
          <div className="cart_link d-lg-none" onClick={() => props.redirectTocart()}>
            <CartIcon />
            <Badge variant="danger" bg="danger">{props.Cartqty}</Badge>
            <span className="cart_price d-none">${numberWithCommas(props.CartAmount.amount)}</span>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <div className="navbar_right d-flex flex-wrap align-items-center">
            <Nav className="mx-auto ms-lg-auto d-md-flex flex-row align-items-center flex-wrap justify-content-lg-start justify-content-center">
            <Link to={"/"+GAurl} className="home_link d-lg-none"><BrandIcon className='d-block' /> Store Home</Link>
              <a className="nav-link d-none" onClick={props.ShowAboutPagePopup}>About us</a>

              <Select aria-label="select"
                key={props.userState}
                onChange={val => props.setStateforUserDropdown(val.value)}
                options={StateOPtion}
                defaultValue={props.userState !== undefined ? { label: getLabel(StateOPtion, props.userState), value: props.userState } : null}
              />

              <Link to="/all-courses?view=calendar" className="nav-link d-none d-lg-flex align-items-center"><ProgramIcon />Program</Link>
              {!props.useLoggedIn ? <><a onClick={() => props.ShowCreateAccountPopup()} className="btn btn-secondary"> {props.useLoggedIn ? 'My Account' : 'sign up'}</a>
                <a onClick={() => props.ShowLoginPopup_()} className="btn btn-secondary-outline"> {props.useLoggedIn ? 'My Account' : 'Log in'}</a></> :
                <a onClick={() => props.ShowLoginPopup_()} className="nav-link account_link"><UserIconTwo /></a>}
              <a onClick={() => props.redirectTocart()} className="nav-link cart_link d-none d-lg-flex align-items-center">
                <CartIcon />
                <Badge variant="danger" bg="danger">{props.Cartqty}</Badge>
                {props.CartAmount.amount > 0 ? <span className="cart_price">${numberWithCommas(props.CartAmount.amount)}</span> : ''}
              </a>
            </Nav>
            <Form onSubmit={main_search_form} className="d-flex search_bar align-items-center position-relative d-none">
              <FormControl
                type="search"
                placeholder="Type and enter to search"
                className="search_input"
                onChange={(e) => set_search_text(e.target.value)}
                value={search_text}
                aria-label="Search"
              />
              <Button type="submit" variant="search"><SearchIcon /></Button>
            </Form>
          </div>
        </Container>
        <Container fluid className="bg-secondary">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto" as="ul">
              <Link to={"/"+GAurl} className="home_link"><BrandIcon /> Store Home</Link>
              <ListGroup.Item as="li" className=""><Link to={"/all-courses" +GAurl} className="nav-link">All Programs</Link></ListGroup.Item>
              {props.ProductPageJson.map((menu, index) => {
                const menuname = menu.Name;
                const slug = menuname.split(' ').join('-').toLowerCase();
                let active_class = '';
                if (slug == history.location.pathname.replace('/', '')) {
                  active_class = 'active';
                }
                return <ListGroup.Item eventKey={index} key={index} as="li" className={active_class}><Link to={"/" + slug+GAurl} className="nav-link">{menu.Name}</Link></ListGroup.Item>
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>

      </Navbar>
    </>
  )
}