import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag';

import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

import { Helmet } from "react-helmet";
import { AdminSidebar } from './Sidebar/AdminSidebar';
import axios from 'axios';
import {
    ShareIcon
} from "../../icons";
import BlueBg from "../../images/my-profile-banner.png";
import { Invite } from "./../Modals/Invite";

export const AdminRefer = (props) => {
    const [profile_data, set_profile_data] = useState([]);
    const [joined, set_joined] = useState([]);
    const [CanWeinvite, setCanWeinvite] = useState(false);
    /**
        * Do logout
     */
    const doLogout = () => {
        localStorage.removeItem("userDetail");
        localStorage.setItem('isLoggedin', false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        window.location.href = "/";
    }
    const FetchUserDateFromDatabase = async (useremail) => {
        if (useremail === null || useremail === "") return;
        try {
            const api_URL = props.EngineURL + 'profile/get_referal.php?email=' + useremail;
            const response = await axios(api_URL);

            /***
             * Assign data to State
            */
            if (response.status === 200 && response.data.status === 200) {
                let pdata = response.data.data.profile_data;
                if (pdata.length > 0) {
                    set_profile_data(pdata);
                }
                set_joined(response.data.data.joined);
            }
            else {
                console.log(response.data.error);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (props.useLoggedIn !== undefined && props.useLoggedIn === true && props.UserData !== null && props.UserData !== undefined) {
            FetchUserDateFromDatabase(props.UserData.customer.email);
        }
    }, [props.UserData]);

    return (
        <>   <div className="admin_page admin_cpd_points" style={{ backgroundImage: "url(" + BlueBg + ")" }}>
            <Helmet>
                <title>Refer & Earn | Leo Cussen Centre for Law </title>
            </Helmet>
            <Container>
                {
                    props.useLoggedIn !== undefined && props.useLoggedIn === true
                        ? <div className="admin_wrapper position-relative">
                            {
                                props.UserData !== null && props.UserData !== undefined ?
                                    <div className='admin_details_block mb-5'>
                                        <h1 className="m-0">{props.Good},  {props.UserData.customer.firstName}</h1>
                                    </div>
                                    : null
                            }
                            <Row>
                                <Col xl={3} lg={4} xs={12} className="d-none d-lg-block">
                                    <AdminSidebar doLogout={doLogout} />
                                </Col>
                                <Col xl={9} lg={8} xs={12}>
                                    <div className='admin_sidebar_mobile d-lg-none'>
                                        <AdminSidebar doLogout={doLogout} />
                                    </div>
                                    <div className="admin_right_content_block wishlist_right_block">
                                        <div className='profile_detail_head'>
                                            <Row xs="auto" className='justify-content-between align-items-center'>
                                                <Col>
                                                    <h4 className="m-0 text-dark">Invite and Earn</h4>
                                                </Col>
                                                {props.useLoggedIn !== undefined && props.useLoggedIn === true && props.UserData !== null && props.UserData !== undefined && profile_data.length > 0 ?
                                                    <Col className='d-flex align-items-center '>
                                                        <div className='manually_add_point d-flex align-items-center' onClick={() => setCanWeinvite(true)} ><ShareIcon />Invite now</div>
                                                    </Col>
                                                    : null}
                                            </Row>
                                        </div>
                                        {
                                            profile_data.length > 0 ?
                                                <div className="profile_detail">
                                                    <div className="profile_detail_content">
                                                        <span>Your Referral Code:</span> <strong>{profile_data[0].referal_code}</strong>
                                                    </div>
                                                    <p className='mt-3'><i>Refer a friend and receive discount bonus.</i></p>
                                                </div>
                                                : null
                                        }
                                        <h4 className="mb-2 mt-4 text-dark">Who joined with my referral code</h4>
                                        {
                                            joined.length > 0 ?
                                                <div className="profile_detail">
                                                    <div className="profile_detail_content">
                                                        {
                                                            profile_data.length > 0 ?
                                                                <Table striped bordered hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>First Name</th>
                                                                            <th>Last Name</th>
                                                                            <th>Email</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {joined.map((user, index) => {
                                                                            return <tr key={user.user_email}>
                                                                                <td>{++index}</td>
                                                                                <td>{user.user_first_name}</td>
                                                                                <td>{user.user_last_name}</td>
                                                                                <td>{user.user_email}</td>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        : <div className="text-center"><h3>Please login to access My profile section</h3><a onClick={() => props.ShowLoginPopup_()} className="btn btn-primary rounded-pill m-0 stest"> Login</a></div>
                }
            </Container>
        </div>
            {props.useLoggedIn !== undefined && props.useLoggedIn === true && props.UserData !== null && props.UserData !== undefined && profile_data.length > 0 ?
                <Invite
                    useremail={props.UserData.customer.email}
                    userfname={props.UserData.customer.firstName}
                    userlname={props.UserData.customer.firstName}
                    userRefer={profile_data[0].referal_code}
                    CanWeinvite={CanWeinvite}
                    setCanWeinvite={setCanWeinvite}
                    EngineURL={props.EngineURL}
                />
                : null}

        </>
    );
}