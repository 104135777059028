import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Modal,
    ListGroup
} from 'react-bootstrap';
import {
    LinkedInIcon,
} from "../../icons";
export const PresentorModal = (props) => {
   // console.log('presentor_props',props);
    return (
        <>
            <Modal className="privacy_policy_modal" size="lg" show={props.show} onHide={() => props.hide()} scrollable>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title as="h2" className="mb-0">
                        {/* {props.presentor_modal_data.name}
                        <p className="small"><b>Job Title : </b>{props.presentor_modal_data !== null  ? <><span className="author_role">{props.presentor_modal_data.job}</span><br/><br/></> : null }</p> */}
                    </Modal.Title>
                    
                </Modal.Header>
                <Modal.Body className="pt-0 px-4">
                    <div className="about_page_wrapper">
                        <div className="conditions">
                            
                            <div className="row">
                            <div className="col-md-4 col-12"></div>
                            <div className="col-lg-8 col-12"></div>

                                </div>
                            
                                
                            

                            <img src={props.presentor_modal_data.image} alt={props.presentor_modal_data.name} />
                            <h2 className="mb-0">
                                {props.presentor_modal_data.name}
                                <p className="small mb-0"><><span className="author_role">{props.presentor_modal_data.job}{props.presentor_modal_data.author_company_title != '' ? (props.presentor_modal_data.job !== '' && props.presentor_modal_data.job !== null ? ', ' : null)+props.presentor_modal_data.author_company_title : null}</span></></p>

                            </h2>
                            {
                                props.presentor_modal_data.linkedin !== null ?
                                <ListGroup horizontal as="ul">
                                    <ListGroup.Item as="li" className="me-3">
                                        <a target="_blank" href={props.presentor_modal_data.linkedin} className="d-block">
                                            <LinkedInIcon />
                                        </a>
                                    </ListGroup.Item>
                                    <br/><br/>
                                </ListGroup>
                                : null 
                            }
                            <div dangerouslySetInnerHTML={{ __html: props.presentor_modal_data.description }} ></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}