import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import {
    CalendarIcon,
    EditIcon,
    PlusIcon,
    PlusPrimaryIcon
} from "../../icons";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    Alert
} from 'react-bootstrap';
import { Helmet } from "react-helmet";
import BlueBg from "../../images/my-profile-banner.png";

import pm from '../../images/pm-ic.png';
import ps from '../../images/ps-ic.png';
import sl from '../../images/sl-ic.png';
import ep from '../../images/ep-ic.png';

import gql from 'graphql-tag';
import {
    useMutation,
    useQuery
} from '@apollo/react-hooks';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { AdminSidebar } from './Sidebar/AdminSidebar';
import axios from 'axios';
import { ManuallyCPD } from '../Modals/ManuallyCPD';

const GetuserAddress = gql`
query($customerAccessToken:String!){
    customer(customerAccessToken:$customerAccessToken){
    firstName
    lastName
    email
    phone
      addresses(first:10)
      {
        edges
        {
          node{
            address1
            address2
            city
            company
            zip
            country
            countryCodeV2
            firstName
            lastName
            phone
            id
            province
            provinceCode
          }
        }
      }
    }
  }
  `;

const UpdateAddressQuery = gql`
  mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
  `;

const AddnewAddressQuery = gql`
  mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
 `;


const updateCustomer = gql`
 mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
 `;


const customerRecover = gql`
 mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
 `;



export const AdminProfile = (props) => {
    const [validated, setValidated] = useState(false);



    /***
     * Query
     */
    const [doUpdateAddress, { data: address_Upadated, error: address_error_update, loading: address_adding }] = useMutation(UpdateAddressQuery);

    const [doAddAddress, { data: address_added, error: error_on_address_added, loading: address_adding_new }] = useMutation(AddnewAddressQuery);

    const [update_customer, { data: customer_updated, error: customer_upadting, loading: customer_updating }] = useMutation(updateCustomer);

    const [customer_Recover, { data: recover_password, loading: process_recover_pass }] = useMutation(customerRecover);

    /****
     * State Decalration
     */
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [emailAddress, setemailAddress] = useState("");
    const [Company, setCompany] = useState("");
    const [Phone, setPhone] = useState("");



    const [EditProfile, setEditProfile] = useState(false);

    const [TotalOrder, setTotalOrder] = useState('-');
    const [TotalCPDpoints, setTotalCPDpoints] = useState('-');
    const [PS_points, set_PS_points] = useState('-');
    const [PM_points, set_PM_points] = useState('-');
    const [SL_points, set_SL_points] = useState('-');
    const [EP_points, set_EP_points] = useState('-');
    const [yourReferalCode, setyourReferalCode] = useState(null);
    const [ResetPassLinkSent, setResetPassLinkSent] = useState(false);

    const PracticeArea = [
        "Building & Construction",
        "Business & Contracts",
        "Consumer",
        "Corporate & In-House Counsel", 
        "Costs",
        "Criminal",
        "Employment & Workplace Relations",
        "Ethics",
        "Family",
        "Finance, Tax, Superannuation",
        "Government, Privacy & FOI",
        "Insolvency & Restructuring",
        "Intellectual Property",
        "Legal Practice Fundamentals",
        "Litigation & Advocacy",
        "Migration",
        "Personal Injury & Medico-Legal",
        "Professional Skills & Practice Management",
        "Property, Leases & Planning",
        "Sports Law",
        "Wills, Trusts & Succession Planning"
    ];

    /// Billing address
    const [AddressID, setAddressID] = useState('');
    const [Bill_Street, set_Bill_Street] = useState('');
    const [Bill_Subrurb, set_Bill_Subrurb] = useState('');
    const [Bill_Country, set_Bill_Country] = useState('');
    const [Bill_State, set_Bill_State] = useState('');
    const [Bill_Postcode, set_Bill_Postcode] = useState('');
    const [Bill_Address_ID, set_Bill_Address_ID] = useState('');




    //Validation variable
    const [firstName_ERR, set_firstName_ERR] = useState(false);
    const [lastName_ERR, set_lastName_ERR] = useState(false);
    const [Phone_ERR, set_Phone_ERR] = useState(false);
    const [Street_ERR, set_Street_ERR] = useState(false);
    const [Suburb_ERR, set_Suburb_ERR] = useState(false);
    const [Country_ERR, set_Country_ERR] = useState(false);
    const [State_ERR, set_State_ERR] = useState(false);
    const [Zip_ERR, set_Zip_ERR] = useState(false);



    /***
     * Error message
     */
    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);


    /***
     * get Access token from user detail
     */
    const [UserToken, setUserTOken] = useState(props.UpdateloginUserDetail);


    /***
     * get user addreess from shopify account
     */
    const customerAccessToken = UserToken != undefined ? UserToken.accessToken : "";
    const { loading: getting_address, error: address_error, data: UserData } = useQuery(GetuserAddress,
        {
            variables: { customerAccessToken }
        });





    /***
     * Save data to state
     */
    useEffect(() => {

        if (UserData != undefined && UserData.customer != null) {
          //  console.log(UserData);

            setfirstName(UserData.customer.firstName);
            setlastName(UserData.customer.lastName);
            setPhone(UserData.customer.phone);



            setemailAddress(UserData.customer.email);

            if (UserData.customer.addresses.edges.length) {
                const User_Data = UserData.customer.addresses.edges[0].node;
               // console.log(UserData);
                setfirstName(User_Data.firstName);
                // setlastName(User_Data.lastName);
                setPhone(User_Data.phone);
                setCompany(User_Data.company);
                setAddressID(User_Data.id);
               // console.log(User_Data.address1);
                set_Bill_Street(User_Data.address1);
                set_Bill_State(User_Data.provinceCode);
                set_Bill_Country(User_Data.country);
                set_Bill_Subrurb(User_Data.city);
                set_Bill_Postcode(User_Data.zip);

                //  console.log(UserData.customer.addresses.edges.length);


            }

            FetchUserDateFromDatabase(UserData.customer.email, UserData.customer.lastName);

        }
    }, [UserData]);


    const FetchUserDateFromDatabase = async (useremail, userLastname) => {
        if (useremail === null || useremail === "") return;
        try {
            const api_URL = props.EngineURL + 'profile/get_profile.php?email=' + useremail;
            const response = await axios(api_URL);

            /***
             * Assign data to State
            */
            if (response.status === 200 && response.data.status === 200) {
               
                var data = response.data.data[0];
              //  console.log(data.practice_areas);
                setyourReferalCode(data.referal_code);
                SetUserSelectedPractice(data.practice_areas !== null & data.practice_areas !== "" ? data.practice_areas.split("|") : []);
            }
            else {
               //(response.data.error);
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const api_URL1 = props.EngineURL + 'salesforce-query/checkout/leo_cpd_debug_mix.php?contact_id=' + useremail + '&user_lname=' + userLastname;
            const response1 = await axios(api_URL1);

            /***
             * Assign data to State
            */
            if (response1.status === 200) {
                setTotalOrder(response1.data.total_programs);
                setTotalCPDpoints(response1.data.total_cpd_points);
                set_PS_points(response1.data.total_ps);
                set_PM_points(response1.data.total_pm);
                set_SL_points(response1.data.total_sl);
                set_EP_points(response1.data.total_ep);
            }
            else {
               // console.log(response1.data.error);
            }
        } catch (error) {
            console.log(error);
        }

    };



    // console.log(Bill_State);
    const handleSubmit = (event) => {
        //   console.log("submit event");
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        setSomethingUpadating(false);

        event.preventDefault();
        var firstName_ERR_var = true;
        var lastName_ERR_var = true;
        var Phone_ERR_var = true;
        var Street_ERR_var = true;
        var Suburb_ERR_var = true;
        var Country_ERR_var = true;
        var State_ERR_var = true;
        var Zip_ERR_var = true;

        set_firstName_ERR(false);
        set_lastName_ERR(false);
        set_Phone_ERR(false);
        set_Street_ERR(false);
        set_Suburb_ERR(false);
        set_Suburb_ERR(false);
        set_Country_ERR(false);
        set_State_ERR(false);
        set_Zip_ERR(false);

        if (!firstName) {
            set_firstName_ERR(true);
            firstName_ERR_var = false;
        }

        if (!lastName) {
            set_lastName_ERR(true);
            lastName_ERR_var = false;
        }

        if (!Phone) {
            set_Phone_ERR(true);
            Phone_ERR_var = false;
        }
        if (Phone && !isValidPhoneNumber(Phone)) {
            set_Phone_ERR(true);
            Phone_ERR_var = false;
        }


        if (!Bill_Street) {
            set_Street_ERR(true);
            Street_ERR_var = false;
        }

        if (!Bill_Subrurb) {
            set_Suburb_ERR(true);
            Suburb_ERR_var = false;
        }

        if (!Bill_Country) {
            set_Country_ERR(true);
            Country_ERR_var = false;
        }

        if (!Bill_State) {
            set_State_ERR(true);
            State_ERR_var = false;
        }

        if (!Bill_Postcode) {
            set_Zip_ERR(true);
            Zip_ERR_var = false;
        }
        //    console.log(firstName_ERR_var + ' && ' + lastName_ERR_var + ' && ' + Phone_ERR_var + ' && ' + Street_ERR_var + ' && ' + Suburb_ERR_var + ' && ' + Country_ERR_var + ' && ' + State_ERR_var + ' && ' + Zip_ERR_var)

        if (firstName_ERR_var && lastName_ERR_var && Phone_ERR_var && Street_ERR_var && Suburb_ERR_var && Country_ERR_var && State_ERR_var && Zip_ERR_var) {
            //    console.log(AddressID);
            setSomethingUpadating(true);
            setSuccessMessage(undefined);

            update_customer({
                variables: {
                    customerAccessToken: customerAccessToken,
                    customer: {
                        firstName: firstName,
                        lastName: lastName,
                        phone: Phone
                    }
                }
            })



            if (AddressID != "") {
                doUpdateAddress({
                    variables: {
                        customerAccessToken: customerAccessToken,
                        id: AddressID,
                        address: {
                            lastName: lastName,
                            firstName: firstName,
                            address1: Bill_Street,
                            province: Bill_State,
                            country: Bill_Country,
                            zip: Bill_Postcode,
                            city: Bill_Subrurb,
                            phone: Phone,
                            company: Company
                        }
                    }
                })
            } else {
                doAddAddress({
                    variables: {
                        customerAccessToken: customerAccessToken,
                        address: {
                            lastName: lastName,
                            firstName: firstName,
                            address1: Bill_Street,
                            province: Bill_State,
                            country: Bill_Country,
                            zip: Bill_Postcode,
                            city: Bill_Subrurb,
                            phone: Phone,
                            company: Company
                        }
                    }
                })
            }


        } else {

            setErrorMessage("Fields are required!");
            //  toast.error("Validation error");
        }



    };

    //  const loadingID = toast.loading('Saving...');




    useEffect(() => {
        if (address_Upadated != undefined || address_added != undefined || customer_updated != undefined) {
            setSomethingUpadating(false);
            setSuccessMessage("Profile updated successfully");
        }
    }, [address_added, address_Upadated, customer_updated])


    const doLogout = () => {
        localStorage.removeItem("userDetail");
        localStorage.setItem('isLoggedin', false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        window.location.href = "/";
    }
    //  console.log(Phone);

    /****
     * Update Practice area to account
     */
    const [UserSelectedPractice, SetUserSelectedPractice] = useState([]);
    const [updateButtonText, setupdateButtonText] = useState("Update");

    const ManageUserSelectionFORpractice = (data) => {
      //  console.log(data);
        let UserSelectedPractice_ = UserSelectedPractice;
       // console.log(UserSelectedPractice);
        if (UserSelectedPractice_.includes(data)) {
            SetUserSelectedPractice([...removeItemOnce(UserSelectedPractice_, data)]);
        }
        else {
            SetUserSelectedPractice(oldArray => [...oldArray, data]);
        }
    }

    const removeItemOnce = (arr, value) => {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    const UpdatePracticeArea = () => {
        setSomethingUpadating(true);
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
       // console.log(UserSelectedPractice.join('|'));

        axios.post(props.EngineURL + 'profile/update_profile.php', { email: emailAddress, practice_areas: UserSelectedPractice.join('|') })
            .then(function (response) {
                if (response.status === 200 && response.data.status === 200) {
                    setSuccessMessage(response.data.message);
                }
                else {
                    setErrorMessage(response.data.error);
                }
                setSomethingUpadating(false);
            })
            .catch(function (error) {
                console.log(error);
                setErrorMessage(error);
                setSomethingUpadating(false);
            });
    }


    const StateOPtion = [
        { label: "Western Australia", value: "WA" },
        { label: "Queensland", value: "QLD" },
        { label: "South Australia", value: "SA" },
        { label: "New South Wales", value: "NSW" },
        { label: "Tasmania", value: "TAS" },
        { label: "Victoria", value: "VIC" },
        { label: "Australian Capital Territory", value: "ACT" }
    ];

    const getLabel = (optionList, selected) => {
        var label = "";
        optionList.map((value) => {
            if (value.value === selected) {
                label = value.label;
            }
        });
       // console.log(label);
        return label;

    }

    /***
     * Recover password
     */
    const customerRecoverPassword = (useremail) => {
        if (useremail === null || useremail === "") return;
        customer_Recover({
            variables: {
                "email": useremail
            }
        });
    }

    useEffect(() => {
        if (recover_password !== undefined) {
            setResetPassLinkSent(true);
        }
    }, [recover_password]);


    const [cdp_point_popup, set_cpd_point_popup] = useState(false);

    return (


        <div className="admin_page admin_profile" style={{ backgroundImage: "url(" + BlueBg + ")" }}>
            <Container>
                <Helmet>
                    <title>My Profile | Leo Cussen Centre for Law</title>
                </Helmet>
                {UserToken != undefined
                    ?
                    <div className="admin_wrapper position-relative">
                        <div className='admin_details_block mb-5'>
                            <h1 className="m-0">{props.Good},  {firstName}</h1>
                        </div>
                        <Row>
                            <Col xl={3} lg={4} xs={12} className="d-none d-lg-block">
                                <AdminSidebar doLogout={doLogout} />
                            </Col>
                            <Col xl={9} lg={8} xs={12}>
                                <div className="admin_right_content_block">
                                    <div className='profile_detail_head'>
                                        <Row xs="auto" className='justify-content-between align-items-center'>
                                            <Col>
                                                <h4 className="m-0 text-dark">Current CPD Year Statistics</h4>
                                            </Col>
                                            <Col className='d-flex align-items-center '>
                                                <div className='manually_add_point d-flex align-items-center' onClick={() => set_cpd_point_popup(true)}><PlusPrimaryIcon /> Manually add points</div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col xs="col-12" sm>
                                            <div className='admin_cpd_counts_card d-flex align-items-center justify-content-center'>
                                                <div className='counts'>{TotalOrder}</div>
                                                <h4>CPD Program <br /> Registration</h4>
                                            </div>
                                        </Col>
                                        <Col xs="col-12" sm>
                                            <div className='admin_cpd_counts_card d-flex align-items-center justify-content-center'>
                                                <div className='counts'>{TotalCPDpoints}</div>
                                                <h4>Total <br /> CPD Points</h4>
                                            </div>
                                        </Col>
                                        <Col xs="col-12" sm>
                                            <div className='admin_cpd_counts_card d-flex align-items-center justify-content-center'>
                                                <ul className='d-flex list-unstyled mb-0'>
                                                    <li className='text-center'>
                                                        <div className='count-cpd'>{PS_points}</div>
                                                        <span className='ps_cpd'><img src={ps} alt="Professional Skills" title="Professional Skills" /></span>
                                                    </li>
                                                    <li className='text-center'>
                                                        <div className='count-cpd'>{PM_points}</div>
                                                        <span className='pm_cpd'><img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" /></span>
                                                    </li>
                                                    <li className='text-center'>
                                                        <div className='count-cpd'>{SL_points}</div>
                                                        <span className='sl_cpd'><img src={sl} alt="Substantiative Law" title="Substantiative Law" /></span>
                                                    </li>
                                                    <li className='text-center'>
                                                        <div className='count-cpd'>{EP_points}</div>
                                                        <span className='ep_cpd'><img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" /></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='admin_sidebar_mobile d-lg-none'>
                                        <AdminSidebar doLogout={doLogout} />
                                    </div>
                                    <div className="profile_detail_head">
                                        <Row xs="auto" className='justify-content-between align-items-center'>
                                            <Col>
                                                <h4 className="m-0 text-dark">My Profile</h4>
                                            </Col>
                                            <Col className='d-flex align-items-center'>
                                                <div className='edit_profile d-flex align-items-center' onClick={() => setEditProfile(true)}><EditIcon /> Edit profile</div>
                                                {
                                                    ResetPassLinkSent
                                                        ? <div className='edit_password d-flex align-items-center'>Reset password link has been sent to your email!</div>
                                                        : <div className='edit_password d-flex align-items-center' onClick={() => customerRecoverPassword(emailAddress)}><EditIcon /> {process_recover_pass ? 'Sending...' : 'Reset password'}</div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={EditProfile ? 'profile_detail  d-none' : 'profile_detail'}>
                                        <div className='profile_detail_content'>
                                            <Row xs="auto" className='justify-content-between flex-md-row flex-column-reverse'>
                                                <Col>
                                                    <ul className='list-unstyled'>
                                                        <li><span>Name:</span> {firstName} {lastName}</li>
                                                        <li><span>Organisation Name:</span>{Company}</li>
                                                        <li><span>Email:</span>{emailAddress}</li>
                                                        <li className='d-flex align-items-center'>
                                                            <span>State:</span>
                                                            <Select aria-label="select"
                                                                key={props.userState}
                                                                onChange={val => props.setStateforUserDropdown(val.value)}
                                                                options={StateOPtion}
                                                                defaultValue={props.userState !== undefined ? { label: getLabel(StateOPtion, props.userState), value: props.userState } : null}
                                                            />
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col>
                                                    <div className='your_referal_code mb-3 mb-md-0'>
                                                        {
                                                            yourReferalCode !== null && yourReferalCode !== ""
                                                                ?
                                                                <><span>Your Referral Code:</span> <strong>{yourReferalCode}</strong></>
                                                                : null
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='practice_area_interests'>
                                            <h4 className='text-dark mb-1'>Practice Area Interests</h4>
                                            <p>Choose the practice areas that interest you to receive latest offers</p>
                                            <Row xs="1" md="2" xl="3">
                                                {PracticeArea.map((val, index) => {
                                                    return <Col key={index}>
                                                        <Form.Check
                                                            label={val}
                                                            value={val}
                                                            onChange={(e) => ManageUserSelectionFORpractice(e.target.value)}
                                                            data-checked={UserSelectedPractice.includes(val)}
                                                            checked={UserSelectedPractice.includes(val)}
                                                        />
                                                    </Col>
                                                })}
                                            </Row>
                                        </div>

                                        <Button type="button" onClick={UpdatePracticeArea} disabled={updateButtonText === "Update" ? false : true} className='mb-3'>Update</Button>
                                        {ErrorMessage !== undefined ?
                                            <Alert variant="danger">
                                                {ErrorMessage}
                                            </Alert>
                                            : ''}

                                        {SuccessMessage !== undefined ?
                                            <Alert variant="success">
                                                {SuccessMessage}
                                            </Alert>
                                            : ''}
                                        {SomethingUpadating === true ?
                                            <Alert variant="primary">
                                                Updating.....
                                            </Alert>
                                            : ''}
                                    </div>
                                    <Form validated={validated} onSubmit={handleSubmit} className={EditProfile ? null : 'd-none'}>
                                        <div className="profile_card profile_personal_details shadow bg-white rounded-2">
                                            <div className="profile_card_content">
                                                <h4 className="display-4 text-primary">My Details</h4>
                                                <Row>
                                                    <Form.Group as={Col} md="6" xs="12" controlId="validationCustom01" className="form-group">
                                                        <Form.Label>First name</Form.Label>
                                                        <Form.Control
                                                            value={firstName}
                                                            onChange={(e) => setfirstName(e.target.value)}
                                                            type="text"
                                                            placeholder="First name*"
                                                            className={firstName_ERR ? 'form-error' : ''}
                                                        />
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" xs="12" controlId="validationCustom02" className="form-group">
                                                        <Form.Label>Last name</Form.Label>
                                                        <Form.Control
                                                            value={lastName}
                                                            onChange={(e) => setlastName(e.target.value)}
                                                            type="text" className={lastName_ERR ? 'form-error' : ''}
                                                            placeholder="Last name*"

                                                        />
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustomemail" className="form-group">
                                                        <Form.Label>Email Address</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                                type="email"
                                                                value={emailAddress}
                                                                onChange={(e) => setemailAddress(e.target.value)}
                                                                placeholder="Your email*"
                                                                aria-describedby="inputGroupPrepend"
                                                                readOnly
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Wrong Email Address.
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustomCompany" className="form-group">
                                                        <Form.Label>Company</Form.Label>
                                                        <Form.Control
                                                            value={Company}
                                                            onChange={(e) => setCompany(e.target.value)}
                                                            type="text"
                                                            placeholder="Company"
                                                        />
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustomMobile" className="form-group">
                                                        <Form.Label>Mobile</Form.Label>
                                                        <PhoneInput
                                                            international
                                                            defaultCountry="AU"
                                                            placeholder="Enter phone number"
                                                            value={Phone}
                                                            onChange={setPhone}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="profile_card profile_billing_address_details shadow bg-white rounded-2">
                                            <div className="profile_card_content">
                                                <h4 className="display-4 text-primary">Address</h4>
                                                <Row>
                                                    <Form.Group as={Col} md="6" xs="12" controlId="validationCustom03" className="form-group">
                                                        <Form.Label>Street</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className={Street_ERR ? 'form-error' : ''}
                                                            value={Bill_Street} onChange={(e) => set_Bill_Street(e.target.value)}
                                                            placeholder="Street*" />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid Street.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" xs="12" controlId="validationCustom04" className="form-group">
                                                        <Form.Label>Suburb / city</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className={Suburb_ERR ? 'form-error' : ''}
                                                            placeholder="Suburb*"
                                                            value={Bill_Subrurb} onChange={(e) => set_Bill_Subrurb(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid city.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" xs="12" controlId="validationCustom06" className="form-group">
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Select defaultValue={Bill_State} className={State_ERR ? 'form-error form-control' : 'form-control'} value={Bill_State} onChange={(e) => set_Bill_State(e.target.value)}>
                                                            <option value="">State*</option>
                                                            <option value="VIC">Victoria</option>
                                                            <option value="NSW" >New South Wales</option>
                                                            <option value="QLD" >Queesland</option>
                                                            <option value="TAS">Tasmania</option>
                                                            <option value="WA" >Western Australia</option>
                                                            <option value="SA" >South Australia</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid Country.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" xs="12" controlId="validationCustom05" className="form-group">
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className={Country_ERR ? 'form-error' : ''}
                                                            placeholder="Country*"
                                                            value={Bill_Country} onChange={(e) => set_Bill_Country(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid Country.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" xs="12" controlId="validationCustom07" className="form-group">
                                                        <Form.Label>Post Code</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Post code*"
                                                            className={Zip_ERR ? 'form-error' : ''}
                                                            value={Bill_Postcode}
                                                            onChange={(e) => set_Bill_Postcode(e.target.value)} />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid Post Code.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="profile_cta text-center d-flex flex-wrap align-items-center justify-content-center">
                                            {ErrorMessage !== undefined ?
                                                <Alert variant="danger">
                                                    {ErrorMessage}
                                                </Alert>
                                                : ''}
                                            {SuccessMessage !== undefined ?
                                                <Alert variant="success">
                                                    {SuccessMessage}
                                                </Alert>
                                                : ''}
                                            {SomethingUpadating === true ?
                                                <Alert variant="primary">
                                                    Upadating....
                                                </Alert>
                                                : ''}

                                            <Button type="submit" className="btn-lg border-2 rounded-2">Save</Button>
                                            <Button type="button" className="btn-lg btn-cancle border-2 rounded-2" onClick={() => setEditProfile(false)}>Cancel</Button>

                                        </div>

                                    </Form>
                                </div>

                            </Col>
                        </Row>
                    </div >
                    : <div className="text-center"><h3>Please login to access My profile section</h3><a onClick={() => props.ShowLoginPopup_()} className="btn btn-primary rounded-pill m-0 stest"> Login</a></div>
                }
            </Container >
            <ManuallyCPD
                EngineURL={props.EngineURL}
                cdp_point_popup={cdp_point_popup}
                set_cpd_point_popup={set_cpd_point_popup}
                email={emailAddress}
            />
        </div >
    )

}
