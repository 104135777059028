import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#989795",
  width = "9.333px",
  height = "16.11px",
  className = "",
  viewBox = "0 0 9.333 16.11"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-8.4 -0.9)">
    <path
    fill={fill} stroke={stroke}
    d="M18,1.5V16.41" transform="translate(-4.934)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
    <path
    fill={fill} stroke={stroke}
    d="M16.455,7.5H11.372a2.372,2.372,0,0,0,0,4.744h3.389a2.372,2.372,0,1,1,0,4.744H9" transform="translate(0 -3.289)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
  </g>
  </svg>
);

export default SVG;