import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#01293a",
  width = "19.5",
  height = "20.118",
  className = "",
  viewBox = "0 0 19.5 20.118"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(1.118 1.118)">
    <path d="M0,.05H5.892" transform="translate(5.995 11.777)" fill={fill} stroke={stroke} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M0,.05H5.892" transform="translate(5.995 13.893)" fill={fill} stroke={stroke} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M0,.05H5.892" transform="translate(5.995 16.092)" fill={fill} stroke={stroke} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M3.6,9.545H1.16A1.16,1.16,0,0,1,0,8.385V1.16A1.16,1.16,0,0,1,1.16,0H16.722a1.16,1.16,0,0,1,1.16,1.16V8.385a1.16,1.16,0,0,1-1.16,1.16H14.278" transform="translate(0 4.73)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M9.766,9.04H.929A.929.929,0,0,1,0,8.112V.929A.929.929,0,0,1,.929,0H9.766a.929.929,0,0,1,.929.929V8.112A.929.929,0,0,1,9.766,9.04Z" transform="translate(3.594 9.46)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M0,4.73V.636A.636.636,0,0,1,.636,0h9.442a.636.636,0,0,1,.636.636V4.73" transform="translate(3.585)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
    <path d="M.894,0a.894.894,0,1,0,.894.894A.894.894,0,0,0,.894,0" transform="translate(1.972 6.638)" fill={fill} stroke={stroke} strokeMiterlimit="10" strokeWidth="1"/>
  </g>
  </svg>
);

export default SVG;