import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#004e6d",
  width = "24.553px",
  height = "21.053px",
  className = "",
  viewBox = "0 0 24.553 21.053"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
      <g transform="translate(0.553 0.553)"><path d="M0,0,5.476.614,10.295,13l12.18-.006.972-9.981L7,3" fill="none" stroke="#004e6d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/><circle cx="1.954" cy="1.954" r="1.954" transform="translate(9.158 16.092)" fill="none" stroke="#004e6d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/><circle cx="1.954" cy="1.954" r="1.954" transform="translate(19.546 16.092)" fill="none" stroke="#004e6d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/></g></svg>
);

export default SVG;
