import React from "react";

const SVG = ({
  style = {},
  fill = "#004e6d",
  stroke="none",
  width = "11.848px",
  height = "22.393px",
  className = "",
  viewBox = "0 0 11.848 22.393"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path d="M9.685,3.718h2.163V.157A29.645,29.645,0,0,0,8.7,0C5.578,0,3.442,1.854,3.442,5.261V8.4H0v3.98H3.442V22.393H7.661V12.377h3.3l.525-3.98H7.661V5.655C7.661,4.5,7.988,3.718,9.685,3.718Z" fill={fill} /></svg>
);

export default SVG;
