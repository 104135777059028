import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
export const AboutPage = (props) => {
    return (
        <>
            <Modal className="about_modal" size="lg" show={props.AboutPagePopup} onHide={props.HideAboutPagePopup} scrollable>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title as="h2" className="mb-0">About Us</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <div className="about_page_wrapper">
                        <p>Since its establishment in 1972, Leo Cussen Centre for Law has been and continues to be recognised as a centre of excellence for practical legal training and continuing professional development for entry-level lawyers through to senior legal practitioners.</p>
                        <p>Our purpose is to provide high quality education, training and professional development to strengthen and support the administration of justice and the legal system.</p>
                        <p>Leo Cussen provides Practical Legal Training (PLT) and Continuing Professional Development (CPD) for lawyers, as well as traineeship programs for entry-level lawyers employed by law firms; training for overseas lawyers seeking admission to the Australian legal profession; and for lawyers needing to requalify, as well as courses for legal support staff.</p>
                        <h3>Our History</h3>
                        <p>Leo Cussen Centre for Law is named after one of the leading figures in Australian legal history: Sir Leo Cussen.</p>
                        <p>Leo Cussen Institute was established by an Act of Victorian Parliament and was, for over 40 years, a not-for-profit statutory body supporting the legal system by providing quality education, training and professional development.</p>
                        <p>We are proud of our longstanding participation in the risk management of the legal profession, to enhance protection for legal consumers and to support/strengthen the administration of justice.</p>
                        <p>In 2010 the Leo Cussen Institute commenced trading under the business name of Leo Cussen Centre for Law and in 2012 became an independent not-for-profit centre of excellence for practical legal training, ongoing professional development for lawyers, and law programs/publications for all professionals.</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}