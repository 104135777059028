import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#004e6d",
  width = "18px",
  height = "17.5px",
  className = "",
  viewBox = "0 0 18 17.5"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(0.5)">
    <path d="M8.5,16S0,11.626,0,5.066s8.5-6.56,8.5-1.093C8.5-1.494,17-1.494,17,5.066S8.5,16,8.5,16Z" transform="translate(0 1)" fill={fill} stroke={stroke} strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
  </g>
  </svg>
);

export default SVG;